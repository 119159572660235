import React from 'react';
import { Table, Popconfirm, Switch, notification, Tag } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';



@observer
export default class SoulList extends React.Component<any, any>{

    columns: any = [
        {
            title: "nickname",
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => <Link className="linkColor" to={`/souls/${record.id}`}>{text}</Link>
        },
        {
            title: "phone",
            render: record => <PhoneCell record={record}></PhoneCell>
        },
        {
            title: "birthday",
            dataIndex: "birthday"
        },
        {
            title: "gender",
            dataIndex: "gender"
        },
        {
            title: "mate collection",
            dataIndex: "mate_collect",
            key: "MateCollectStatistics.Count",
            sorter: true,
            sortDirections: ["descend", "ascend"],
        },
        {
            title: "comments",
            dataIndex: "commentCount",
            key: "CommentStatistics.Count",
            sorter: true,
            sortDirections: ["descend", "ascend"],
        },
        // {
        //     title: "roles",
        //     render: record => <RolsCell record={record}></RolsCell>
        // },
        {
            title: "block",
            render: record => <BlockCell record={record}></BlockCell>
        }

    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {
        const columns = this.columns;
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}


@observer
class BlockCell extends React.Component<any, any>{


    confirm = async () => {
        const { record } = this.props
        if (record.has_block) {
            try {
                await record.unblock()
                notification.success({
                    message: "unblock success"
                })
            } catch (error) {
                notification.error({
                    message: "unblock fail",
                    description: error.message
                })
            }
        } else {
            try {
                await record.block()
                notification.success({
                    message: "block success"
                })
            } catch (error) {
                notification.error({
                    message: "block fail",
                    description: error.message
                })
            }
        }
    }

    render() {

        const { record } = this.props

        return (
            <div>
                <Popconfirm
                    title="Are you sure?"
                    onConfirm={this.confirm}
                    okText="yes"
                    cancelText="no"
                >
                    <Switch checked={record.has_block}></Switch>
                </Popconfirm>
            </div>
        )
    }
}


const PhoneCell = observer(({record})=>{
    return <div>{record.phone}</div>
})

const RolsCell = observer(({record})=>{
    return <div>
        {
            record.roles.map(item=>{
                if(item == "Soul"){
                    return <Tag key={`${record.id}-soul`}>Soul</Tag>
                }
                if(item == "Mate"){
                    return <Tag key={`${record.id}-mate`} style={{marginLeft:12}}>Mate</Tag>
                }
            })
        }
    </div>
})
import { CRUDService } from "services/_CRUDService";
import { CRUDState } from 'stores/_CRUDState';
import { MateItemState } from 'stores/mates/mateItem';




class CollectService extends CRUDService {
    constructor(soul_id) {
        super(`/admin/souls/${soul_id}/mates/collect`, "content_api_v1")
    }
}


export class SoulCollectState extends CRUDState {

    constructor(public soul_id) {
        super(new CollectService(soul_id), item => new MateItemState(item))
    }

}

export enum LogLevel {
    info = 4,
    debug = 3,
    warn = 2,
    error = 1
}

var dateFormat = 'MMMM Do YYYY, h:mm:ss a'

export class Logger {
    static logLevel = __DEV__ ? LogLevel.info : LogLevel.error;

    static info(message, ...args) {
        if (Logger.logLevel < LogLevel.info) {
            return;
        }
        //console.log(`✔[INFO][${new Date().toLocaleString()}] ${message}`, ...args);
    }
    static debug(message, ...args) {
        if (Logger.logLevel < LogLevel.debug) {
            return;
        }
        //console.log(`🐞[DEBUG][${new Date().toLocaleString()}] ${message}`, ...args);
    }

    static warn(message, ...args) {
        if (Logger.logLevel < LogLevel.warn) {
            return;
        }
        //console.warn(`🥕[WARN][${new Date().toLocaleString()}] ${message}`, ...args);
    }
    static error(message, ...args) {
        //console.warn(`❌[ERROR][${new Date().toLocaleString()}] ${message}`, ...args);
    }
}

export default Logger
import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';



@observer
export default class CoinList extends React.Component<any, any>{

    columns = [
        {
            title: "name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "code",
            dataIndex: "code"
        },
        {
            title: "type",
            dataIndex: "type"
        },
        {
            title: "description",
            dataIndex: "description"
        }
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {

        const { records, status, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => item["id"]}
                ></Table>
            </div>
        )
    }
}
import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class CategoriesService extends CRUDService {
    @di.Inject("content_api_v1") http: HttpFactory

    constructor() {
        super("/admin/categories", "content_api_v1")
    }

    checkName(name) {
        return this.http.url(`/admin/categories/names/${name}`).addSecurityHeaders().head()
    }

    deleteItem(id){
        return this.http.url(`/admin/categories/${id}/items`).addSecurityHeaders().remove()
    }


    updateProductIndexInCategory(id,data){
        return this.http.url(`/admin/categories/${id}/items/index`).addSecurityHeaders().put(data)
    }
}
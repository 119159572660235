import { CRUDState } from 'stores/_CRUDState';
import { EditorItemState } from './editorItem';
import { observable } from 'mobx';
import { di } from 'jsmodules';
import { EditorService } from 'services/editor';



export class EditorState extends CRUDState {
    @di.Inject() editorService: EditorService


    constructor() {
        super("editorService", item => new EditorItemState(item))
    }

    @observable loading_add = false

    @observable step = "1"

    new_add_editor_id = ""

    checNickname(name) {
        return this.editorService.checkNickname(name)
    }

    @observable loading_edit_nickname = false
    async editNickname(id, data) {

        try {
            await this.editorService.editNickname(id, data)
            this.step = "1"
        } catch (error) {
            throw error
        }
        
    }


}
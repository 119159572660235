import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class CommentService extends CRUDService {
    @di.Inject("content_api_v1") http: HttpFactory

    constructor() {
        super('/admin/comments', "content_api_v1")
    }

    loadComments(params){
        return this.http.url("/admin/comments").addSecurityHeaders().get(params)
    }
}
import moment from 'moment'
import { BaseMomentFormat } from 'config/common';
import { di } from 'jsmodules';
import { Batch_wallet } from 'utils/batch_wallet';
import { observable } from 'mobx';


export class TransationItemState {
    @di.Inject() batch_wallet: Batch_wallet


    id

    creationTime

    @observable transfer_in_soul_id = ""
    @observable transfer_in_name = ""

    @observable transfer_out_soul_id = ""
    @observable transfer_out_name = ""

    netAmountCode = ""
    netAmount = 0

    constructor(item) {
        this.id = item.id
        this.update(item)
    }

    update(item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)

        this.formatNetAmount(item)

        this.loadTransationerInfo(item)
    }

    formatNetAmount(item) {
        if (item.type == "DepositTransaction") {
            this.netAmountCode = item.extend_data.CurrencyCode ? item.extend_data.CurrencyCode : (item.extend_data.OrderCurrencyCode ? item.extend_data.OrderCurrencyCode : ""),
                this.netAmount = item.extend_data.NetAmount ? item.extend_data.NetAmount * 1 : (item.extend_data.OrderAmount ? item.extend_data.OrderAmount * 1 : 0)
        }

        if (item.type == "WithdrawTransaction") {
            this.netAmountCode = item.extend_data.PayoutTradeCurrencyCode ? item.extend_data.PayoutTradeCurrencyCode : "",
                this.netAmount = item.extend_data.PayoutTradeNetAmount ? item.extend_data.PayoutTradeNetAmount * 1 : 0
        }

        if (item.type == "TransferTransaction") {

        }
    }


    async loadTransationerInfo(item) {

        var transfer_in_account_wallet

        var transfer_out_account_wallet


        if (item.type == "DepositTransaction") {
            this.transfer_in_name = item.transfer_in_account.extend_data.Nickname
            transfer_in_account_wallet = await this.batch_wallet.getData(item.transfer_in_account.identity)
            this.transfer_in_soul_id = transfer_in_account_wallet.owner.id
        }

        if (item.type == "WithdrawTransaction") {
            this.transfer_out_name = item.transfer_out_account.extend_data.Nickname
            transfer_out_account_wallet = await this.batch_wallet.getData(item.transfer_out_account.identity)
            this.transfer_out_soul_id = transfer_out_account_wallet.owner.id
        }

        if (item.type == "TransferTransaction") {
            this.transfer_in_name = item.transfer_in_account.extend_data.Nickname
            transfer_in_account_wallet = await this.batch_wallet.getData(item.transfer_in_account.identity)
            this.transfer_in_soul_id = transfer_in_account_wallet.owner.id

            this.transfer_out_name = item.transfer_out_account.extend_data.Nickname
            transfer_out_account_wallet = await this.batch_wallet.getData(item.transfer_out_account.identity)
            this.transfer_out_soul_id = transfer_out_account_wallet.owner.id
        }

    }





}
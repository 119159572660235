import AsyncComponent from '../components/AsyncComponent';
import { Icon } from 'antd'
import * as React from 'react'
import CustomIcon from 'components/CustomIcon'


export interface IMenuItem {
    divider?: boolean,
    key?: string,
    url?: string,
    title?: any,
    groups?: IMenuItem[],
    items?: IMenuItem[],
    render?: (menu: IMenuItem) => React.ReactNode
}

export const menus: IMenuItem[] = [
    {
        title: <span><Icon type="file-text" /><span>User Manage</span></span>,
        key: "sub1",
        items: [
            {
                url: "/souls",
                title: <span><Icon type="user" /><span>Soul</span></span>
            },
            {
                url: "/mates",
                title: <span><Icon type="user" /><span>Mate</span></span>
            },
            {
                url: "/editors",
                title: <span><Icon type="user" /><span>Editor</span></span>
            }
        ]
    },
    {
        title: <span><Icon type="phone" /><span>Call Manage</span></span>,
        key: "sub2",
        items: [
            {
                url: "/callhistory",
                title: <span><Icon type="history" /><span>History</span></span>
            }
        ]
    },
    {
        title: <span><Icon type="snippets" /><span>Marketing</span></span>,
        key: "sub3",
        items: [
            {
                url: "/categories",
                title: <span><Icon type="snippets" /><span>Categories</span></span>
            },
            {
                url: "/products",
                title: <span><Icon type="shopping" /><span>Product</span></span>
            },
            {
                url: "/coins",
                title: <span><Icon type="dollar" /><span>Coins</span></span>
            }
        ]
    },
    {
        title: <span><Icon type="account-book" /><span>Sale</span></span>,
        key: "sub4",
        items: [
            {
                url: "/orders",
                title: <span><Icon type="account-book" /><span>Order</span></span>
            },
            {
                url: "/payments",
                title: <span><Icon type="account-book" /><span>Payment</span></span>
            },
            {
                url: "/payouts",
                title: <span><Icon type="account-book" /><span>Payout</span></span>
            },
            {
                url: "/transations",
                title: <span><Icon type="transaction" /><span>Transations</span></span>
            }
        ]
    },
    {
        title: <span><Icon type="customer-service" /><span>Service</span></span>,
        key: "sub5",
        items: [
            {
                url: "/feedbacks",
                title: <span><Icon type="solution"></Icon><span>Feedback</span></span>
            },
            {
                url: "/workorders",
                title: <span><Icon type="file-word"></Icon><span>Work Orders</span></span>
            }
        ]
    },


]



export const routes = [

    {
        component: require('./Layout/Root').default,
        routes: [

            {
                path: "/login",
                component: require('./Login').default
            },

            , {
                path: '/',
                component: require('./Layout/Layout').default,
                routes: [
                    {
                        path: "/souls",
                        component: require("./Souls").default,
                        routes: [
                            {
                                path: "/souls/:id",
                                component: require("./Souls/DetailsCon").default
                            },
                            {
                                path: "/souls",
                                component: require("./Souls/SoulsCon").default
                            }
                        ]
                    },
                    {
                        path: "/mates",
                        component: require("./Mates").default,
                        routes: [
                            {
                                path: "/mates/:id/edit",
                                component: require("./Mates/EditCon").default
                            },
                            {
                                path: "/mates/:id",
                                component: require("./Mates/DetailsCon").default
                            },
                            {
                                path: "/mates",
                                component: require("./Mates/MatesCon").default
                            }
                        ]
                    },
                    {
                        path: "/editors",
                        component: require("./Editor").default,
                        routes: [
                            {
                                path: "/editors/new",
                                component: require("./Editor/AddCon").default
                            },
                            {
                                path: "/editors",
                                component: require("./Editor/EditorCon").default
                            }
                        ]
                    },

                    {
                        path: "/products",
                        component: require("./Products").default,
                        routes: [
                            {
                                path: "/products/new",
                                component: require("./Products/AddCon").default
                            },
                            {
                                path: "/products",
                                component: require("./Products/ProductsCon").default
                            }
                        ]
                    },
                    {
                        path: "/coins",
                        component: require("./Coins").default,
                        routes: [
                            {
                                path: "/coins",
                                component: require("./Coins/CoinsCon").default
                            }
                        ]
                    },
                    {
                        path: "/orders",
                        component: require("./Orders").default,
                        routes: [
                            {
                                path: "/orders",
                                component: require("./Orders/OrdersCon").default
                            }
                        ]
                    },

                    {
                        path: "/payments",
                        component: require("./Payment").default,
                        routes: [
                            {
                                path: "/payments",
                                component: require("./Payment/PaymentCon").default
                            }
                        ]
                    },

                    {
                        path: "/categories",
                        component: require("./Categories").default,
                        routes: [
                            {
                                path: "/categories/new",
                                component: require("./Categories/AddCon").default
                            },
                            {
                                path: "/categories/:id/items",
                                component: require("./Categories/ItemsCon").default
                            },
                            {
                                path: "/categories",
                                component: require("./Categories/CategoriesCon").default
                            }
                        ]
                    },

                    {
                        path: "/callhistory",
                        component: require("./Calls").default,
                        routes: [
                            {
                                path: "/callhistory",
                                component: require("./Calls/CallsCon").default
                            }
                        ]
                    },

                    {
                        path: "/transations",
                        component: require("./Transations").default,
                        routes: [
                            {
                                path: "/transations",
                                component: require("./Transations/TransationsCon").default
                            }
                        ]
                    },

                    {
                        path: "/payouts",
                        component: require("./Payouts").default,
                        routes: [
                            {
                                path: "/payouts",
                                component: require("./Payouts/PayoutsCon").default
                            }
                        ]
                    },

                    {
                        path: "/feedbacks",
                        component: require("./Feedback").default,
                        routes: [
                            {
                                path: "/feedbacks",
                                component: require("./Feedback/FeecbackCon").default
                            }
                        ]
                    },

                    {
                        path: "/workorders",
                        component: require("./WorkOrder").default,
                        routes: [
                            {
                                path: "/workorders",
                                component: require("./WorkOrder/WorkOrdersCon").default
                            }
                        ]
                    },

                    {
                        path: "/",
                        exact: true,
                        component: require("./Home").default
                    }
                ]
            }
        ]
    }
];

import * as React from 'react';
import { di } from 'jsmodules';

import { Input, Button, message } from 'antd'
import { observer } from 'mobx-react';
import { EditorState } from 'stores/editor/editor';
import { withRouter } from 'react-router';


@observer
class NicknameEdit extends React.Component<any, any> {
    @di.Inject() editorState: EditorState

    state = {
        nickname: ""
    }

    nameChange = (e) => {
        this.setState({
            nickname: e.target.value
        })
    }

    async editname() {
        const { nickname } = this.state
        const { new_add_editor_id } = this.editorState

        this.editorState.loading_edit_nickname = true

        try {
            await this.editorState.checNickname(nickname)
            message.error("nickname not available")
            this.editorState.loading_edit_nickname = false
        } catch (error) {
            await this.editorState.editNickname(new_add_editor_id, { nickname: nickname })
            this.editorState.loading_edit_nickname = false
            this.editorState.step = "1"
            this.setState({
                nickname: ""
            },()=>{
                this.props.history.push("/editors")
            })
            
        }
    }

    public render() {
        return (
            <div style={{ textAlign: "center" }}>
                <h1 style={{ fontSize: 32 }}>nickname</h1>
                <div style={{ width: 400, margin: "60px auto" }}>
                    <Input
                        placeholder="your nickname"
                        size="large"
                        onChange={this.nameChange}
                        value={this.state.nickname}
                    />
                </div>
                <Button
                    type="primary"
                    size="large"
                    loading={this.editorState.loading_edit_nickname}
                    onClick={this.editname.bind(this)}
                >
                    ok
                </Button>
            </div>
        );
    }
}


export default withRouter(NicknameEdit)
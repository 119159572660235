import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class MateService extends CRUDService {
    @di.Inject("content_api_v1") http: HttpFactory

    constructor() {
        super('/admin/mates', "content_api_v1")
    }

    loadMates(params){
        return this.http.url("/admin/mates").addSecurityHeaders().get(params)
    }

    edit(id,data){
        return this.http.url(`/admin/mates/${id}/info`).addSecurityHeaders().put(data)
    }

    edit_voice_product(id,data){
        return this.http.url(`/admin/mates/${id}/voice-product`).addSecurityHeaders().put(data)
    }
}
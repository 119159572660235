import { di } from 'jsmodules';
import { CategoriesService } from 'services/categories';
import { CategoriesState } from './categories';
import { CategoryProductState } from './categoryProducts';

export class CategoriesItemState {
    @di.Inject() categoriesService: CategoriesService
    @di.Inject() categoriesState: CategoriesState


    categoryProductState: CategoryProductState


    constructor(public item) {

        this.categoryProductState = new CategoryProductState(item.id)

        for (const key in item) {
            this[key] = item[key]
        }


    }

    async deleteItem() {
        try {
            await this.categoriesService.deleteItem(this.item.id)
            
            this.categoriesState.loadRecords()
        } catch (error) {
            throw error
        }

    }
}
import { CRUDState } from 'stores/_CRUDState';
import { CategoriesItemState } from './categoriesItem';
import { observable } from 'mobx';
import { di } from 'jsmodules';
import { CategoriesService } from 'services/categories';
import { ProductsService } from 'services/products';
import { ProductItemState } from 'stores/products/productItem';

export class CategoriesState extends CRUDState {
    @di.Inject() categoriesService: CategoriesService
    @di.Inject() productsService: ProductsService

    constructor() {
        super("categoriesService", item => new CategoriesItemState(item))
    }

    @observable loading_add = false


    _get(id) {
        var temp = this.records.filter(item => item.id == id)
        if (temp.length != 0) {
            return temp[0]
        } else {
            var _record = new CategoriesItemState({ id })
            this.records.push(_record)
            return _record
        }
    }

    @observable loading_queryProducts = true
    queryProductRecords = []
    queryProductCount = 0


    queryPagination = {
        current: 1,
        pageSize: 10,
        total: 0
    }

    async loadQueryProducts() {
        this.loading_queryProducts = true
        var params = {
            limit: this.queryPagination.pageSize,
            skip: this.queryPagination.pageSize * (this.queryPagination.current - 1),
        }
        var res = await this.productsService.loadProducts(params)
        this.queryProductRecords = res.data.result.items.map(item => new ProductItemState(item))
        this.queryProductCount = res.data.result.total_count * 1
        this.queryPagination.total = res.data.result.total_count * 1
        this.loading_queryProducts = false
    }

}
import React from 'react';
import { Radio, Button } from 'antd';
import { Link } from 'react-router-dom';






export default class Actions extends React.Component<any, any>{


    statusChange = (e) => {
        this.props.onChange({ has_published: e.target.value, page: 1 })
    }

    render() {

        const { has_published } = this.props

        return (
            <div>
                <div style={{marginBottom: 24}}>
                    <Link to="/products/new"><Button type="primary">Add Product</Button></Link>
                </div>

                <div>
                    <span style={{ color: "#101934", minWidth: 80, marginRight: 36, lineHeight: "32px" }}>publish status</span>
                    <Radio.Group defaultValue={has_published} onChange={this.statusChange}>
                        <Radio value="">all</Radio>
                        <Radio value="true">published</Radio>
                        <Radio value="false">unpublished</Radio>
                    </Radio.Group>
                </div>
            </div>

        )
    }
}
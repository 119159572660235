import React from 'react';
import { Table, notification, Button } from 'antd';
import { observer } from 'mobx-react';
import { di } from 'jsmodules';
import { Link } from 'react-router-dom';




@observer
export default class CategoriesList extends React.Component<any, any>{

    columns = [
        {
            title: 'name',
            key: "name",
            render: record => <Link to={`/categories/${record.id}/items`}>{record.name}</Link>
        },
        {
            title: "description",
            key: "description",
            dataIndex: "description"
        },
        {
            title: "index",
            key: "index",
            dataIndex: "index"
        },
        {
            title: "tags",
            key: "tag_names",
            dataIndex: "tag_names"
        },
        {
            title: "",
            render: record => <Button size="small" type="primary" onClick={()=>this.remove(record)}>remove</Button>
        }
    ]

    handleTableChange = (pagination, filters, sorter) => {
        this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize })
    }

    remove = async (record) => {
        try {
            await record.deleteItem()
            notification.success({
                message: "remove success!"
            })
        } catch (error) {
            notification.error({
                message: "remove fail!",
                description: error.message
            })
        }
    }

    render() {

        const { records, status, pagination } = this.props
        console.log(status)
        return (
            <div>
                <Table
                    dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => item["id"]}
                ></Table>
            </div>
        )
    }
}
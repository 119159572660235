import * as React from 'react';
import SplashScreen from '../components/SplashScreen';
import { AppState, AppStatus } from '../../stores/app';
import { BrowserRouter } from 'react-router-dom';
import { di } from 'jsmodules';
import { Logger } from '../../utils/logger';
import { observer } from 'mobx-react';
import { renderRoutes } from 'react-router-config';
import { routes } from './config';
import '../styles/index.less';
import "../styles/common.less"
import "../styles/customTheme.less"
//import "antd/dist/antd.css";


@observer
class AppRouter extends React.Component<{}, {}> {
    @di.Inject() app: AppState;

    componentWillMount() {
        this.app.start()
    }

    public render() {
        if (this.app.status == AppStatus.starting) {
            Logger.info("进入SplashScreen");
            return <SplashScreen />
        }
        
        Logger.info("进入主界面");
        return <BrowserRouter>
            {renderRoutes(routes)}
        </BrowserRouter>
    }
}

export default AppRouter;

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { ProductsState } from 'stores/products/products';
import Products from './components/Products';

export interface IProductsConProps extends RouteComponentProps {
}

export default class ProductsCon extends React.Component<IProductsConProps> {

    @di.Inject() productsState: ProductsState


    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        var { page, pagesize, ...filter } = queryParams;
        if (filter) {
            this.productsState.filter(filter, true)
        }
        page = page * 1 || 1;

        if (pagesize) {
            this.productsState.page(page, pagesize * 1)
        } else {
            this.productsState.page(page)
        }
        this.productsState.loadRecords();
    }


    public render() {

        return (
            <div className="main-container">
                <Products></Products>
            </div>
        );
    }
}

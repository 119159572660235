import React from 'react';
import { List, Rate } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';



@observer
export default class Comments extends React.Component<any, any>{

    pageChange = (page) => {
        const { soulCommentState } = this.props.soulRecord
        soulCommentState.page(page)
        soulCommentState.loadRecords()
    }

    pageSizeChange = (current, size) => {
        const { soulCommentState } = this.props.soulRecord
        soulCommentState.page(current, size)
        soulCommentState.loadRecords()
    }


    render() {

        const { soulRecord } = this.props

        const { records, pagination, status } = soulRecord.soulCommentState


        return (
            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={records}
                    loading={status == "loading"}
                    pagination={pagination.total == 0 ? false : {
                        ...pagination,
                        onChange: this.pageChange,
                        onShowSizeChange: this.pageSizeChange
                    }}
                    renderItem={item => (
                        <ListItem item={item}></ListItem>
                    )}
                />
            </div>
        )
    }
}


class ListItem extends React.Component<any, any>{
    render() {
        const { item } = this.props
        return (
            <List.Item>
                <List.Item.Meta
                    title={
                        <div style={{ display: "flex", alignItems: "baseline" }}>
                            <div style={{ flex: 1, display: "flex" }}>
                                <Link to={`/souls/${item.author.id}`} className="linkColor">{item.author.nickname}</Link>
                                <div style={{ marginLeft:36,marginRight:2 }}>@</div>
                                <Link to={`/mates/${item.to.content.id}`} className="linkColor">{item.to.author.nickname}</Link>
                            </div>

                            <Rate allowHalf defaultValue={5} disabled />
                        </div>
                    }
                    description={item.content}
                />
            </List.Item>
        )
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { Table } from 'antd';





@observer
export default class EditorList extends React.Component<any,any>{

    columns = [
        {
            title: "nickname",
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => <div className="text-ellipsis">{text}</div>
        },
        {
            title: "gender",
            dataIndex: "gender",
        },
        {
            title: "birthday",
            dataIndex: "birthday"
        },
        {
            title: "creation time",
            dataIndex: "creationTime"
        }
    ]

    handleTableChange = (pagination, filters, sorter) => {
        this.props.onChange({ page: pagination.current,pagesize:pagination.pageSize })
    }


    render(){

        const columns = this.columns;
        const { status, records, pagination } = this.props

        
        return(
            <div>
                <Table dataSource={records}
                    columns={columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => item.id }
                ></Table>
            </div>
        )
    }
}
import * as React from 'react';
import { observer } from 'mobx-react';
import { renderRoutes, matchRoutes } from 'react-router-config';
import { di } from 'jsmodules';
import { SessionState } from 'stores/session';
import { menus } from '../config';
import { Layout, Icon } from 'antd';
import Menus from 'components/Menus';
import "./styles.less"
import LayoutHeader from './LayoutHeader';
import { Redirect } from 'react-router-dom';


const { Header, Sider } = Layout

@observer
export default class MainLayout extends React.Component<any, any> {
    @di.Inject() session: SessionState

    state = {
        collapsed: false
    }

    toggole = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }



    public render() {
        var { route, location } = this.props;
        const { routes } = route;
        
        if (!this.session.isAuthenticated) {
            return <Redirect to="/login"></Redirect>
        }

        let navs = menus

        const theMatchRoutes = matchRoutes(routes, location.pathname)

        const selectedKeys = theMatchRoutes.map(item => {
            return item.match.path
        })



        return (
            <React.Fragment>
                <Layout className="main-layout">
                    <Sider
                        trigger={null}
                        collapsible
                        collapsed={this.state.collapsed}
                        onCollapse={this.toggole}
                        style={{ overflow: "auto", background: "#fff", borderRight: "1px solid #e8e8e8" }}
                    >
                        <div style={{ height: "100%", display: "flex", flexDirection: "column", overflowX: "hidden" }}>
                            <div style={{ height: 68, textAlign: "center", lineHeight: "68px", fontWeight: 600 }}>
                                sounmate
                            </div>

                            <div style={{ flex: 1 }}>
                                <Menus theme="light"
                                    mode="inline"
                                    selectedKeys={selectedKeys}
                                    style={{ border: "none" }}
                                    menus={navs}>
                                </Menus>
                            </div>


                            <div className="teacms-menu-toggle">
                                <Icon className="trigger"
                                    type={this.state.collapsed ? "menu-unfold" : "menu-fold"}
                                    onClick={this.toggole}></Icon>
                            </div>

                        </div>
                    </Sider>
                    <Layout>
                        <Header className="header">
                            <LayoutHeader></LayoutHeader>
                        </Header>
                        <Layout.Content style={{ overflow: "auto", background: "#fff" }} id="scroll-container" >
                            {renderRoutes(routes)}
                        </Layout.Content>
                    </Layout>
                </Layout>
            </React.Fragment>
        )

    }
}

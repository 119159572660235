import { observable } from 'mobx';
import { di } from 'jsmodules';
import { ReviewService } from 'services/review';

export class CommentItemState {
    @di.Inject() reviewService: ReviewService

    @observable reviewResult


    constructor(item) {
        this.update(item)
    }

    update(item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.reviewResult = item.review_result
    }

    
    @observable loading_review = false
    async handleReview(data) {
        try {
            await this.reviewService.handleReview(data)
            this.reviewResult = data.result
        } catch (error) {
            throw error
        }
    }

}
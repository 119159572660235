import React from 'react';
import { observer } from 'mobx-react';
import CallHistory from '../Souls/components/CallHistory';




@observer
export default class CallHistoryCon extends React.Component<any, any>{


    componentWillMount() {
        const { mateRecord } = this.props
        mateRecord.callHistory.filter({
            participant_ids: mateRecord.chatuser.id
        }, true)
        mateRecord.callHistory.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab } = this.props
        if(selectTab != nextProps.selectTab){
            if(nextProps.selectTab == "history"){
                nextProps.mateRecord.callHistory.loadRecords()
            }
        }
    }

    render() {
        const { mateRecord } = this.props


        return (
            <CallHistory record={mateRecord}></CallHistory>
        )
    }
}
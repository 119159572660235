class AudioPlayer {

    private manager: HTMLAudioElement;

    private play_url;
    private __play_token__ = {};
    private __stop_token__ = {};

    constructor() {
        this.manager = new Audio();
        this.manager.onended = this.onEnded;
        this.manager.onerror = this.onError;
        this.manager.onpause = this.onStop;
    }

    private onEnded = () => {
        const play_url = this.play_url;
        if (this.__play_token__[play_url]) {
            this.__play_token__[play_url].resolve(true);
            delete this.__play_token__[play_url];
        }
    };

    private onStop = () => {
        this.onEnded();
        const play_url = this.play_url;
        if (this.__stop_token__[play_url]) {
            this.__stop_token__[play_url].resolve(false);
            delete this.__stop_token__[play_url];
        }
    };

    private onError = () => {
        const url = this.play_url;
        if (this.__play_token__[url]) {
            this.__play_token__[url].reject();
            delete this.__play_token__[url];
        }
    };

    async play(url, options: any = {}) {
        await this.stop(); //播放前关闭 避免几个音源同时播放
        return new Promise((resolve, reject) => {
            this.play_url = url;
            this.__play_token__[this.play_url] = {
                resolve,
                reject,
            };
            this.manager.title = options.title || '音频播放';
            this.manager.src = url;

            this.manager.play();
        });
    }

    async stop(url?) {
        const play_url = this.play_url;
        if (!play_url) {
            return;
        }
        if (url && url != play_url) {
            return;
        }
        if (this.manager.paused) {
            this.onEnded();
            return;
        }
        return new Promise((resolve, reject) => {
            this.__stop_token__[play_url] = { resolve, reject };
            this.manager.pause();
        });
    }
}

export default new AudioPlayer();
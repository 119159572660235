export const BaseMomentFormat = "MM/DD/YYYY hh:mm a"
export const BirthdayFormat = "MM/DD/YYYY"
export const CallTimeFormat = "hh:mm:ss a"

export const CurrencyCodeIcon = {
    NTS: "♪",
    USD: "$",
    CNY: "￥"
}

export const patterns = {
    password: /^(?=.*\d)(?=.*[a-z]).{8,20}$/
}

import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { OrdersState } from 'stores/orders/orders';
import Orders from './components/Orders';

export interface IOrdersConProps extends RouteComponentProps {
}



export default class OrdersCon extends React.Component<IOrdersConProps> {

    @di.Inject() ordersState: OrdersState

    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        var { page, pagesize, ...filter } = queryParams;
        if (filter) {
            this.ordersState.filter(filter, true)
        }
        page = page * 1 || 1;

        if (pagesize) {
            this.ordersState.page(page, pagesize * 1)
        } else {
            this.ordersState.page(page)
        }
        this.ordersState.loadRecords();
    }

    public render() {
        return (
            <div className="main-container">
                <Orders></Orders>
            </div>
        );
    }
}

import React from 'react';
import { observer } from 'mobx-react';
import { Statistic, Col, Card, Row, Icon, Avatar } from 'antd';
import CustomIcon from 'components/CustomIcon';
import WalletsCon from '../WalletsCon';



@observer
export default class BaseInfo extends React.Component<any, any>{
    render() {

        const { record } = this.props

        return (
                <div style={{display: "flex"}}>
                    <div style={{flex:1,marginRight:24}}>
                        <div style={{ display: "flex", alignItems: "center", marginBottom: 12 }}>
                            <div style={{ marginRight: 24 }}>
                                {
                                    record.avatar == "" ?
                                        <Avatar style={{ backgroundColor: '#87d068' }} icon="user" size={90} />
                                        :
                                        <Avatar style={{ backgroundColor: '#87d068' }} src={record.avatar} size={90} />
                                }

                            </div>
                            <div>
                                <div style={{
                                    fontSize: 20,
                                    color: "#1a1e2a",
                                    fontWeight: 700,
                                    marginBottom: 8
                                }}>{record.nickname}</div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div style={{
                                        color: "#1a1e2a",
                                        fontSize: 14,
                                    }}>{record.constellation}</div>
                                    <div style={{ marginLeft: 6 }}>
                                        {
                                            record.gender == "Female" && <Icon type="woman" style={{ color: "#eb2f96" }}></Icon>
                                        }
                                        {
                                            record.gender == "Male" && <Icon type="man" style={{ color: "#1890ff" }}></Icon>
                                        }
                                        {
                                            record.gender == "Unknown" && <span></span>
                                        }
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div style={{ display: "flex", marginLeft: 20, alignItems: "flex-end" }}>
                            <div style={{ marginRight: 48 }}>
                                <div style={{ marginBottom: 4 }}>
                                    <Statistic value={record.mate_collect} valueStyle={{ color: "#1a1e2a", fontSize: 24, fontWeight: 600 }} ></Statistic>
                                </div>
                                <div style={{ color: "#7d818d", fontSize: 14, }}>favs</div>
                            </div>
                            <div>
                                <div style={{ marginBottom: 4 }}>
                                    <Statistic value={record.commentCount} valueStyle={{ color: "#1a1e2a", fontSize: 24, fontWeight: 600 }}></Statistic>
                                </div>
                                <div style={{ color: "#7d818d", fontSize: 14, }}>comments</div>
                            </div>
                        </div>

                    </div>



                    <div style={{minWidth: 300}}>
                        <WalletsCon soulRecord={record}></WalletsCon>
                    </div>
                </div>
        )
    }
}
import { CRUDState } from 'stores/_CRUDState';
import { di } from 'jsmodules';
import { CoinService } from 'services/coins';
import { observable } from 'mobx';

export class CoinState extends CRUDState {

    constructor(){
        super("coinService")
    }

    @observable loading_create = false

}
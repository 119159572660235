import Logger from '../../utils/logger';
import { di } from 'jsmodules';
import { observable } from 'mobx';
import { IAppPlugin, module, moduleFunc } from '.';
import { IKeyValueStorage } from '../../storage/IKeyValueStorage';
import { SessionState } from '../session';
import { wait } from 'jsmodules/lib/system/wait';

/**
 * 用户模块
 */
export class UserPlugin implements IAppPlugin {

    @di.Inject() private session: SessionState

    name = "user";

    __ready__: boolean;

    __installed__: boolean;

    @module
    async install() {
        if (this.session.isAuthenticated) {
            Logger.info("获取上次登录的用户信息")
            await this.session.getCurrentUserInfo();
        }
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { List, Row, Col, Descriptions } from 'antd';
import { Link } from 'react-router-dom';



@observer
export default class WorkOrderList extends React.Component<any, any>{
    render() {

        const { status, records, pagination } = this.props

        return (
            <List
                itemLayout="vertical"
                dataSource={records}
                loading={status == "loading"}
                pagination={{
                    ...pagination,
                    onChange: (page) => { this.props.onChange({ page: page }) },
                    onShowSizeChange: (current, size) => { this.props.onChange({ page: current, pagesize: size }) }
                }}
                renderItem={item => <ListItem record={item} />}
            ></List>
        )
    }
}


class ListItem extends React.Component<any, any>{
    render() {
        const { record } = this.props


        return (
            <List.Item style={{ padding: "24px 0" }}>
                <div style={{ display: "flex", alignItems: "baseline", marginBottom: 24 }}>
                    <div style={{ marginRight: 36, fontSize: 16, color: "rgba(0,0,0,.85)" }}>{`ID：${record.id}`}</div>
                    <div style={{ fontSize: 14, color: "rgba(0,0,0,.45)" }}>{record.creationTime}</div>
                </div>
                <div style={{ padding: 24, background: "#efefef", borderRadius: 4 }}>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Descriptions title="Customer" column={1}>
                                <Descriptions.Item label="Nickname">
                                    {
                                        record.customer.type == "Soul" && <Link to={`/souls/${record.customer.id}`}>{record.customer.nickname}</Link>
                                    }
                                    {
                                        record.customer.type == "Mate" && <Link to={`/mates/${record.customer.id}`}>{record.customer.nickname}</Link>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="Telephone">{record.customerPhone}</Descriptions.Item>
                                <Descriptions.Item label="type">{record.customer.type}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions title="Target" column={1}>
                                <Descriptions.Item label="Nickname">
                                    {
                                        record.target.type == "Soul" && <Link to={`/souls/${record.target.id}`}>{record.target.text}</Link>
                                    }
                                    {
                                        record.target.type == "Mate" && <Link to={`/mates/${record.target.id}`}>{record.target.text}</Link>
                                    }
                                </Descriptions.Item>
                                <Descriptions.Item label="type">{record.target.type}</Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={8}>
                            <Descriptions title="Question" column={1}>
                                {
                                    record.target.question_tag_names.length == 0 ?
                                        <Descriptions.Item label="Description">
                                            {record.content}
                                        </Descriptions.Item>
                                        :
                                        <Descriptions.Item>
                                            <div style={{ display: "flex" }}>
                                                <div style={{ color: "rgba(0,0,0,.85)", marginRight: 8 }}>Tags:</div>
                                                <div>
                                                    {
                                                        record.target.question_tag_names.map(item => {
                                                            return <div key={item}>{item}</div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                }

                            </Descriptions>
                        </Col>
                    </Row>
                </div>
            </List.Item>
        )
    }

}
import { observable } from 'mobx';
import moment  from 'moment';

var startNow=moment().unix();
export class ServerTimeState {
    serverTime = startNow;
    clientTime= startNow

    getServerNow(){
        var now=moment().unix()

        return this.serverTime +now-this.clientTime;

    }

    set(date){
        this.serverTime = moment(date).unix()
        this.clientTime = moment().unix()
    }

}
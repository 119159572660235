import React from 'react';
import { observer } from 'mobx-react';
import { List, Icon, Carousel } from 'antd';
import CustomIcon from 'components/CustomIcon';
import VoiceCell from 'web/components/VoiceCell';
import ReviewCell from 'web/routes/Review/ReviewCell';
import { Link } from 'react-router-dom';


@observer
export default class MateCardList extends React.Component<any, any>{
    render() {

        const { records, is_loading } = this.props

        return (
            <div>
                <List
                    grid={{
                        gutter: 32,
                        xs: 3,
                        sm: 3,
                        md: 3,
                        lg: 3,
                        xl: 3,
                        xxl: 4,
                    }}
                    dataSource={records}
                    loading={is_loading}
                    renderItem={item => (<ListItem record={item} />)}
                ></List>
            </div>
        )
    }
}



@observer
class ListItem extends React.Component<any, any>{
    render() {

        const { record } = this.props

        return (
            <List.Item>
                <div className="mate-list-card-item" style={{
                    borderRadius: 4,
                    color: "#8B49FF",
                    fontSize: 12,
                    position: "relative",
                }}>
                    <div style={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        zIndex: 9
                    }}>
                        <ReviewCell record={record} type="Mate"></ReviewCell>
                    </div>

                    <Carousel dotPosition="bottom" draggable={true}>
                        {
                            record.images.map(image => {
                                return <div key={image.url}>
                                    <div style={{
                                        overflow: "hidden",
                                        backgroundImage: `url(${image.target_url})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderTopLeftRadius: 4,
                                        borderTopRightRadius: 4
                                    }}>
                                        <div style={{ marginBottom: "120%" }}></div>
                                    </div>
                                </div>


                            })
                        }
                    </Carousel>



                    <div style={{
                        background: "#040219",
                        position: "absolute",
                        bottom: 4,
                        width: "100%",
                        height: 188,
                        opacity: 0.6,
                        borderTopLeftRadius: 20,
                        borderTopRightRadius: 20
                    }}>

                    </div>


                    <div style={{
                        background: "transparent",
                        paddingTop: 8,
                        paddingLeft: 16,
                        paddingRight: 8,
                        paddingBottom: 8,
                        position: "absolute",
                        bottom: 0,
                        width: "100%"
                    }}>
                        <Link to={`/mates/${record.id}`} style={{ color: "inherit" }}>
                            <div style={{ display: "flex", alignItems: "center", paddingBottom: 24, }}>
                                <div style={{ flex: 1, marginRight: 4 }}>
                                    <div className="text-ellipsis" style={{ fontSize: 18, fontWeight: 600, width: 150, marginBottom: 2 }}>{record.nickname}</div>
                                    <div>
                                        <span style={{ background: "#8B49FF", borderRadius: 20, color: "#171430", padding: "0 8px" }}>{record.constellation}</span>
                                        <span style={{ marginLeft: 6 }}>
                                            {
                                                record.gender == "Female" && <Icon type="woman" style={{ color: "#eb2f96" }}></Icon>
                                            }
                                            {
                                                record.gender == "Male" && <Icon type="man" style={{ color: "#1890ff" }}></Icon>
                                            }
                                            {
                                                record.gender == "Unknown" && <span></span>
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ fontSize: 32, fontWeight: 600, lineHeight: 1 }}>{record.unitPrice}</span>
                                    <span><CustomIcon type="icon-yinfu" /></span>
                                    <span>/{record.unitTimeSeconds / 60}mins</span>
                                </div>
                            </div>
                        </Link>


                        <div style={{ display: "flex", alignItems: "flex-end" }}>
                            <Link to={`/mates/${record.id}`} style={{ color: "inherit", flex: 1, }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-around"
                                }}>
                                    <div>
                                        <div><Icon type="star" theme="filled" /></div>
                                        <div>{record.averageScore}/5</div>
                                    </div>
                                    <div>
                                        <div><Icon type="phone" theme="filled" /></div>
                                        <div>{record.serviceTimes}</div>
                                    </div>
                                    <div>
                                        <div><Icon type="clock-circle" theme="filled" /></div>
                                        <div>{record.serviceTime}</div>
                                    </div>
                                </div>
                            </Link>
                            <div style={{
                                paddingLeft: 16
                            }}>
                                <VoiceCell content={record.voice[0]}></VoiceCell>
                            </div>
                        </div>

                        <Link to={`/mates/${record.id}`} style={{ color: "inherit", paddingTop: 8 }}>
                            <div style={{ maxHeight: 54, minHeight: 37 }} className="multi-ellipsis">{record.about}</div>
                        </Link>
                    </div>



                </div>
            </List.Item>
        )
    }
}
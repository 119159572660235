import React from 'react';
import Transfers from './components/Transfers';
import { observer } from 'mobx-react';
import _Transfers from './components/_Transfers';



@observer
export default class TransfersCon extends React.Component<any, any>{

    render() {
        const { record } = this.props
        return (
            <_Transfers record={record}></_Transfers>
        )
    }
}
import { di } from 'jsmodules'
import { COSUrlConverter } from 'services/urlConvert'
import moment from 'moment'
import { BaseMomentFormat } from 'config/common'

export class WorkOrderItemState {
    @di.Inject() urlConvert: COSUrlConverter

    customerAvatar = ""
    creationTime = ""
    customerPhone = ""

    constructor(item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.customerAvatar = item.customer.avatar ?  this.handleAvatar(item.customer.avatar) : ""
        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
        this.customerPhone = `+${item.contact_phone.country_code} ${item.contact_phone.area_number}`
    }

    handleAvatar = (url) => {
        var temp = ""
        if (url.indexOf("http") != -1) {
            temp = url
        } else {
            temp = this.urlConvert.url(url).imageCdn().thumbnail(132, 132)
        }
        return temp
    }

}
import { di } from 'jsmodules';
import { MemberService } from 'services/member';
import { observable } from 'mobx';
import { WalletTransationState } from './walletTransation';

export class MateWalletState {
    @di.Inject() memberService: MemberService

    walletTransationState: WalletTransationState


    @observable id

    constructor(public user_id) {

    }

    @observable balances = []
    async loadWallets() {
        var res = await this.memberService.loadWallet({ owner_user_ids: this.user_id })
        this.balances = res.data.result.items[0].currency_balances
        
        this.walletTransationState = new WalletTransationState()

        this.id = res.data.result.items[0].id
    }
}
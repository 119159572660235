import React from 'react';
import CallHistory from './components/CallHistory';
import { observer } from 'mobx-react';




@observer
export default class CallHistoryCon extends React.Component<any, any>{


    componentWillMount() {
        const {soulRecord} = this.props
        soulRecord.callHistory.filter({ 
            participant_ids: soulRecord.chatuser.id
         }, true)
        soulRecord.callHistory.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab } = this.props
        if(selectTab != nextProps.selectTab){
            if(nextProps.selectTab == "history"){
                nextProps.soulRecord.callHistory.loadRecords()
            }
        }
    }

    render() {
        const {soulRecord} = this.props
        

        return (
            <CallHistory record={soulRecord}></CallHistory>
        )
    }
}
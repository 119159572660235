import React from 'react';
import { Radio } from 'antd';




export default class Actions extends React.Component<any, any>{

    reviewResultChange = (e) => {
        this.props.onChange({ review_result: e.target.value, page: 1 })
    }

    render() {

        const { reviewresult } = this.props

        return (
            <div>
                <span style={{ color: "#101934", minWidth: 80, marginRight: 36, lineHeight: "32px" }}>review result</span>
                <Radio.Group defaultValue={reviewresult} onChange={this.reviewResultChange}>
                    <Radio value="">all</Radio>
                    <Radio value="Normal">Normal</Radio>
                    <Radio value="Illegal">Illegal</Radio>
                </Radio.Group>
            </div>
        )
    }
}
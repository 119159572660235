import React from 'react';
import { Dropdown } from 'antd';




export default class PaymethodCell extends React.Component<any, any>{
    render() {

        const { trade } = this.props


        const Content = (
            <div style={{ background: "#f0f0f0", padding: "8px 16px", borderRadius: 2 }}>
                <div>
                    <span>payment key:  </span>
                    <span>{trade.idempotent_key}</span>
                </div>
                {
                    trade.method === "AppleIAP" &&
                    <div style={{ marginTop: 8 }}>
                        <span>IsProduction:  </span>
                        <span>{trade.extend_data.IsProduction || ""}</span>
                    </div>
                }
            </div>
        )

        return (
            <div>
                <Dropdown overlay={Content} trigger={["click"]} >
                    <a>{trade.method}</a>
                </Dropdown>
            </div>
        )
    }
}
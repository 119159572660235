import React from 'react';
import { Input, Select } from 'antd';


const Option = Select.Option


export default class AddPriceCell extends React.Component<any, any> {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        return null;
    }

    constructor(props) {
        super(props);

        const value = props.value || {};
        this.state = {
            number: value.number || 0,
            currency: value.currency || 'CNY',
        };
    }

    handleNumberChange = (e) => {
        var reg = e.target.value.match(/^\d+(?:\.\d{0,2})?/);
        var number = 0
        if (reg != null) {
            number = reg[0];
        }
        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    };


    handleNumberBlur = (e) => {
        var number = parseFloat(e.target.value)

        if (!('value' in this.props)) {
            this.setState({ number });
        }
        this.triggerChange({ number });
    }


    handleCurrencyChange = currency => {
        if (!('value' in this.props)) {
            this.setState({ currency });
        }
        this.triggerChange({ currency });
    };

    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...this.state,
                ...changedValue,
            });
        }
    };

    render() {
        const { currency, number } = this.state;
        return (
            <span>
                <Select
                    value={currency}
                    style={{ width: '27%', marginRight: '3%' }}
                    onChange={this.handleCurrencyChange}
                >
                    <Option value="NTS">NTS</Option>
                    <Option value="CNY">CNY</Option>
                    <Option value="USD">USD</Option>
                </Select>
                <Input
                    value={number}
                    type="text"
                    onChange={this.handleNumberChange}
                    onBlur={this.handleNumberBlur}
                    style={{ width: '70%' }}
                />
            </span>
        );
    }
}
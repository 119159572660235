import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { di, qs } from 'jsmodules';
import { CategoriesState } from 'stores/categories/categories';
import CategoriesList from './CategoriesList';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';


export interface ICategoriesProps extends RouteComponentProps {
}




@observer
class Categories extends React.Component<ICategoriesProps> {
    @di.Inject() categoriesState: CategoriesState


    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/categories?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    public render() {

        const { status, records, pagination, totalCount } = this.categoriesState

        return (
            <div className="main-content">
                <div style={{
                    color: "#D462FB",
                    fontSize: 24,
                    marginBottom: 36
                }}>
                    <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Categories</span>
                </div>

                <div style={{marginBottom:24}}>
                    <Link to="/categories/new"><Button type="primary">Add Category</Button></Link>
                </div>

                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <CategoriesList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></CategoriesList>
                </div>
            </div>
        );
    }
}

export default withRouter(Categories)
import React from 'react';
import { observer } from 'mobx-react';
import { Statistic, Col, Card, Row, Icon, Avatar, Button } from 'antd';
import CustomIcon from 'components/CustomIcon';
import WalletCon from '../WalletCon';
import MateDetails from 'web/components/ImageList/MateDetails';
import ReviewCell from 'web/routes/Review/ReviewCell';
import VocieInMateDetailCell from 'web/components/VocieInMateDetailCell';
import { Link } from 'react-router-dom';



@observer
export default class BaseInfo extends React.Component<any, any>{
    render() {

        const { record } = this.props

        return (
            <div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1, marginRight: 24, display: "flex", flexDirection: "column" }}>

                        <div style={{ display: "flex", alignItems: "baseline" }}>
                            <div style={{ display: "flex", alignItems: "center", marginBottom: 12, flex: 1 }}>
                                <div style={{ marginRight: 24 }}>
                                    {
                                        record.avatar == "" ?
                                            <Avatar style={{ backgroundColor: '#87d068' }} icon="user" size={90} />
                                            :
                                            <Avatar style={{ backgroundColor: '#87d068' }} src={record.avatar} size={90} />
                                    }

                                </div>
                                <div>
                                    <div style={{ display: "flex", marginBottom: 8, alignItems: "baseline" }}>
                                        <div style={{
                                            fontSize: 20,
                                            color: "#1a1e2a",
                                            fontWeight: 700,
                                            marginRight: 24
                                        }}>{record.nickname}</div>
                                        <div>
                                            <ReviewCell record={record} type="Mate"></ReviewCell>
                                        </div>
                                    </div>


                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{
                                            color: "#1a1e2a",
                                            fontSize: 14,
                                        }}>{record.constellation}</div>
                                        <div style={{ marginLeft: 6 }}>
                                            {
                                                record.gender == "Female" && <Icon type="woman" style={{ color: "#eb2f96" }}></Icon>
                                            }
                                            {
                                                record.gender == "Male" && <Icon type="man" style={{ color: "#1890ff" }}></Icon>
                                            }
                                            {
                                                record.gender == "Unknown" && <span></span>
                                            }
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <Link to={`/mates/${record.id}/edit`}>
                                <Button type="primary" icon="edit">Edit</Button>
                            </Link>
                        </div>


                        <div style={{ flex: 1 }}></div>

                        <div style={{ display: "flex", marginLeft: 20, alignItems: "flex-end", }}>

                            <div style={{ marginRight: 48 }}>
                                <VocieInMateDetailCell content={record.voice[0]}></VocieInMateDetailCell>
                            </div>

                            <div style={{ color: "#8B49FF", fontWeight: 600, letterSpacing: "1px", display: "flex", alignItems: "baseline", marginRight: 48 }}>
                                <span style={{ fontSize: 40, lineHeight: 1 }}>{record.unitPrice}</span>
                                <span><CustomIcon type="icon-yinfu" /></span>
                                <span>/{record.unitTimeSeconds / 60}mins</span>
                            </div>

                            <div style={{ marginRight: 48 }}>
                                <div style={{ marginBottom: 4 }}>
                                    <Statistic value={record.averageScore} valueStyle={{ color: "#1a1e2a", fontSize: 24, fontWeight: 600 }} precision={1} ></Statistic>
                                </div>
                                <div style={{ color: "#7d818d", fontSize: 14, }}>score</div>
                            </div>
                            <div>
                                <div style={{ marginBottom: 4 }}>
                                    <Statistic value={record.collectCount} valueStyle={{ color: "#1a1e2a", fontSize: 24, fontWeight: 600 }}></Statistic>
                                </div>
                                <div style={{ color: "#7d818d", fontSize: 14, }}>collected</div>
                            </div>

                            {
                                record.reviewResult == "Illegal" &&
                                <div style={{ marginLeft: 48 }}>
                                    <div style={{ marginBottom: 4 }}>
                                        <React.Fragment>
                                            {
                                                record.reviewResult == "Normal" && <div style={{ color: "#52c41a", fontSize: 24, fontWeight: 600 }}>Normal</div>
                                            }
                                            {
                                                record.reviewResult == "Illegal" && <div style={{ color: "#f5222d", fontSize: 24, fontWeight: 600 }}>Illegal</div>
                                            }
                                        </React.Fragment>
                                    </div>
                                    <div style={{ color: "#7d818d", fontSize: 14, }}>review result</div>
                                </div>
                            }
                        </div>

                    </div>



                    <div style={{ minWidth: 300 }}>
                        <WalletCon mateRecord={record}></WalletCon>
                    </div>
                </div>

                {
                    record.images.length != 0 &&
                    <div style={{ marginTop: 24 }}>
                        <MateDetails images={record.images}></MateDetails>
                    </div>
                }
            </div>
        )
    }
}




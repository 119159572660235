import cdnPaths from '../config/cdn';




class COSUrlBuilder {

    target_url;

    constructor(url) {
        this.target_url = url;
    }

    imageCdn() {
        return this.cdnWarpper("image");
    }
    imageytoCdn(){
        return this.cdnWarpper("image_yto");
    }
    videoCdn() {
        return this.cdnWarpper("file");
    }
    downloadCdn() {
        return this.cdnWarpper("download");;
    }

    private cdnWarpper(type) {
        if (/https?:\/\//.test(this.target_url)) {
            return this;
        }
        if (!this.target_url) {
            return this;
        }
        if (!type) {
            throw new Error("没有设置文件类型")
        }
        this.target_url = `${cdnPaths[type]}${this.target_url}`;
        return this;;
    }

    /**
    * 缩略图
    * @param url 原url
    * @param minwidth 最大宽度
    * @param minheight 最小宽度
    */
    thumbnail(minwidth, minheight) {
        if (!this.target_url) {
            return null;
        }
        return `${this.target_url}?imageMogr2/thumbnail/!${minwidth}x${minheight}r`;
    }
    /**
     * 头像
     * @param url 原url
     * @param cut 裁剪配置,(x,y)起点坐标,(width,height)裁剪宽高
     */
    avatar(cut?: { width, height, x, y }) {
        if (!this.target_url) {
            return null;
        }
        var cut_str = "";
        if (cut) {
            var { width, height, x, y } = cut;
            cut_str = `/cut/${width}x${height}-${x}x${y}`;
        }
        return `${this.target_url}?imageMogr2${cut_str}/thumbnail/132x132`
    }

    toString() {
        return this.target_url;
    }
}

//添加腾讯云图片样式
export class COSUrlConverter {

    url(url) {
        return new COSUrlBuilder(url);
    }

    getFileInfo(uri) {

        var index = uri.lastIndexOf(".");
        //获取后缀
        var ext = uri.substr(index + 1);
        var contentType = ""
        switch (ext) {
            case 'jpg':
            case 'jpeg':
                contentType = "image/jpeg";
                break;
            case 'png':
                contentType = "image/png";
                break;
            case 'gif':
                contentType = "image/gif";
                break;
            default:
                contentType = "application/octet-stream"
                break;
        }

        return {
            contentType,
            ext
        }

    }


}

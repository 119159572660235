import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Details from './components/Details';
import { di } from 'jsmodules';
import { SoulState } from 'stores/souls/Soul';
import { SoulItemState } from 'stores/souls/soulItemState';


export interface IDetailsConProps extends RouteComponentProps<{ id }> {
}

export default class DetailsCon extends React.Component<IDetailsConProps> {
    @di.Inject() soulState: SoulState

    componentWillMount() {

        const { id } = this.props.match.params

        this.soulState.recordState.load(id, item => new SoulItemState(item))

    }

    public render() {
        return (
            <div className="main-container">
                <Details></Details>
            </div>
        );
    }
}

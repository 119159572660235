import React from 'react';
import Wallets from './components/Wallets';




export default class WalletCon extends React.Component<any,any>{

    componentWillMount() {
        const {mateRecord} = this.props
        mateRecord.walletState.loadWallets()
    }

    render(){

        const {mateRecord} = this.props
        return(
            <Wallets mateRecord={mateRecord}></Wallets>
        )
    }
}
import React from 'react';
import { Checkbox } from 'antd';
import { observer } from 'mobx-react';



const CheckboxGroup = Checkbox.Group;

const plainOptions = ["DialHangUp", "DialTimeout", "Dialing", "Calling", "End"];
const defaultCheckedList = ["DialHangUp", "DialTimeout", "Dialing", "Calling", "End"];



@observer
export default class Actions extends React.Component<any, any>{

    onChange = checkedList => {
        this.props.onChange({
            statuses: checkedList.join(","),
            page: 1,
        })
    };

    onCheckAllChange = e => {
        this.props.onChange({
            statuses: e.target.checked ? plainOptions.join(",") : "",
            page: 1
        })
    };

    

    render() {

        const { statuses, status } = this.props

        let checkedList = statuses.length == 0 ? defaultCheckedList : statuses

        let indeterminate = checkedList.length != 0 && checkedList.length < plainOptions.length
        let checked_all = checkedList.length === plainOptions.length

        return (
            <div>
                <div>
                    <span style={{
                        color: "#101934",
                        minWidth: 80,
                        marginRight: 36,
                        lineHeight: "32px"
                    }}>statuses</span>

                    <Checkbox
                        indeterminate={indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={checked_all}
                        style={{ marginRight: 24 }}
                        disabled={status == "loading"}
                    >
                        Check all
                </Checkbox>

                    <CheckboxGroup
                        options={plainOptions}
                        value={checkedList}
                        onChange={this.onChange}
                        disabled={status == "loading"}
                    />
                </div>
            </div>

        )
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { CategoriesState } from 'stores/categories/categories';
import { CategoriesItemState } from 'stores/categories/categoriesItem';
import Items from './components/Items';

export interface IItemsConProps extends RouteComponentProps<{ id }> {
}




export default class ItemsCon extends React.Component<IItemsConProps> {
    @di.Inject() categoriesState: CategoriesState


    componentWillMount() {
        this.handleLoad(this.props)
    }

    componentWillUpdate(nextProps) {
      if(this.props.location.search != nextProps.location.search){
          this.handleLoad(nextProps)
      }
    }

    handleLoad(props){
        var {search} = props.location
        const { id } = this.props.match.params
        var queryParams = qs.decode(search) || {page: 1}

        let record:CategoriesItemState = this.categoriesState._get(id)


        var {page,pagesize,...filter} = queryParams
        if(filter){
            record.categoryProductState.filter(filter,true)
        }
   
        page = page*1 || 1;
        if(pagesize){
            record.categoryProductState.page(page,pagesize*1)
        }else{
            record.categoryProductState.page(page)
        }
        record.categoryProductState.loadRecords()
    }

    public render() {
        return (
            <div className="main-container">
                <Items></Items>
            </div>
        );
    }
}

import React from 'react';
import { Tabs } from 'antd';
import CallMissedCon from './CallMissedCon';
import CallHistoryCon from './CallHistoryCon';
import { observer } from 'mobx-react';


const TabPane = Tabs.TabPane

@observer
export default class CallsCon extends React.Component<any, any>{

    state = {
        key: "history"
    }


    componentWillMount() {
        this.props.soulRecord.loadChatuser()
    }

    keyChange = (key) => {
        this.setState({
            key: key
        })
    }

    render() {
        const { soulRecord } = this.props
        const { key } = this.state

        if (soulRecord.loading_chatuser) {
            return <div></div>
        }

        return (
            <div>
                <Tabs activeKey={key} onChange={this.keyChange}>
                    <TabPane tab="Call History" key="history">
                        <CallHistoryCon soulRecord={soulRecord} selectTab={key}></CallHistoryCon>
                    </TabPane>
                    <TabPane tab="Call Missed" key="missed">
                        <CallMissedCon soulRecord={soulRecord} selectTab={key}></CallMissedCon>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
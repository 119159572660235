import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { Form, Button, Input, DatePicker, Cascader, Radio, notification, } from "antd"
import { di } from 'jsmodules';
import { UploadState } from 'stores/upload';
import { observer } from 'mobx-react';
import options from 'utils/cascader-address-options';
import moment from 'moment'
import { EditorState } from 'stores/editor/editor';
import AddPhoneCell from './AddPhoneCell';
import { AccountService } from 'services/account';
import { patterns } from 'config/common';
import AvatarUpload from 'components/ImageUpload/AvatarUpload';
import { AccountState } from 'stores/accounts/account';
import { wait } from 'jsmodules/lib/system/wait';
import { EditorService } from 'services/editor';
import { FormatUploadUrl } from 'stores/formatUploadUrl';



const RadioGroup = Radio.Group


@observer
class AddForm extends React.Component<RouteComponentProps & FormComponentProps> {
    @di.Inject() editorState: EditorState
    @di.Inject() editorService: EditorService
    @di.Inject() uploadState: UploadState
    @di.Inject() accountState: AccountState
    @di.Inject() accountService: AccountService
    @di.Inject() formatUploadUrl: FormatUploadUrl



    checkPhoneNumber = async (rule, value, callback) => {

        if (value.country_code == "1" && value.area_number.length != 10) {
            callback("invalid phone number")
            return
        }

        if (value.country_code == "86" && value.area_number.length != 11) {
            callback("invalid phone number")
            return
        }

        if (value.country_code == "852" && value.area_number.length != 8) {
            callback("invalid phone number")
            return
        }

        if (value.country_code == "65" && value.area_number.length != 8) {
            callback("invalid phone number")
            return
        }

        try {
            var res = await this.accountService.checkPhoneNumberExsit(value.country_code, value.area_number)
            if (res.status == 200) {
                callback('phone number already exists!')
            } else {
                callback()
            }
        } catch (error) {
            callback();
        }

    }


    waitUserEditorId = async (user_id) => {
        var param = {
            user_ids: user_id,
            limit: 20,
            skip: 0
        }
        var res = await this.editorService.loadEditors(param);
        var result = res.data.result.items

        if (result.length == 0) {
            await wait(1000);
            return this.waitUserEditorId(user_id);
        }
        return result[0]
    }


    handleAdd = (e) => {
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.accountState.loading_add = true

                var postData = {
                    "address": {
                        "city": "",
                        "country": "",
                        "detail": "",
                        "district": "",
                        "province": ""
                    },
                    "avatar": "",
                    "birthday": values.birthday.utc().format(),
                    "gender": values.gender,
                    "password": values.password,
                    "phone_number": {
                        "area_number": values.phone.area_number,
                        "country_code": values.phone.country_code
                    },
                    "add_roles": [
                        "Editor"
                    ]
                }

                if (values.images.length != 0) {
                    var aa = await this.uploadState.upload(values.images[0])
                    postData.avatar = this.formatUploadUrl.formatImageUrl(aa.filePath) 
                }



                try {
                    var res = await this.accountState.create(postData)

                    var editor_Res = await this.waitUserEditorId(res.data.result.id)
                    this.editorState.new_add_editor_id = editor_Res.id
                    this.props.form.resetFields();
                    notification.success({
                        message: "add editor success"
                    })
                    this.editorState.loading_add = false

                    this.editorState.step = "2"

                } catch (error) {
                    this.editorState.loading_add = false
                    return notification.error({
                        message: "add editor fail",
                        description: error.message
                    })
                }

            }
        })
    }



    public render() {

        const { getFieldDecorator } = this.props.form
        const passwordPattern = patterns.password

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18, offset: 2 },
            },
        };

        return (
            <div style={{
                maxWidth: 680,
                marginBottom: 140
            }}>
                <Form {...formItemLayout}>
                    <Form.Item label="phone" >
                        {
                            getFieldDecorator("phone", {
                                initialValue: { area_number: "", country_code: '1' },
                                rules: [
                                    { required: true, message: "can not be null" },
                                    {
                                        validator: this.checkPhoneNumber
                                    }
                                ]
                            })(
                                <AddPhoneCell></AddPhoneCell>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="password" >
                        {getFieldDecorator("password", {
                            rules: [
                                { required: true, message: "can not be null" },
                                { pattern: passwordPattern, message: "contain both letters and numbers and 8-20 digits" }
                            ]
                        })(
                            <Input type="password"></Input>
                        )}
                    </Form.Item>

                    <Form.Item label="birthday" >
                        {getFieldDecorator('birthday', {
                            initialValue: null
                        })(
                            <DatePicker></DatePicker>
                        )}
                    </Form.Item>


                    <Form.Item label="gender" >
                        {getFieldDecorator('gender', {
                            initialValue: "Male",
                            rules: [{ required: true, message: "can not be null" }]
                        })(
                            <RadioGroup>
                                <Radio value="Male">Male</Radio>
                                <Radio value="Female">Female</Radio>
                            </RadioGroup>
                        )}
                    </Form.Item>


                    <Form.Item label="avatar" >
                        {getFieldDecorator('images', {
                            initialValue: []
                        })(
                            <AvatarUpload />
                        )}
                    </Form.Item>



                </Form>

                <div style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 68,
                    lineHeight: "68px",
                    marginLeft: 200,
                    zIndex: 100,
                    backgroundColor: "#fff",
                    textAlign: "right",
                    borderTop: "1px solid #d8d8d8"
                }}>
                    <Button
                        type="primary"
                        icon="save"
                        style={{ marginRight: "20%" }}
                        loading={this.accountState.loading_add}
                        onClick={this.handleAdd}
                    >add</Button>
                </div>
            </div>
        );
    }
}





const _AddForm = Form.create<RouteComponentProps & FormComponentProps>()(AddForm)


export default withRouter(_AddForm)


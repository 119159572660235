import { CRUDState } from 'stores/_CRUDState';
import { CommentItemState } from './commentItem';

export class CommentState extends CRUDState {
    constructor() {
        super("commentService", item => new CommentItemState(item))
    }

    init() {
        this.records = []
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { EditorState } from 'stores/editor/editor';
import Editors from './components/Editors';

export interface IEditorConProps extends RouteComponentProps {
}

export default class EditorCon extends React.Component<IEditorConProps> {
    @di.Inject() editorState: EditorState


    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        var { page, pagesize, ...filter } = queryParams;
        if (filter) {
            this.editorState.filter(filter, true)
        }
        page = page * 1 || 1;

        if (pagesize) {
            this.editorState.page(page, pagesize * 1)
        } else {
            this.editorState.page(page)
        }
        this.editorState.loadRecords();
    }

    

    public render() {
        return (
            <div className="main-container">
                <Editors></Editors>
            </div>
        );
    }
}

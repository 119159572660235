import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class ProductsService extends CRUDService {
    @di.Inject("marketing_api_v1") http: HttpFactory

    constructor() {
        super('/admin/products', "marketing_api_v1")
    }

    publish(id) {
        return this.http.url(`/admin/products/${id}/publish`).addSecurityHeaders().put()
    }

    unpublish(id) {
        return this.http.url(`/admin/products/${id}/unpublish`).addSecurityHeaders().put()
    }

    loadProducts(params){
        return this.http.url("/admin/products").addSecurityHeaders().get(params)
    }

    updateQuantity(id,data){
        return this.http.url(`/admin/products/${id}/inventory`).addSecurityHeaders().put(data)
    }

    updateProductInfo(id,data){
        return this.http.url(`/admin/products/${id}`).addSecurityHeaders().put(data)
    }
}
import { CRUDState } from 'stores/_CRUDState';
import { ProductItemState } from './productItem';
import { observable } from 'mobx';

export class ProductsState extends CRUDState {
    constructor() {
        super("productsService", item => new ProductItemState(item))
    }


    @observable loading_add = false
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { Card, Tabs } from 'antd';
import BaseInfo from './BaseInfo';
import { di } from 'jsmodules';
import { MateState } from 'stores/mates/mate';
import CommentsCon from '../CommentsCon';
import PaymentsCon from '../PaymentsCon';
import CallsCon from '../CallsCon';

const TabPane = Tabs.TabPane


export interface IDetailsProps extends RouteComponentProps<{ id }> {
}


@observer
class Details extends React.Component<IDetailsProps> {
    @di.Inject() mateState: MateState

    public render() {

        const { record, status } = this.mateState.recordState


        if (status == "loading") {
            return <div></div>
        }


        return (
            <div className="main-content">
                <BaseInfo record={record}></BaseInfo>

                <div style={{ marginTop: 48 }}>
                    <Tabs>

                        <TabPane tab="Calls" key="call">
                            <CallsCon mateRecord={record}></CallsCon>
                        </TabPane>

                        {
                            record.walletState.id &&
                            <TabPane tab="Transaction" key="transaction">
                                <PaymentsCon mateRecord={record}></PaymentsCon>
                            </TabPane>
                        }

                        {
                            record.soul_id &&
                            <TabPane tab="Comments" key="comment">
                                <CommentsCon mateRecord={record}></CommentsCon>
                            </TabPane>
                        }

                    </Tabs>
                </div>


            </div>
        );
    }
}

export default withRouter(Details)

import { di, HttpFactory } from 'jsmodules';
import TokenService from './token';


export const UserRoles = {
    All: "All",
    Admin: "Admin",
    Assistant: "Assistant",
    Sales: "Sales",
    Student: "Student"
}

export default class UsersService {

    @di.Inject() identity_api_v1: HttpFactory
    @di.Inject("content_api_v1") http: HttpFactory

    @di.Inject() tokenService: TokenService;

    async me() {
        return this.identity_api_v1.url(`/users/me`).addSecurityHeaders().get();
    }

    editorMe(){
        return this.http.url("/editors/me").addSecurityHeaders().get()
    }

    async updatePassword(password, new_password) {
        return this.identity_api_v1.url(`/users/current/password`).addSecurityHeaders().put({
            new_password,
            password
        })
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { MateState } from 'stores/mates/mate';
import Mates from './components/Mates';

export interface IMatesConProps extends RouteComponentProps {
}

export default class MatesCon extends React.Component<IMatesConProps> {
    @di.Inject() mateState: MateState

    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        this.mateState.review_result = queryParams.review_result || null

        this.mateState.loadMates();
    }

    componentDidMount() {
        const srcollDom = document.getElementById("scroll-container")
        srcollDom.addEventListener("scroll", (e) => {
            this.onScroll(e.target)
        })
    }


    onScroll = (scrollDom) => {
        const { mateRecords, totalCount } = this.mateState
        const { clientHeight, scrollHeight, scrollTop } = scrollDom;
        const can_load = clientHeight + Math.ceil(scrollTop) > scrollHeight - 100; //防止有些人设置了windows的缩放比例导致不能滚动到底部
        if (can_load && mateRecords.length < totalCount) {
            this.mateState.loadMoreMates()
        }
    }




    public render() {
        return (
            <div className="main-container">
                <Mates></Mates>
            </div>
        );
    }
}

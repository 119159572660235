import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { Form, Button, Input, Select, InputNumber, notification } from "antd"
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { di } from 'jsmodules';
import { CategoriesService } from 'services/categories';
import _ from 'lodash';
import { UploadState } from 'stores/upload';
import { CategoriesState } from 'stores/categories/categories';
import { observer } from 'mobx-react';
import { FormatUploadUrl } from 'stores/formatUploadUrl';


const TextArea = Input.TextArea


@observer
class AddForm extends React.Component<RouteComponentProps & FormComponentProps> {
    @di.Inject() categoriesService: CategoriesService
    @di.Inject() categoriesState: CategoriesState
    @di.Inject() uploadState: UploadState
    @di.Inject() formatUploadUrl: FormatUploadUrl


    constructor(props) {
        super(props)
        this.checkNickname = _.debounce(this.checkNickname, 600)
    }

    handleAdd = (e) => {
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.categoriesState.loading_add = true

                var postData = {
                    "description": values.description,
                    "index": values.index,
                    "name": values.name,
                    "url": values.url,
                    "attachments": [],
                    "tag_names": values.tags
                }
                if (values.files) {
                    for (var i = 0; i < values.files.length; i++) {
                        try {
                            var imageUrl = await this.uploadState.upload(values.files[i])
                            postData.attachments[i] = {
                                extend_data: {
                                    width: values.files[i].width,
                                    height: values.files[i].height,
                                    type: values.files[i].type,
                                    size: values.files[i].size
                                },
                                type: "Image",
                                url: this.formatUploadUrl.formatImageUrl(imageUrl.filePath) 
                            }
                        } catch (error) {
                            this.categoriesState.loading_add = false
                            return notification.error({
                                message: "upload image fail",
                                description: error.message
                            })
                        }
                    }
                }


                try {
                    await this.categoriesState.create(postData)
                    this.props.form.resetFields();
                    notification.success({
                        message: "add success"
                    })
                    this.categoriesState.loading_add = false
                    this.props["history"].push("/categories")
                } catch (error) {
                    this.categoriesState.loading_add = false
                    return notification.error({
                        message: "add fail",
                        description: error.message
                    })
                }

            }
        })
    }

    checkNickname = async (rule, value, callback) => {
        try {
            var res = await this.categoriesService.checkName(value)
            if (res.status == 200) {
                callback("name already exists")
            }
        } catch (error) {
            callback()
        }
    }


    public render() {

        const { getFieldDecorator } = this.props.form

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18, offset: 2 },
            },
        };

        return (
            <div style={{
                maxWidth: 680,
                marginBottom: 140
            }}>
                <Form {...formItemLayout}>
                    <Form.Item label="name" >
                        {
                            getFieldDecorator("name", {
                                initialValue: "",
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    },
                                    {
                                        validator: this.checkNickname
                                    }
                                ]
                            })(
                                <Input></Input>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="description">
                        {getFieldDecorator('description', {
                            initialValue: ""
                        })(
                            <TextArea autoSize={{ minRows: 6 }}></TextArea>
                        )}
                    </Form.Item>

                    <Form.Item label="url">
                        {
                            getFieldDecorator("url", {
                                initialValue: ""
                            })(
                                <Input></Input>
                            )
                        }
                    </Form.Item>



                    <Form.Item label="tags"  >
                        {
                            getFieldDecorator("tags", {
                                initialValue: []
                            })(
                                <Select
                                    mode="tags"
                                    dropdownStyle={{ display: "none" }}
                                    style={{ width: "100%" }}
                                ></Select>
                            )
                        }
                    </Form.Item>


                    <Form.Item label="iamges"  >
                        {getFieldDecorator('files', {
                        })(
                            <ImageUpload quantity={1}></ImageUpload>
                        )}
                    </Form.Item>

                    <Form.Item label="index" >
                        {
                            getFieldDecorator("index", {
                                initialValue: 0
                            })(
                                <InputNumber min={0}></InputNumber>
                            )
                        }
                    </Form.Item>


                </Form>
                <div style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 68,
                    lineHeight: "68px",
                    marginLeft: 200,
                    zIndex: 100,
                    backgroundColor: "#fff",
                    textAlign: "right",
                    borderTop: "1px solid #d8d8d8"
                }}>
                    <Button
                        type="primary"
                        icon="save"
                        style={{ marginRight: "20%" }}
                        loading={this.categoriesState.loading_add}
                        onClick={this.handleAdd}
                    >add</Button>
                </div>
            </div>
        );
    }
}





const _AddForm = Form.create<RouteComponentProps & FormComponentProps>()(AddForm)


export default withRouter(_AddForm)


import '../normalize';

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import AppRouter from './routes';
import { WebAppContainer } from '../containers/WebAppContainer';
import { ConfigProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';


// ========================================================
// Render Setup
// ========================================================
const MOUNT_NODE = document.getElementById('root');


const isMobile = navigator.userAgent.match(
    /iPhone|iPad|iPod|Android|BlackBerry|Opera Mini|IEMobile/i
) !== null;



let render = () => {
    // if (isMobile) {
    //     ReactDOM.render(
    //         <WebAppContainer>
    //             <LocaleProvider locale={zh_CN}>
    //                 <MobileAppRouter />
    //             </LocaleProvider>
    //         </WebAppContainer>,
    //         MOUNT_NODE
    //     );
    // } else {
    ReactDOM.render(
        <WebAppContainer>

            <ConfigProvider>
                <AppRouter />
            </ConfigProvider>

        </WebAppContainer>,
        MOUNT_NODE
    );
    // }

};

// This code is excluded from production bundle
if (__DEV__) {
    //devTools
    if (module.hot) {
        // Development render functions
        const renderApp = render;
        const renderError = (error) => {
            const RedBox: any = require<{ default: any }>('redbox-react').default;
            ReactDOM.render(<RedBox error={error} />, MOUNT_NODE);
        };

        // Wrap render in try/catch
        render = () => {
            try {
                renderApp();
            } catch (error) {
                renderError(error);
            }
        };
        //Setup hot module replacement
        module.hot.accept('./routes/index', () => {
            requestAnimationFrame(() => {
                ReactDOM.unmountComponentAtNode(MOUNT_NODE);
                render();
            })
        }
        );
    }
}

// ========================================================
// render
// ========================================================

render();

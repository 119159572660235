import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { Card, Tabs } from 'antd';
import BaseInfo from './BaseInfo';
import { di } from 'jsmodules';
import { SoulState } from 'stores/souls/Soul';
import CommentsCon from '../CommentsCon';
import MatesCon from '../MatesCon';
import CallsCon from '../CallsCon';
import MateCollectCon from '../MateCollectCon';
import PaymentsCon from '../PaymentsCon';
import _CommentCon from '../_CommentCon';

const TabPane = Tabs.TabPane


export interface IDetailsProps extends RouteComponentProps<{ id }> {
}


@observer
class Details extends React.Component<IDetailsProps> {
    @di.Inject() soulState: SoulState


    public render() {

        const { record, status } = this.soulState.recordState

        if (status == "loading") {
            return <div></div>
        }

        return (
            <div className="main-content">
                <BaseInfo record={record}></BaseInfo>

                <Tabs>
                    <TabPane tab="My Fav" key="collet">
                        <MateCollectCon soulRecord={record}></MateCollectCon>
                    </TabPane>
                    <TabPane tab="Comments" key="comment">
                        <_CommentCon soulRecord={record}></_CommentCon>
                    </TabPane>
                    <TabPane tab="Calls" key="call">
                        <CallsCon soulRecord={record}></CallsCon>
                    </TabPane>
                    {
                        record.walletState.id &&
                        <TabPane tab="Transaction" key="transaction">
                            <PaymentsCon soulRecord={record}></PaymentsCon>
                        </TabPane>
                    }
                </Tabs>
            </div>
        );
    }
}

export default withRouter(Details)

import React from 'react';
import { observer } from 'mobx-react';
import { di } from 'jsmodules';
import { Button, Modal, Table, InputNumber, notification } from 'antd';
import { CategoriesState } from 'stores/categories/categories';
import { CurrencyCodeIcon } from 'config/common';




@observer
export default class AddItemCell extends React.Component<any, any>{
    @di.Inject() categoriesState: CategoriesState

    state = {
        visible: false,
        selectProduct: "",
        index: 0
    }

    show = () => {
        this.categoriesState.loadQueryProducts()
        this.setState({
            visible: true
        })
    }

    hide = () => {
        this.setState({
            visible: false,
            selectProduct: "",
            index: 0
        })
    }

    handleAdd = async () => {
        const { selectProduct, index } = this.state
        const {categoryProductState} = this.props.record

        var postData = {
            "content": {
                "id": selectProduct,
                "type": "Product"
            },
            "index": index
        }

        try {
            await categoryProductState.create(postData)
            categoryProductState.loadRecords()
            notification.success({
                message: "add product items success!"
            })
            this.hide()
        } catch (error) {
            notification.error({
                message:"add product items fail!",
                description: error.message
            })
        }
    }

    columns = [
        {
            title: "name",
            key: "name",
            dataIndex: "name"
        },
        {
            title: "price",
            key: "price",
            render: record => <div>
                <span>{CurrencyCodeIcon[record.currency_code]}</span>
                <span>{record.price}</span>
            </div>
        },
        {
            title: "total quantity",
            key: "total_quantity",
            dataIndex: "total_quantity"
        },
        {
            title: "stock quantity",
            key: "stock_quantity",
            dataIndex: "stock_quantity"
        },
        {
            title: "used quantity",
            key: "used_quantity",
            dataIndex: "used_quantity"
        },
        {
            title: "sale begin time",
            key: "sale_begin_time",
            dataIndex: "sale_begin_time"
        },
        {
            title: "sale end time",
            key: "sale_end_time",
            dataIndex: "sale_end_time"
        },
        {
            render: record => <Button size="small" type="primary" onClick={() => this.select(record)}>select</Button>
        }

    ]


    select = (record) => {
        this.setState({
            selectProduct: record.id
        })
    }

    back = () => {
        this.setState({
            selectProduct: ""
        })
    }

    handleTableChange = (pagination) => {
        this.categoriesState.queryPagination.current = pagination.current * 1
        this.categoriesState.loadQueryProducts()

    }

    render() {

        const { queryPagination, loading_queryProducts, queryProductRecords } = this.categoriesState

        const { selectProduct, index } = this.state


        return (
            <div>
                <Button type="primary" onClick={this.show}>add new item</Button>
                <Modal
                    visible={this.state.visible}
                    onCancel={this.hide}
                    footer={null}
                    title="select product"
                    width={selectProduct == "" ? "70%" : 400}
                >
                    <div>
                        {
                            selectProduct == "" ?
                                <Table dataSource={queryProductRecords}
                                    columns={this.columns}
                                    loading={loading_queryProducts}
                                    pagination={queryPagination}
                                    onChange={this.handleTableChange}
                                    rowKey={item => { return `t-${item["id"]}` }}
                                ></Table>
                                :
                                <div style={{ textAlign: "center" }}>
                                    <div style={{ margin: "48px 0" }}>
                                        <span style={{ color: "#101934", marginRight: 12 }}>index:</span>
                                        <InputNumber min={0} value={index}></InputNumber>
                                    </div>
                                    <div>
                                        <span style={{ marginRight: 24 }}>
                                            <Button onClick={this.back}>back</Button>
                                        </span>
                                        <span><Button type="primary" onClick={this.handleAdd}>ok</Button></span>
                                    </div>
                                </div>
                        }

                    </div>
                </Modal>
            </div>
        )
    }
}



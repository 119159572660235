import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { di, qs } from 'jsmodules';
import { CategoriesState } from 'stores/categories/categories';
import { observer } from 'mobx-react';
import { CategoriesItemState } from 'stores/categories/categoriesItem';
import CategoryProductList from './CategoryProductList';
import AddItemCell from './AddItemCell';

export interface IItemsProps extends RouteComponentProps<{ id }> {
}


@observer
class Items extends React.Component<IItemsProps> {
    @di.Inject() categoriesState: CategoriesState

    handleSearch(query, removeKeys?) {
        const { id } = this.props.match.params

        var last_query = qs.decode(this.props.location.search) || {}

        var new_query = { ...last_query, ...query }
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key]
            }
        }
        this.props.history.push(`/categories/${id}/items?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }

    paramsChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    public render() {
        const { id } = this.props.match.params

        let record: CategoriesItemState = this.categoriesState._get(id)

        const { records, status, pagination, totalCount } = record.categoryProductState

        return (
            <div className="main-content">
                <div style={{
                    color: "#D462FB",
                    fontSize: 24,
                    marginBottom: 36
                }}>
                    <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Category Product Items</span>
                </div>

                <div style={{ marginBottom: 36 }}>
                    <AddItemCell record={record}></AddItemCell>
                </div>

                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <CategoryProductList
                        record={record}
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.paramsChange}
                    ></CategoryProductList>
                </div>
            </div>
        );
    }
}

export default withRouter(Items)
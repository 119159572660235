import * as React from 'react';
import WebLang from '../i18n/web';
import { AppContainer } from './AppContainer';
import { di } from 'jsmodules';
import { I18nState } from '../stores/i18n';
import { WebKeyValueStorage as KeyValueStorage } from '../storage/web/KeyValueStorage';
import { SessionState } from '../stores/session'
import { UploadService } from 'services/upload'
import { COSUrlConverter } from 'services/urlConvert';
import { MytestState } from 'stores/mytest';
import { SoulService } from 'services/soul';
import { ProductsState } from 'stores/products/products';
import { ProductsService } from 'services/products';
import { OrdersService } from 'services/orders';
import { OrdersState } from 'stores/orders/orders';
import { PaymentService } from 'services/payment';
import { PaymentState } from 'stores/payment/payment';
import { MateService } from 'services/mates';
import { MateState } from 'stores/mates/mate';
import { SoulState } from 'stores/souls/Soul';
import { AccountService } from 'services/account';
import { CommentService } from 'services/comment';
import { CommentState } from 'stores/comments/comment';
import { MemberService } from 'services/member';
import { ReviewService } from 'services/review';
import { TransationService } from 'services/transation';
import { CoinService } from 'services/coins';
import { CoinState } from 'stores/coins/coins';
import { AccountState } from 'stores/accounts/account';
import { EditorService } from 'services/editor';
import { EditorState } from 'stores/editor/editor';
import { CategoriesService } from 'services/categories';
import { CategoriesState } from 'stores/categories/categories';
import { ChatService } from 'services/chat';
import { CallService } from 'services/call';
import { CallState } from 'stores/calls/call';
import { TransactionState } from 'stores/transations/transations';
import { PayoutService } from 'services/payouts';
import { PayoutState } from 'stores/payout/Payout';
import { FeedbackService } from 'services/feedback';
import { FeedbackState } from 'stores/feedback/feedback';
import { WorkOrderState } from 'stores/workorder/workOrders';
import { WorkdOrderService } from 'services/workorder';











const { container } = di;

container.bind("mytestState").to(MytestState).isSingletonScope()

container.bind('kvStorage').to(KeyValueStorage).params('__APP_DATA__');

container.bind('i18n').to(I18nState).params(WebLang).isSingletonScope();

container.bind("uploadService").to(UploadService).isSingletonScope()
container.bind("urlConvert").to(COSUrlConverter).isSingletonScope()
container.bind("session").to(SessionState).isSingletonScope()

container.bind("accountService").to(AccountService).isSingletonScope()
container.bind("accountState").to(AccountState).isSingletonScope()

container.bind("soulService").to(SoulService).isSingletonScope()
container.bind("soulState").to(SoulState).isSingletonScope()

container.bind("mateService").to(MateService).isSingletonScope()
container.bind("mateState").to(MateState).isSingletonScope()

container.bind("productsService").to(ProductsService).isSingletonScope()
container.bind("productsState").to(ProductsState).isSingletonScope()

container.bind("ordersService").to(OrdersService).isSingletonScope()
container.bind("ordersState").to(OrdersState).isSingletonScope()

container.bind("paymentService").to(PaymentService).isSingletonScope()
container.bind("paymentState").to(PaymentState).isSingletonScope()

container.bind("commentService").to(CommentService).isSingletonScope()
container.bind("commentState").to(CommentState).isSingletonScope()

container.bind("memberService").to(MemberService).isSingletonScope()

container.bind("reviewService").to(ReviewService).isSingletonScope()

container.bind("transationService").to(TransationService).isSingletonScope()
container.bind("transactionState").to(TransactionState).isSingletonScope()

container.bind("coinService").to(CoinService).isSingletonScope()
container.bind("coinState").to(CoinState).isSingletonScope()

container.bind("editorService").to(EditorService).isSingletonScope()
container.bind("editorState").to(EditorState).isSingletonScope()

container.bind("categoriesService").to(CategoriesService).isSingletonScope()
container.bind("categoriesState").to(CategoriesState).isSingletonScope()

container.bind("chatService").to(ChatService).isSingletonScope()

container.bind("callService").to(CallService).isSingletonScope()
container.bind("callState").to(CallState).isSingletonScope()

container.bind("payoutService").to(PayoutService).isSingletonScope()
container.bind("payoutState").to(PayoutState).isSingletonScope()

container.bind("feedbackService").to(FeedbackService).isSingletonScope()
container.bind("feedbackState").to(FeedbackState).isSingletonScope()

container.bind("workdOrderService").to(WorkdOrderService).isSingletonScope()
container.bind("workOrderState").to(WorkOrderState).isSingletonScope()


export function WebAppContainer(props) {
    return <AppContainer>
        {props.children}
    </AppContainer>;
}

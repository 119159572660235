import React from 'react';
import { observer } from 'mobx-react';
import CallMissed from '../Souls/components/CallMissed';




@observer
export default class CallMissedCon extends React.Component<any,any>{

    componentWillMount() {
        const {mateRecord} = this.props
        mateRecord.callMissed.filter({ 
            statuses: ["DialHangUp","DialTimeout"].join(","),
            participant_ids: mateRecord.chatuser.id
         }, true)
         mateRecord.callMissed.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab } = this.props
        if(selectTab != nextProps.selectTab){
            if(nextProps.selectTab == "missed"){
                nextProps.mateRecord.callMissed.loadRecords()
            }
        }
    }

    render(){
        const {mateRecord} = this.props
        
        return(
            <CallMissed record={mateRecord}></CallMissed>
        )
    }
}
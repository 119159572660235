import * as React from 'react';
import { Button, DatePicker, Radio } from 'antd';
import moment from 'moment'


const { RangePicker } = DatePicker;


export default class Actions extends React.Component<any, any> {



    orderStatusChange = (e) => {
        this.props.onChange({ status: e.target.value, page: 1 })
    }

    paymentStatusChange = (e) => {
        this.props.onChange({ payment_status: e.target.value, page: 1 })
    }

    timeChange = (time) =>{
        if (time.length != 0) {
            this.props.onChange(
                {
                    begin_timestamp_utc: time[0].startOf("day").unix(),
                    end_timestamp_utc: time[1].endOf("day").unix(),
                    page: 1
                }
            )
        } else {
            this.props.onChange(
                {
                    begin_timestamp_utc: "",
                    end_timestamp_utc: "",
                    page: 1
                }
            )
        }
    }

    

    public render() {

        const { status, payment_status, begin_time, end_time } = this.props

        let defaultStartTime = begin_time ? moment.unix(begin_time) : null
        let defaultEndTime = end_time ? moment.unix(end_time) : null

        return (
            <div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 120, marginRight: 36 }}>Time Range</span>
                    <RangePicker
                        defaultValue={[defaultStartTime, defaultEndTime]}
                        onChange={this.timeChange}
                        format="MM/DD/YYYY"
                    ></RangePicker>
                </div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 120, marginRight: 36 }}>Order Status</span>
                    <Radio.Group defaultValue={status} onChange={this.orderStatusChange}>
                        <Radio value="">all</Radio>
                        {

                            ["Placed", "Processing", "Success", "Closed", "Expired"].map(item => {
                                return <Radio key={item} value={item}>{item}</Radio>
                            })

                        }

                    </Radio.Group>
                </div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 120, marginRight: 36 }}>Payment Status</span>
                    <Radio.Group defaultValue={payment_status} onChange={this.paymentStatusChange}>
                        <Radio value="">all</Radio>
                        {
                            ["None", "Processing", "Authorized", "Completed", "Rejected"].map(item => {
                                return <Radio key={item} value={item}>{item}</Radio>
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
        );
    }
}

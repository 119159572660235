import { observable } from 'mobx';
import { di } from 'jsmodules';
import { Batch_productItem } from 'utils/batch_productItem';
import { CategoriesService } from 'services/categories';





export class CategoryProductItem {
    @di.Inject() batch_productItem: Batch_productItem
    @di.Inject() categoriesService: CategoriesService


    @observable attachments = []
    @observable isPublish = ""
    @observable name = ""
    @observable description = ""
    @observable stock_quantity = 0
    @observable price = 0
    @observable currency_code = ""
    @observable used_quantity = 0
    @observable index = 0



    constructor(public manager, public item) {
        for (const key in item) {
            this[key] = item[key];
        }
        this.index = item.index * 1
        this.loadProductInfo()
    }


    async loadProductInfo() {

        var result = await this.batch_productItem.getData(this.item.content.id)

        this.name = result.name
        this.description = result.description
        this.isPublish = result.has_published
        this.attachments = result.attachments
        this.stock_quantity = result.inventory.stock_quantity
        this.used_quantity = result.inventory.total_quantity - result.inventory.stock_quantity
        this.currency_code = result.sale.prices[0].currency_code
        this.price = result.sale.prices[0].price.toFixed(2)
    }

    async delete() {

        await this.manager.recordService.delete(this.manager.category_id, {
            content: {
                id: this.item.content.id,
                type: "Product"
            }
        })
        this.manager.loadRecords()
    }

    async updateIndex(index) {
        var postData = {
            "content": {
                "id": this.item.content.id,
                "type": "Product"
            },
            "index": index
        }

        try {
            await this.categoriesService.updateProductIndexInCategory(this.manager.category_id,postData)
            this.index = index
        } catch (error) {
            throw error
        }
    }
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { di, qs } from 'jsmodules';
import { EditorState } from 'stores/editor/editor';
import EditorList from './EditorList';
import Actions from './Actions';

export interface IEditorsProps extends RouteComponentProps {
}



@observer
class Editors extends React.Component<IEditorsProps> {
    @di.Inject() editorState: EditorState

    
    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/editors?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    public render() {

        const {records,status,pagination,totalCount} = this.editorState
        var queryParams = qs.decode(this.props.location.search) || {};


        return (
            <div className="main-content">
                <div style={{
                    color: "#D462FB",
                    fontSize: 24,
                    marginBottom: 36
                }}>
                    <span style={{paddingBottom:8, borderBottom: "2px solid #D462FB"}}>Editor</span>
                </div>

                <div style={{ marginBottom: 36 }}>
                    <Actions
                        nickname={queryParams.nickname || ""}
                        onChange={this.queryChange}
                    ></Actions>
                </div>

                <div>
                    <div style={{fontSize:12, marginBottom:12 }}>{totalCount} results</div>
                    <EditorList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></EditorList>
                </div>
            </div>
        );
    }
}

export default withRouter(Editors)
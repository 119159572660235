import React from 'react';
import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import CallHistoryCon from './CallHistoryCon';
import CallMissedCon from './CallMissedCon';




const TabPane = Tabs.TabPane

@observer
export default class CallsCon extends React.Component<any, any>{

    state = {
        key: "history"
    }


    componentWillMount() {
        this.props.mateRecord.loadChatuser()
    }

    keyChange = (key) => {
        this.setState({
            key: key
        })
    }


    render() {
        const { mateRecord } = this.props

        const { key } = this.state

        if (mateRecord.loading_chatuser) {
            return <div></div>
        }

        return (

            <div>
                <Tabs activeKey={key} onChange={this.keyChange}>
                    <TabPane tab="Call History" key="history">
                        <CallHistoryCon mateRecord={mateRecord} selectTab={key}></CallHistoryCon>
                    </TabPane>
                    <TabPane tab="Call Missed" key="missed">
                        <CallMissedCon mateRecord={mateRecord} selectTab={key}></CallMissedCon>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class EditorService extends CRUDService {
    @di.Inject("content_api_v1") http: HttpFactory

    constructor() {
        super("/admin/editors", "content_api_v1")
    }

    checkNickname(nickname){
        return this.http.url(`/editors/nicknames/${encodeURIComponent(nickname)}`).addSecurityHeaders().head()
    }

    editNickname(id,data){
        return this.http.url(`/admin/editors/${id}/nickname`).addSecurityHeaders().put(data)
    }
    
    loadEditors(params){
        return this.http.url("/admin/editors").addSecurityHeaders().get(params)
    }

    me(){
        return this.http.url("/editors/me").addSecurityHeaders().get()
    }
}
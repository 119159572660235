import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import Details from './components/Details';
import { di } from 'jsmodules';
import { MateState } from 'stores/mates/mate';
import { MateItemState } from 'stores/mates/mateItem';

export interface IDetailsConProps extends RouteComponentProps<{ id }> {
}

export default class DetailsCon extends React.Component<IDetailsConProps> {
    @di.Inject() mateState: MateState

    componentWillMount() {
        const { id } = this.props.match.params
        this.mateState.recordState.load(id, item => new MateItemState(item))
        
    }


    public render() {
        return (
            <div className="main-container">
                <Details></Details>
            </div>
        );
    }
}

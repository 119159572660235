import { di } from 'jsmodules';
import { IKeyValueStorage } from '../storage/IKeyValueStorage';
import TokenService from '../services/token';
import UserService from '../services/user';
import { observable } from 'mobx';
import { Events } from 'jsmodules/lib/events';
import { EditorService } from 'services/editor';
import Editor from 'web/routes/Editor';






export class SessionState extends Events {

    @di.Inject() tokenService: TokenService;

    @di.Inject() userService: UserService;

    @di.Inject() kvStorage: IKeyValueStorage;

    @di.Inject() editorService: EditorService




    @observable isAuthenticated = false;

    @observable user;

    @observable login_role = "Admin"


    constructor() {
        super();
    }


    @observable roles = []

    async getCurrentUserInfo() {

        var loginRole = localStorage.getItem("sounmate_login_role")
        if(!loginRole){
            return this.isAuthenticated = false
        }
        
        try {
            if (loginRole == "Admin") {
                var accountRes = await this.userService.me();
                this.user = accountRes.data.result;
                this.roles = accountRes.data.result.roles || []
            }

            if(loginRole == "Editor"){
                var editorRes = await this.editorService.me()
                this.user = editorRes.data.result;
                this.roles = editorRes.data.result.roles || []
            }

            this.isAuthenticated = true;

        }
        catch (ex) {
            this.isAuthenticated = false;
            return ex.message
        }
    }



    async login(username, password) {
        try {
            await this.tokenService.login(username, password);

            if (this.login_role == "Admin") {
                var accountRes = await this.userService.me();
                this.roles = accountRes.data.result.roles
                this.user = accountRes.data.result
            }

            if (this.login_role == "Editor") {
                var editorRes = await this.editorService.me()
                this.roles = editorRes.data.result.roles
                this.user = editorRes.data.result
            }

            this.isAuthenticated = true;

        }
        catch (ex) {
            throw ex
        }
    }


    async logout() {
        await this.tokenService.clear();
        this.roles = []
        this.isAuthenticated = false;
        this.user = null;
        localStorage.clear()
    }



}
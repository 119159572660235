import React from 'react';
import { observer } from 'mobx-react';
import { List, Avatar } from 'antd';
import { Link } from 'react-router-dom';



@observer
export default class FeedbackList extends React.Component<any, any>{
    render() {

        const { status, records, pagination } = this.props

        return (
            <List
                itemLayout="vertical"
                dataSource={records}
                loading={status == "loading"}
                pagination={{
                    ...pagination,
                    onChange: (page) => { this.props.onChange({ page: page }) },
                    onShowSizeChange: (current, size) => { this.props.onChange({ page: current, pagesize: size }) }
                }}
                renderItem={item => <ListItem record={item} />}
            ></List>
        )
    }
}


class ListItem extends React.Component<any, any>{

    render() {
        const { record } = this.props

        return (
            <List.Item>
                <List.Item.Meta title={`ID：${record.id}`} />
                <div>
                    <div>
                        {record.content}
                    </div>
                    <div style={{display:"flex", marginTop:"15px", alignItems:"center"}}>
                        <div style={{marginRight:"10px"}}>
                            <Avatar src={record.avatar}></Avatar>
                        </div>
                        <div>
                            <Link to={`/souls/${record.customer.id}`}>
                                <span>{record.customer.nickname}</span>
                            </Link>
                            
                            <span style={{marginLeft:"30px"}}>
                            {record.creationTime}
                            </span>
                        </div>
                    </div>
                </div>
            </List.Item>
        )
    }
}
import React from 'react';
import VocieInMateDetailCell from 'web/components/VocieInMateDetailCell';
import { Upload, Button, Icon, message, Spin } from 'antd';
import { di } from 'jsmodules';
import { COSUrlConverter } from 'services/urlConvert';
import { UploadState } from 'stores/upload';
import { observer } from 'mobx-react';
import { nextId } from 'utils/id';
import Recorder from 'react-mp3-recorder'





@observer
export default class AudioUploadInForm extends React.Component<any, any>{
    @di.Inject() urlConvert: COSUrlConverter
    @di.Inject() uploadState: UploadState


    beforeUpload = (file) => {
        return false
    }

    handleChange = async info => {
        let fileList = [...info.fileList];

        if (fileList.length != 0) {
            if (fileList[0].size / 1024 / 1024 > 10) {
                message.error("File cannot be greater than 10m!")
                return
            }
        }

        var res = await this.uploadState.uploadFile(fileList[0])
        res.target_url = this.urlConvert.url(res.filePath).videoCdn().target_url

        this.triggerChange({
            extend_data: {},
            target_url: res.target_url,
            type: "Audio",
            url: res.filePath,
        })
    };


    _onRecordingComplete = (blob) => {
        var postData = {
            type: "Audio",
            url: "",
            target_url:"",
            extend_data: {
                size: blob.size,
                duration: 0
            }
        }

        var url = URL.createObjectURL(blob)

        var audio = new Audio();
        audio.onloadeddata = async (e) => {

            postData.extend_data.duration = audio.duration

            var temp = {
                name: nextId().toString(),
                size: blob.size,
                type: blob.type,
                originFileObj: blob
            }
            
            var audiourl = await this.uploadState.uploadFile(temp)

            postData.url = audiourl.filePath
            postData.target_url = this.urlConvert.url(audiourl.filePath).videoCdn().target_url
            
            this.triggerChange(postData)
        }

        audio.onerror = () => {

        }

        audio.src = url;

    }

    _onRecordingError = (err) => {
        console.log('recording error', err)
    }



    triggerChange = changedValue => {
        const { onChange } = this.props;
        if (onChange) {
            onChange({
                ...changedValue,
            });
        }
    }



    render() {

        const { value } = this.props;

        const props = {
            action: 'https://www.mocky.io/v2/5cc8019d300000980a055e76',
            onChange: this.handleChange,
            multiple: false,
            fileList: null,
            accept: "audio/mp3,audio/aac",
        };


        return (
            <Spin spinning={this.uploadState.loading_upload}>
                <div style={{
                    display: "flex",
                    alignItems: "center"
                }}>

                    <div style={{ marginRight: 48 }}>
                        <VocieInMateDetailCell content={value} ></VocieInMateDetailCell>
                    </div>

                    <div>
                        <Recorder
                            onRecordingComplete={this._onRecordingComplete}
                            onRecordingError={this._onRecordingError}
                        />
                    </div>

                    <div style={{ margin: "0 24px" }}>or</div>

                    <div>
                        <Upload
                            {...props}
                            beforeUpload={this.beforeUpload}
                        >
                            <Button type="primary">
                                upload
                        </Button>
                        </Upload>
                    </div>

                </div>
            </Spin>
        )
    }
}
import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class SoulService extends CRUDService {
    @di.Inject("content_api_v1") http: HttpFactory

    constructor() {
        super('/admin/souls', "content_api_v1")
    }

    edit_nickname(id,data){
        return this.http.url(`/admin/souls/${id}/nickname`).addSecurityHeaders().put(data)
    }

    edit_profile(id,data){
        return this.http.url(`/admin/souls/${id}/profile`).addSecurityHeaders().put(data)
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { PayoutState } from 'stores/payout/Payout';
import Payouts from './components/Payouts';

export interface IPayoutsConProps extends RouteComponentProps {
}

export default class PayoutsCon extends React.Component<IPayoutsConProps> {

    @di.Inject() payoutState: PayoutState


    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        var { page, pagesize, ...filter } = queryParams;
        if (filter) {
            this.payoutState.filter(filter, true)
        }
        page = page * 1 || 1;

        if (pagesize) {
            this.payoutState.page(page, pagesize * 1)
        } else {
            this.payoutState.page(page)
        }
        this.payoutState.loadRecords();
    }


    public render() {
        return (
            <div className="main-container">
                <Payouts></Payouts>
            </div>
        );
    }
}

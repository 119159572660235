import React from 'react';
import { Tabs } from 'antd';
import { observer } from 'mobx-react';
import DepositCon from '../Mates/DepositCon';
import WithdrawCon from '../Mates/WithdrawCon';
import TransfersCon from '../Mates/TransfersCon';



const TabPane = Tabs.TabPane


@observer
export default class PaymentsCon extends React.Component<any, any>{

    state = {
        key: "deposit"
    }

    componentWillMount() {
        const { soulRecord } = this.props
        soulRecord.walletState.walletTransationState.filter({
            account_identity: soulRecord.walletState.id,
            type: "DepositTransaction"
        }, true)
        soulRecord.walletState.walletTransationState.loadRecords()
    }

    keyChange = (key) => {
        const { soulRecord } = this.props

        this.setState({
            key: key
        }, () => {
            if (key == "deposit") {
                soulRecord.walletState.walletTransationState.filter({
                    account_identity: soulRecord.walletState.id,
                    type: "DepositTransaction"
                }, true)
                soulRecord.walletState.walletTransationState.loadRecords()
            }
            if (key == "withdraw") {
                soulRecord.walletState.walletTransationState.filter({
                    account_identity: soulRecord.walletState.id,
                    type: "WithdrawTransaction"
                }, true)
                soulRecord.walletState.walletTransationState.loadRecords()
            }
            if (key == "transfers") {
                soulRecord.walletState.walletTransationState.filter({
                    account_identity: soulRecord.walletState.id,
                    type: "TransferTransaction"
                }, true)
                soulRecord.walletState.walletTransationState.loadRecords()
            }
        })


    }

    render() {

        const { soulRecord } = this.props

        return (
            <div>
                <Tabs activeKey={this.state.key} onChange={this.keyChange}>
                    <TabPane tab="deposit" key="deposit">
                        <DepositCon record={soulRecord}></DepositCon>
                    </TabPane>
                    <TabPane tab="withdraw" key="withdraw">
                        <WithdrawCon record={soulRecord}></WithdrawCon>
                    </TabPane>
                    <TabPane tab="transfer" key="transfers">
                        <TransfersCon record={soulRecord}></TransfersCon>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

import React from 'react';
import { Table } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AmountCell } from 'web/components/AmountCell/AmountCell';
import PaymethodCell from 'web/components/PaymethodCell';



@observer
export default class PaymentList extends React.Component<any, any>{

    columns: any = [
        {
            title: "amount",
            key: "Amount",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: record => <AmountCell currencyCode={record.currencyCode} amount={record.amount} ></AmountCell>
        },
        {
            title: "merchant",
            render: record => <div>
                {
                    record.merchantId == "1" ?
                        <div>sounmate</div>
                        :
                        <div><Link to={`/souls/${record.merchantId}`}>{record.merchantNickname}</Link></div>
                }
            </div>
        },
        {
            title: "customer",
            key: "customer_name",
            render: record => <CustomerNickname record={record}></CustomerNickname>
        },
        {
            title: "pay method",
            render: record => <div>
                {
                    record.trades.length != 0 ?
                        <PaymethodCell trade={record.trades[0]}></PaymethodCell>
                        :
                        <div></div>
                }
            </div>
        },
        {
            title: "status",
            key: "status",
            dataIndex: "status"
        },
        {
            title: "description",
            key: "description",
            dataIndex: "description"
        },
        {
            title: "creation time",
            key: "creationTime",
            dataIndex: "creationTime"
        },
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {
        const columns = this.columns;
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}

const CustomerNickname = observer((props) => {
    const { record } = props
    return <div><Link to={`/souls/${record.customer.id}`}>{record.customer_name}</Link></div>
})
import { CurrencyCodeIcon, BaseMomentFormat, CallTimeFormat } from 'config/common';
import moment from 'moment'
import { di } from 'jsmodules';
import { Batch_soul_userid } from 'utils/Batch_soul_userid';
import { observable } from 'mobx';
import { CallService } from 'services/call';

export class CallItemState {
    @di.Inject() batch_soul_userid: Batch_soul_userid
    @di.Inject() callService: CallService

    id

    @observable from_nickname = ""
    @observable to_nickname = ""
    rule = ""
    startTime = ""
    @observable endTime = ""
    creationTime = ""
    dial_duration = 0

    currencyCode = ""
    amount = 0

    callTime

    @observable status

    constructor(public item) {
        this.id = item.id
        for (const key in item) {
            this[key] = item[key]
        }

        this.rule = this.formatRule(item.billing_rule)
        this.startTime = item.start_time ? moment(item.start_time).format(CallTimeFormat) : "/"
        this.endTime = item.end_time ? moment(item.end_time).format(CallTimeFormat) : "/"
        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
        this.callTime = item.billing ? item.billing.total_time_seconds + " s" : "0 s"
        this.currencyCode = item.billing ? item.billing.currency_code : ""
        this.amount = item.billing ? item.billing.amount * 1 : 0

        this.formatDialDuration(item)

        this.loadChatuserInfo()
        this.loadToChatuserInfo()
    }

    formatDialDuration(item){
        if(item.start_time){
            let m1 = moment(item.start_time)
            let m2 = moment(item.creation_time)
            this.dial_duration = m1.diff(m2,"second")
        }else{
            this.dial_duration = 0
        }
    }


    formatTime(stat) {
        if (!stat) {
            return "0:0:0"
        } else {
            var a = stat.total_time_seconds * 1
            var hours = Math.floor(a / 3600)
            var mins = Math.floor((a - hours * 3600) / 60)
            var seconds = a - hours * 3600 - mins * 60
            return hours + ":" + mins + ":" + seconds
        }
    }

    formatRule(rule) {

        var code = CurrencyCodeIcon[rule.currency_code]
        var price = rule.unit_price * 1
        var unit_time = (rule.unit_time_seconds * 1) / 60

        return `${price}${CurrencyCodeIcon[rule.currency_code]}/${unit_time}mins`
    }

    async loadChatuserInfo() {
        var result = await this.batch_soul_userid.getData(this.item.customer.user_id)
        this.from_nickname = result.nickname
    }

    async loadToChatuserInfo() {
        var result = await this.batch_soul_userid.getData(this.item.merchant.user_id)
        this.to_nickname = result.nickname
    }


    @observable loading_endCalling = false
    async endCalling(id) {
        try {
            await this.callService.endCalling(id)
            var res = await this.callService.loadCallItem(id)
            this.endTime = res.data.result.end_time ? moment(res.data.result.end_time).format(CallTimeFormat) : "/"
            this.status = "End"
        } catch (error) {
            throw error
        }
    }

}
import { CRUDState } from 'stores/_CRUDState';
import { CallItemState } from './callItem';


export class CallState extends CRUDState {


    constructor() {
        super("callService", item => new CallItemState(item))
    }

   
}
import React from 'react';
import Wallets from './components/Wallets';



export default class WalletsCon extends React.Component<any, any>{

    componentWillMount() {
        const { soulRecord } = this.props
        soulRecord.walletState.loadWallets()
    }

    render() {
        const { soulRecord } = this.props
        return (
            <Wallets soulRecord={soulRecord}></Wallets>
        )
    }
}
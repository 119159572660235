import React from 'react';
import { Select, Input } from 'antd';



const Option = Select.Option


export default class AddPhoneCell extends React.Component<any, any>{


    handleCurrencyChange = (code) => {
        this.triggerChange({
            country_code: code
        })
    }

    handleNumberChange = (e) => {

        this.triggerChange({
            area_number: e.target.value
        })
    }

    triggerChange = changedValue => {
        const { onChange, value } = this.props;
        if (onChange) {
            onChange({
                ...value,
                ...changedValue,
            });
        }
    }


    render() {

        const { area_number, country_code } = this.props.value;


        const selectBefore = (
            <Select
                value={country_code}
                style={{ width: 80 }}
                onChange={this.handleCurrencyChange}
            >
                <Option value="1">+1</Option>
                <Option value="86">+86</Option>
                <Option value="852">+852</Option>
                <Option value="65">+65</Option>
            </Select>
        )

        return (
            <div>
                <Input
                    addonBefore={selectBefore}
                    value={area_number}
                    type="number"
                    onChange={this.handleNumberChange}
                />
            </div>
        )
    }
}
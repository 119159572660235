import * as _ from 'lodash'
import moment from "moment"





export function dataUrlToFile(dataurl, file) {
    let name = file.name;
    let uid = file.uid;
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    var res_file = new File([u8arr], name, { type: mime });
    res_file["uid"] = file.uid
    return res_file
}

export function isJSON(str) {
    if (typeof str == 'string') {
        try {
            var obj = JSON.parse(str);
            if (typeof obj == 'object' && obj) {
                return true;
            } else {
                return false;
            }

        } catch (e) {
            return false;
        }
    }
}



export function caseDataSupplement(data, start, end) {
    var dataObjs = {}
    for (const item of data) {
        var temp = moment(item.creation_date).unix()
        dataObjs[temp] = item.count
    }

    var result = []
    var i = start
    while (i <= end) {
        result.push(
            {
                count: dataObjs[i] || 0,
                creation_date: moment.unix(i).utc().format()
            }
        )
        i = i + 86400
    }

    return result
}


export function previewDataSupplement(data, start, end) {
    var dataObjs = {}
    for (const item of data) {
        var temp = moment(item.creation_date).unix()
        dataObjs[temp] = item
    }

    var result = []
    var i = start
    while (i <= end) {
        result.push(
            {
                creation_date: moment.unix(i).utc().format(),
                illegal_count: dataObjs[i] ? dataObjs[i].illegal_count : 0,
                normal_count: dataObjs[i] ? dataObjs[i].normal_count : 0,
                total_count: dataObjs[i] ? dataObjs[i].total_count : 0
            }
        )
        i = i + 86400
    }

    return result
}


export function randomColor() {
    // var r = Math.floor(Math.random() * 256);
    // var g = Math.floor(Math.random() * 256);
    // var b = Math.floor(Math.random() * 256);
    // return "rgb(" + r + ',' + g + ',' + b + ")"
    var colorArr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']

    var color = "#";
    for (var i = 0; i < 6; i++) {
        color += colorArr[Math.floor(Math.random() * 16)];
    }
    return color;
}


export function validWeChat(text) {
    var strRegex = /^[a-zA-Z][-_a-zA-Z0-9]{5,19}$/;
    var reg = new RegExp(strRegex);
    if (!reg.test(text)) {
        return false;
    }
    else {
        return true;
    }
}

export function validPhone(text) {
    var strRegex = /^\d{11}$/;
    var reg = new RegExp(strRegex);
    if (!reg.test(text)) {
        return false;
    }
    else {
        return true;
    }
}

export function validQQ(text) {
    var strRegex = /^[1-9]\d{4,10}$/;
    var reg = new RegExp(strRegex);
    if (!reg.test(text)) {
        return false;
    }
    else {
        return true;
    }
}

export function toUpperCase_firstWord(str) {  
    return str.slice(0,1).toUpperCase() + str.slice(1);  
} 

export function toLowerCase_firstWord(str) {
    return str.slice(0,1).toLowerCase() + str.slice(1); 
}

export function formatSignalrMessage(message){
    message = _.mapKeys(message,(value,key)=>{
        return toLowerCase_firstWord(key)
    })
}
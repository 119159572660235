import axios from "axios";
import { di, HttpFactory } from 'jsmodules';


class AssetsUrlConverter {

    thumbnail(url, minwidth, minheight) {
        return `${url}?imageMogr2/thumbnail/!${minwidth}x${minheight}r`;
    }

    avatar(url, cut?: { width, height, x, y }) {
        var cut_str = "";
        if (cut) {
            var { width, height, x, y } = cut;
            cut_str = `/cut/${width}x${height}-${x}x${y}`;
        }
        return `${url}?imageMogr2${cut_str}/thumbnail/132x132`
    }

}




export class UploadService {
    @di.Inject("content_api_v1") content_api_v1: HttpFactory;

    urlConvert = new AssetsUrlConverter();

    async preUpload(options: { bucket_name?: string, region?: string, original_name: string, size: number, content_type: string, http_method: 'put' | 'post' }) {
        var res = await this.content_api_v1.url(`/assets`).addSecurityHeaders().post(options);
        return res.data.result;
    }

    afterUpload(asset_id) {
        return this.content_api_v1.url(`/assets/${asset_id}`).addSecurityHeaders().put();
    }


    /**
     * post 上传文件,react-native 用
     * @param filePath
     * @param onProgress
     */
    async post(file: { name: string, size: number, type: string, uri: string }, onProgress?): Promise<any> {
        var { asset_id, url, relative_url, signature: Authorization } = await this.preUpload({
            // bucket_name: "sounmate-image-yto",
            // region: "na-toronto",
            original_name: file.name,
            size: file.size,
            content_type: file.type,
            http_method: "post"
        });
        var formData = new FormData();
        formData.append('key', relative_url);
        formData.append('success_action_status', "200");
        formData.append('Signature', Authorization);
        var fileObj: any = {
            uri: file.uri,
            name: `file`,
            type: file.type
        }
        formData.append('file', fileObj);
        await axios.post(url, formData, {
            headers: {
                Authorization,
                "Content-Type": file.type
            },
            onUploadProgress(res) {
                if (res.lengthComputable) {
                    onProgress && onProgress({
                        loaded: res.loaded,
                        total: res.total,
                        progress: (res.loaded / res.total)
                    })
                }
            }
        });
        await this.afterUpload(asset_id)
        return { url, filePath: relative_url }
    }

    /**
     * put方式上传文件,(web用)
     * @param file 文件对象
     * @param onProgress
     */
    async put(file: { name: string, size: number, type: string, body: File }, onProgress?): Promise<any> {
        var { asset_id, url, relative_url: key, signature: Authorization } = await this.preUpload({
            // bucket_name: "sounmate-image-yto",
            // region: "na-toronto",
            original_name: file.name,
            size: file.size,
            http_method: "put",
            content_type: file.type
        });
        await axios.put(url, file.body, {
            headers: {
                Authorization,
                "Content-Type": file.type
            },
            onUploadProgress(res) {
                if (res.lengthComputable) {
                    onProgress && onProgress({
                        loaded: res.loaded,
                        total: res.total,
                        progress: (res.loaded / res.total)
                    })
                }
            }
        });
        await this.afterUpload(asset_id)
        return { url, filePath: key }
    }
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { di, qs } from 'jsmodules';
import { PayoutState } from 'stores/payout/Payout';
import PayoutList from './PayoutList';
import { Button } from 'antd';

export interface IPayoutsProps extends RouteComponentProps {
}


@observer
class Payouts extends React.Component<IPayoutsProps> {
    @di.Inject() payoutState: PayoutState

    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/payouts?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    refresh = () => {
        this.payoutState.loadRecords()
    }

    public render() {
        const { records, status, pagination, totalCount } = this.payoutState
        const queryParams = qs.decode(this.props.location.search) || {}

        return (
            <div className="main-content">

                <div style={{ display: "flex", alignItems: "baseline", marginBottom: 36 }}>
                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        flex: 1
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Poyouts</span>
                    </div>

                    <div>
                        <Button onClick={this.refresh} type="primary" icon="sync">refresh</Button>
                    </div>
                </div>


                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <PayoutList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></PayoutList>
                </div>
            </div>
        );
    }
}

export default withRouter(Payouts)
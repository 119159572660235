import React from 'react';
import { Modal, Form, notification, Input, Spin, Button, Divider } from 'antd';
import { observer } from 'mobx-react';
import { di } from 'jsmodules';
import { SessionState } from 'stores/session';
import { FormComponentProps } from 'antd/lib/form';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { UploadState } from 'stores/upload';
import { FormatUploadUrl } from 'stores/formatUploadUrl';

const TextArea = Input.TextArea




export default class ReviewOnceCell extends React.Component<any, any>{
    public render() {

        const { record, type } = this.props

        if(record.status == "Rejected" && !record.review_result){
            return null
        }

        return (
            <div>
                <Spin spinning={record.loading_review} >
                    {
                        !record.reviewResult ?
                            <div>
                                <span>
                                    <ReviewPass record={record} type={type}></ReviewPass>
                                </span>
                                <Divider type="vertical"></Divider>
                                <span>
                                    <ReviewUnpass record={record} type={type}></ReviewUnpass>
                                </span>

                            </div>
                            :
                            <div>reviewed</div>
                    }
                </Spin>
            </div>
        );
    }
}


@observer
class ReviewPass extends React.Component<any, any>{
    @di.Inject() session: SessionState


    handlePass = async () => {
        const { record, type } = this.props
        const { user } = this.session

        record.loading_review = true

        const params = {
            "result": "Normal",
            "reviewer": {
                "avatar": user.avatar,
                "id": user.id,
                "nickname": user.nickname,
                "type": this.session.user.roles[0],
                "user_id": user.nickname == "admin" ? "1" : user.user_id,
            },
            "to": {
                "id": record.id,
                "type": type
            },
        }


        try {
            await record.handleReview(params)
            notification.success({
                message: "success!"
            })
            record.loading_review = false
        } catch (error) {
            notification.error({
                message: "fail!",
                description: error.message
            })
            record.loading_review = false
        }

    }

    render() {
        return (
            <Button size="small" type="primary" onClick={this.handlePass}>pass</Button>
        )
    }
}







interface IUnpassForm extends FormComponentProps {
    onSubmit: any
    onCancel: () => void
    visible: any
}

class _UnpassForm extends React.Component<IUnpassForm, any>{
    render() {

        const { getFieldDecorator } = this.props.form

        return <div>
            <Modal
                title="review"
                visible={this.props.visible}
                onCancel={this.props.onCancel}
                onOk={this.props.onSubmit}
            >
                <Form layout="vertical">
                    <Form.Item label="problem description">
                        {getFieldDecorator('content', {
                            rules: [{ required: true, message: 'can not be null!' }]
                        })(
                            <TextArea autoSize></TextArea>
                        )}
                    </Form.Item>

                    <Form.Item>
                        {getFieldDecorator('images', {
                            initialValue: []
                        })(
                            <ImageUpload quantity={9}></ImageUpload>
                        )}
                    </Form.Item>

                </Form>
            </Modal>
        </div>
    }
}

const UnpassForm = Form.create<IUnpassForm>({ name: 'form_in_modal' })(_UnpassForm)





@observer
class ReviewUnpass extends React.Component<any, any>{
    @di.Inject() session: SessionState
    @di.Inject() uploadState: UploadState
    @di.Inject() formatUploadUrl: FormatUploadUrl

    state = {
        visible: false
    }

    show = () => {
        this.setState({
            visible: true
        })
    }

    hide = () => {
        this.setState({
            visible: false
        })
    }

    handelUnpass = (data) => {
        const { form } = this.formRef.props;
        const { record, type } = this.props
        const { user } = this.session


        if (record.loading_review) {
            return
        }

        form.validateFields(async (err, values) => {


            if (!err) {

                record.loading_review = true


                var params = {
                    "content": values.content,
                    "result": "Illegal",
                    "reviewer": {
                        "avatar": user.avatar,
                        "id": user.id,
                        "nickname": user.nickname,
                        "type": this.session.user.roles[0],
                        "user_id": user.nickname == "admin" ? "1" : user.user_id
                    },
                    "to": {
                        "id": record.id,
                        "type": type
                    },
                    "attachments": [
                    ]
                }

                if (values.images.length != 0) {
                    for (var i = 0; i < values.images.length; i++) {
                        try {
                            var imageurl = await this.uploadState.upload(values.images[i])
                            if (values.images[i].type.indexOf("image") != -1) {
                                params.attachments[i] = {
                                    extend_data: {
                                        width: values.images[i].width,
                                        height: values.images[i].height,
                                        type: values.images[i].type,
                                        size: values.images[i].size
                                    },
                                    type: "Image",
                                    url: this.formatUploadUrl.formatImageUrl(imageurl.filePath)
                                }
                            }
                        } catch (error) {
                            notification.error({
                                message: "upload image fail",
                                description: error.message
                            })
                            record.loading_review = false

                        }

                    }
                }



                try {
                    await record.handleReview(params)
                    form.resetFields();
                    notification.success({
                        message: "success!"
                    })
                    record.loading_review = false
                } catch (error) {
                    notification.error({
                        message: "fail!",
                        description: error.message
                    })
                    record.loading_review = false
                }

            }
        });
    }

    formRef
    saveFormRef = formRef => {
        this.formRef = formRef;
    };



    render() {
        return (
            <span>
                <Button size="small" type="danger" onClick={this.show}>reject</Button>
                <UnpassForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.hide}
                    onSubmit={this.handelUnpass}
                ></UnpassForm>
            </span>
        )
    }
}




import React from 'react';
import Comments from './components/Comments';
import { Tabs } from 'antd';


const TabPane = Tabs.TabPane


export default class CommentsCon extends React.Component<any, any>{

    componentWillMount() {
        const { soulRecord } = this.props
        soulRecord.soulCommentState.filter({ author_id: soulRecord.id, to_content_type: "Mate" }, true)
        soulRecord.soulCommentState.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab, soulRecord } = this.props
        if (selectTab != nextProps.selectTab) {
            if (nextProps.selectTab == "comments") {
                nextProps.soulRecord.soulCommentState.filter({ author_id: soulRecord.id, to_content_type: "Mate" }, true)
                nextProps.soulRecord.soulCommentState.loadRecords()
            }
        }
    }


    render() {
        const { soulRecord } = this.props

        return (
            <Comments soulRecord={soulRecord}></Comments>
        )
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { Avatar, Dropdown, Icon, Menu } from 'antd';
import { di } from 'jsmodules';
import { SessionState } from 'stores/session';


@observer
export default class LayoutHeader extends React.Component<any, any>{
    @di.Inject() session: SessionState


    logout = () => {
        this.session.logout()
    }

    render() {

        const menu = (
            <div style={{
                //minWidth: 200,
                //padding: 16,
                boxShadow: "0 0 20px #d1d1d1",
                background: "#fff"
            }}>


                <Menu style={{
                    border: "none",
                }}
                    selectedKeys={[]}
                >
                    <Menu.Item>
                        <a onClick={this.logout}><Icon type="logout" /><span>Logout</span></a>
                    </Menu.Item>
                </Menu>
            </div>

        );

        return (
            <div style={{
                padding: "0 36px",
                display: "flex",
                alignItems: "center"
            }}>
                <div style={{ flex: 1 }}></div>
                <div>
                    <Dropdown
                        overlay={menu}
                    >

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div style={{ marginRight: 16 }}>

                                <Avatar style={{ backgroundColor: '#87d068' }} icon="user" />


                            </div>
                            <div className="color85">{this.session.user.nickname}</div>
                            <div style={{ marginLeft: 4 }}><Icon type="caret-down" /></div>

                        </div>

                    </Dropdown>
                </div>
            </div>
        )
    }
}
import { observable } from 'mobx';
import moment from 'moment'
import { di } from 'jsmodules';
import { Batch_soul_userid } from 'utils/Batch_soul_userid';
import { BaseMomentFormat } from 'config/common';



export class PaymentItemState {
    @di.Inject() batch_soul_userid: Batch_soul_userid

    creationTime = ""
    @observable customer_name = ""
    amount
    currencyCode
    description = ""
    status = ""

    merchantId = ""
    merchantNickname = ""
    
    constructor(public item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
        this.currencyCode = item.currency_code
        this.amount = item.amount.toFixed(2)
        this.description = item.description
        this.status = item.status

        if(item.merchant.id == "1"){
            this.merchantId = "1",
            this.merchantNickname = "sounmate"
        }else{
            this.merchantId = item.merchant.id
            this.merchantNickname = item.merchant.extend_data.Nickname
        }

        this.loadCustomerInfo()
    }

    async loadCustomerInfo(){
        var result = await this.batch_soul_userid.getData(this.item.customer.user_id)
        this.customer_name = result.nickname
    }
}
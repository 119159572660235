import React from 'react';
import Comments from './components/Comments';




export default class EvaluationCon extends React.Component<any, any>{

    componentWillMount() {
        const { soulRecord } = this.props
        soulRecord.soulCommentState.filter({ to_content_id: soulRecord.id, to_content_type: "Soul" }, true)
        soulRecord.soulCommentState.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab } = this.props
        if(selectTab != nextProps.selectTab){
            if(nextProps.selectTab == "evaluation"){
                nextProps.soulRecord.soulCommentState.filter({ to_content_id: nextProps.soulRecord.id, to_content_type: "Soul" }, true)
                nextProps.soulRecord.soulCommentState.loadRecords()
            }
        }
    }

    render() {
        const { soulRecord } = this.props
        return (
            <div>
                <Comments soulRecord={soulRecord}></Comments>
            </div>
        )
    }
}
import moment from 'moment'
import { BirthdayFormat } from 'config/common';

export class EditorItemState {

    birthday
    creationTime

    constructor(item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.birthday = moment(item.birthday).format(BirthdayFormat)
        this.creationTime = moment(item.creation_time).format(BirthdayFormat)
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { Table, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { AmountCell } from 'web/components/AmountCell/AmountCell';



@observer
export default class TransationList extends React.Component<any, any>{

    columns: any = [
        {
            title: "type",
            key: "type",
            render: record => <div>
                {
                    record.type == "DepositTransaction" && <div>Deposit</div>
                }
                {
                    record.type == "WithdrawTransaction" && <div>Withdraw</div>
                }
                {
                    record.type == "TransferTransaction" && <div>Transfer</div>
                }
            </div>
        },

        {
            title: "amount",
            key: "Amount",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: record => <AmountCell currencyCode={record.currency_code} amount={record.amount} ></AmountCell>
        },

        {
            title: "net amount",
            render: record => <AmountCell currencyCode={record.netAmountCode} amount={record.netAmount} ></AmountCell>
        },

        {
            title: "status",
            dataIndex: "status"
        },

        {
            title: "transfer in",
            render: record => <Transfer_in record={record}></Transfer_in>
        },

        {
            title: "transfer out",
            render: record => <Transfer_out record={record}></Transfer_out>
        },

        {
            title: "creation time",
            dataIndex: "creationTime"
        },
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {

        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}


const Transfer_in = observer(({ record }) => {
    return <div>
        {
            record.transfer_in_soul_id ?
                <Link to={`/souls/${record.transfer_in_soul_id}`}>{record.transfer_in_name}</Link>
                :
                <div>{record.transfer_in_name}</div>
        }
    </div>
})


const Transfer_out = observer(({ record }) => {
    return <div>
        {
            record.transfer_out_soul_id ?
                <Link to={`/souls/${record.transfer_out_soul_id}`}>{record.transfer_out_name}</Link>
                :
                <div>{record.transfer_out_name}</div>
        }
    </div>
})
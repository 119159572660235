import { di } from 'jsmodules';
import { ProductsService } from 'services/products';
import { observable } from 'mobx';
import moment from 'moment'
import { BirthdayFormat } from 'config/common';
import { ProductsState } from './products';



export class ProductItemState {
    @di.Inject() productsService: ProductsService
    @di.Inject() productsState: ProductsState

    @observable hasPublished = false

    id
    @observable sale_begin_time = ""
    @observable sale_end_time = ""
    @observable currency_code = ""
    @observable price = 0
    name = ""
    total_quantity
    stock_quantity
    used_quantity
    estimated_quantity = 0
    @observable description = ""

    constructor(public item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.hasPublished = item.has_published
        this.sale_begin_time = moment(item.sale.sale_begin_time).format(BirthdayFormat) 
        this.sale_end_time = moment(item.sale.sale_end_time).format(BirthdayFormat)
        this.currency_code = item.sale.prices[0].currency_code
        this.price = item.sale.prices[0].price.toFixed(2)
        this.stock_quantity = item.inventory.stock_quantity
        this.used_quantity = item.inventory.total_quantity - item.inventory.stock_quantity
        this.total_quantity = item.inventory.total_quantity
        this.estimated_quantity = this.used_quantity + item.inventory.reserved_quantity
        this.description = item.description
    }

    async publish() {
        try {
            await this.productsService.publish(this.item.id)
            this.hasPublished = true
        } catch (error) {
            throw error;
        }
    }

    async unPublish() {
        try {
            await this.productsService.unpublish(this.item.id)
            this.hasPublished = false
        } catch (error) {
            throw error
        }
    }


    async updateQuantity(data){
        try {
            await this.productsService.updateQuantity(this.item.id,data)
            this.productsState.loadRecords()
        } catch (error) {
            throw error
        }
    }

    async updateBaseInfo(data){
        try {
            await this.productsService.updateProductInfo(this.item.id,data)
            this.productsState.loadRecords()
        } catch (error) {
            throw error
        }
    }

}
import React from 'react';
import { Tabs } from 'antd';
import CommentsCon from './CommentsCon';
import EvaluationCon from './EvaluationCon';


const TabPane = Tabs.TabPane

export default class _CommentCon extends React.Component<any, any>{

    state = {
        key: "comments"
    }

    keyChange = (key) => {
        this.setState({
            key: key
        })
    }

    render() {

        const { key } = this.state

        return (
            <div>
                <Tabs activeKey={key} onChange={this.keyChange}>
                    <TabPane tab="Comments" key="comments">
                        <CommentsCon soulRecord={this.props.soulRecord}  selectTab={key}></CommentsCon>
                    </TabPane>
                    <TabPane tab="Evaluation" key="evaluation">
                        <EvaluationCon soulRecord={this.props.soulRecord}  selectTab={key}></EvaluationCon>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
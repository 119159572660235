import React from 'react';
import { Statistic } from 'antd';
import { CurrencyCodeIcon } from 'config/common';
import { observer } from 'mobx-react';



@observer
export class AmountCell extends React.Component<any, any>{
    render() {

        const { currencyCode, amount } = this.props

        return (
            <div>
                {
                    currencyCode == "NTS" &&
                    <div>
                        <Statistic
                            suffix={CurrencyCodeIcon["NTS"]}
                            value={amount}
                            valueStyle={{
                                fontSize: 14,
                                color: "rgba(0,0,0,.65)"
                            }}
                        ></Statistic>
                    </div>
                }

                {
                    currencyCode == "USD" &&
                    <div>
                        <Statistic
                            prefix={CurrencyCodeIcon["USD"]}
                            value={amount}
                            precision={2}
                            valueStyle={{
                                fontSize: 16,
                                color: "rgba(0,0,0,.65)"
                            }}
                        ></Statistic>
                    </div>
                }

                {
                    currencyCode == "CNY" &&
                    <div>
                        <Statistic
                            prefix={CurrencyCodeIcon["CNY"]}
                            value={amount}
                            precision={2}
                            valueStyle={{
                                fontSize: 16,
                                color: "rgba(0,0,0,.65)"
                            }}
                        ></Statistic>
                    </div>
                }
            </div>
        )
    }
}

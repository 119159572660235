import React from 'react';
import AudioPlayer from 'utils/AudioPlayer';




export default class VoiceCell extends React.Component<any, any>{
    state = {
        playing: false
    }

    play = () => {
        const { content } = this.props
        const { playing } = this.state

        if (playing) {
            this.setState({
                playing: false
            }, () => {
                AudioPlayer.stop(content.target_url)
                    .then(() => {
                        this.setState({ playing: false })
                    })
                    .catch(() => {
                        this.setState({ playing: false })
                    })
            })
        } else {
            this.setState({
                playing: true
            }, () => {
                AudioPlayer.play(content.target_url)
                    .then(() => {
                        this.setState({ playing: false })
                    })
                    .catch(() => {
                        this.setState({ playing: false })
                    })
            })
        }
    }

    render() {
        return (
            <div style={{
                width: 128,
                height: 43,
                borderRadius: 22,
                backgroundColor: "#040219",
                position: "relative"
            }}>
                <div style={{
                    width: 128,
                    height: "100%",
                    background: "linear-gradient(125deg,rgba(49,216,247,1) 0%,rgba(212,98,251,1) 100%)",
                    opacity: 0.14,
                    borderRadius: 22
                }}>

                </div>
                <div style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    cursor: "pointer"
                }} onClick={this.play}>
                    {
                        this.state.playing ?
                            <img style={{ width: 90 }} src={require("../../images/playing.gif")} alt="" />
                            :
                            <img style={{ width: 90 }} src={require("../../images/default.png")} alt="" />
                    }
                </div>
            </div >
        )
    }
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { di, qs } from 'jsmodules';
import { ProductsState } from 'stores/products/products';
import ProductList from './ProductList';
import Actions from './Actions';


export interface IProductsProps extends RouteComponentProps {
}


@observer
class Products extends React.Component<IProductsProps> {
    @di.Inject() productsState: ProductsState


    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/products?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }


    public render() {

        const { records, status, pagination, totalCount } = this.productsState
        const queryParams = qs.decode(this.props.location.search) || {}

        return (
            <div className="main-content">
                <div style={{
                    color: "#D462FB",
                    fontSize: 24,
                    marginBottom: 36
                }}>
                    <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Product</span>
                </div>

                <div style={{ marginBottom: 36 }}>
                    <Actions
                        has_published={queryParams.has_published || ""}
                        onChange={this.queryChange}
                    ></Actions>
                </div>

                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <ProductList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></ProductList>
                </div>
            </div>
        );
    }
}

export default withRouter(Products)
import { observable } from 'mobx';
import { message } from 'antd';
import { di } from 'jsmodules';
import { UploadService } from 'services/upload';
import { UploadFileService } from 'services/uploadFile';




export class UploadState {
    @di.Inject() uploadService: UploadService

    @di.Inject() uploadFileService: UploadFileService


    @observable loading_upload = false
    async upload(data) {
        this.loading_upload = true
        var file: any = {
            name: data.name,
            size: data.size,
            type: data.type,
            body: data.originFileObj
        }
        
        try {
            var res = await this.uploadService.put(file)
            this.loading_upload = false
            return res
        } catch (error) {
            this.loading_upload = false
            message.error("upload fail")
        }
    }

    async uploadFile(data) {
        this.loading_upload = true
        var file: any = {
            name: data.name,
            size: data.size,
            type: data.type,
            body: data.originFileObj
        }
        
        try {
            var res = await this.uploadFileService.put(file)
            this.loading_upload = false
            return res
        } catch (error) {
            this.loading_upload = false
            message.error("upload fail")
        }
    }
}
import { CRUDService } from './_CRUDService';
import { di, HttpFactory } from 'jsmodules';

export class CallService extends CRUDService {
    @di.Inject("chat_api_v1") http: HttpFactory 

    constructor(){
        super("/admin/calls","chat_api_v1")
    }

    endCalling(id){
        return this.http.url(`/admin/calls/${id}/end`).addSecurityHeaders().post()
    }

    loadCallItem(id){
        return this.http.url(`/admin/calls/${id}`).addSecurityHeaders().get()
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import { di } from 'jsmodules';
import { MateState } from 'stores/mates/mate';
import { MateItemState } from 'stores/mates/mateItem';
import EditForm from './components/EditForm';

export interface IEditConProps extends RouteComponentProps<{ id }> {
}



@observer
export default class EditCon extends React.Component<IEditConProps> {
    @di.Inject() mateState: MateState

    componentWillMount() {
        const { id } = this.props.match.params

        this.mateState.recordState.load(id, item => new MateItemState(item))
    }


    public render() {

        const { status, record } = this.mateState.recordState

        if (status == "loading") {
            return <div></div>
        }

        return (

            <div className="main-container">
                <div className="main-content">

                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        marginBottom: 48
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Mate Edit</span>
                    </div>

                    <EditForm record={record}></EditForm>
                </div>
            </div>

        );
    }
}

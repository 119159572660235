import React from 'react';
import { Input, Button } from 'antd';
import { Link } from 'react-router-dom';


const Search = Input.Search

export default class Actions extends React.Component<any, any>{


    searchTextChange = (value) => {
        this.props.onChange({ nickname: value, page: 1 })
    }

    render() {

        const { nickname } = this.props

        return (
            <div>
                <div style={{ marginBottom: 24 }}>
                    <Link to="/editors/new"><Button type="primary">Add Editor</Button></Link>
                </div>

                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 80, marginRight: 36, lineHeight: "32px" }}>nickname</span>
                    <Search
                        enterButton="search"
                        defaultValue={nickname}
                        onSearch={this.searchTextChange}
                        style={{ width: 300 }}></Search>
                </div>

            </div>
        )
    }
}
import React from "react"
import {Icon} from 'antd'

export default class CustomIcon extends React.Component<any,any>{
    render(){
        const {type,...props} = this.props
        let _cdn = "//at.alicdn.com/t/font_846513_649kmonxl22.js"
        const IconFont = Icon.createFromIconfontCN({
            scriptUrl: _cdn,
          });
        return (
            <IconFont type={type} {...props} ></IconFont>
        )
    }
}
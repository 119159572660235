import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { di, qs } from 'jsmodules';
import { CategoriesState } from 'stores/categories/categories';
import Categories from './components/Categories';

export interface ICategoriesConProps extends RouteComponentProps {
}

export default class CategoriesCon extends React.Component<ICategoriesConProps> {
    @di.Inject() categoriesState: CategoriesState
    
    componentWillMount() {
        this.handleLoad(this.props);
    }

    componentWillUpdate(nextProps) {
        if (this.props.location.search != nextProps.location.search) {
            this.handleLoad(nextProps);
        }
    }

    handleLoad(props) {
        var { search } = props.location;
        var queryParams = qs.decode(search) || { page: 1 };

        var { page, pagesize, ...filter } = queryParams;
        if (filter) {
            this.categoriesState.filter(filter, true)
        }
        page = page * 1 || 1;

        if (pagesize) {
            this.categoriesState.page(page, pagesize * 1)
        } else {
            this.categoriesState.page(page)
        }
        this.categoriesState.loadRecords();
    }
    
    
    public render() {
        return (
            <div className="main-container">
                <Categories></Categories>
            </div>
        );
    }
}

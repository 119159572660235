import React from 'react';
import MateCollects from './components/MateCollects';




export default class MateCollectCon extends React.Component<any,any>{

    componentWillMount() {
        const { soulRecord } = this.props
        soulRecord.soulCollectState.loadRecords()
    }

    render(){
        return(
            <MateCollects soulRecord={this.props.soulRecord}></MateCollects>
        )
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { Radio } from 'antd';



@observer
export default class Actions extends React.Component<any, any>{

    targetTypeChange = (e) => {
        this.props.onChange({ target_type: e.target.value, page: 1 })
    }

    render() {
        const { target_type } = this.props
        
        return (
            <div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 120, marginRight: 36 }}>Target</span>
                    <Radio.Group defaultValue={target_type} onChange={this.targetTypeChange}>
                        <Radio value="">all</Radio>
                        {

                            ["Soul", "Mate", "Comment"].map(item => {
                                return <Radio key={item} value={item}>{item}</Radio>
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
        )
    }
}
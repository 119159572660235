import React from 'react';
import { observer } from 'mobx-react';
import { Table, Dropdown, List } from 'antd';
import { Link } from 'react-router-dom';
import { AmountCell } from 'web/components/AmountCell/AmountCell';
import { CurrencyCodeIcon } from 'config/common';



@observer
export default class OrderList extends React.Component<any, any>{

    columns: any = [
        {
            title: "amount",
            key: "Amount",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: record => <AmountCell currencyCode={record.currencyCode} amount={record.amount} ></AmountCell>
        },
        {
            title: "merchant",
            render: record => <div>
                {
                    record.merchantId == "1" ?
                        <div>sounmate</div>
                        :
                        <div><Link to={`/souls/${record.merchantId}`}>{record.merchantNickname}</Link></div>
                }
            </div>
        },
        {
            title: "customer",
            key: "customer_name",
            render: record => <CustomerNickname record={record}></CustomerNickname>
        },
        {
            title: "discount",
            key: "discountAmount",
            render: record => <AmountCell currencyCode={record.currencyCode} amount={record.discountAmount} ></AmountCell>
        },
        {
            title: "product",
            key: "productName",
            render: record => <ProductItems items={record.items}></ProductItems>
        },
        {
            title: "status",
            key: "status",
            dataIndex: "status"
        },
        {
            title: "payment status",
            key: "payment_status",
            dataIndex: "payment_status"
        },
        {
            title: "creation time",
            key: "creationTime",
            dataIndex: "creationTime"
        },
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}

const CustomerNickname = observer((props) => {
    const { record } = props
    return <div><Link to={`/souls/${record.customer.id}`}>{record.customer_name}</Link></div>
})



class ProductItems extends React.Component<any, any>{
    render() {

        const { items } = this.props

        return (
            <div>
                <Dropdown overlay={<ProductItemsCell items={items} />}>
                    <div style={{ fontSize: 12, cursor: "pointer", color: "#1890ff" }}>
                        {
                            items.map(item => {
                                return <div key={item.product.id}>{item.product.name}</div>
                            })
                        }
                    </div>
                </Dropdown>
            </div>
        )
    }
}


class ProductItemsCell extends React.Component<any, any> {


    render() {

        const { items } = this.props


        return (
            <div style={{
                background: "#f0f0f0",
                padding: "24px 36px",
                borderRadius: 4
            }}>
                <List
                    dataSource={items}
                    renderItem={item => (
                        <List.Item>
                            <ProductItemCell item={item}></ProductItemCell>
                        </List.Item>
                    )}
                ></List>
            </div>
        )
    }
}

const ProductItemCell = ({ item }) => (
    <div style={{
        display: "flex",
        alignItems: "center",
        fontSize: 12
    }}>
        <div style={{ flex: 1, fontWeight: 600, fontSize: 16, marginRight: 48 }}>{item.product.name}</div>
        <div style={{ marginRight: 24 }}>
            <div>price</div>
            <div style={{ fontSize: 14, fontWeight: 600 }}>{CurrencyCodeIcon[item.product.currency_code]} {item.unit_price}</div>
        </div>

        <div style={{ marginRight: 24 }}>
            <div>quantity</div>
            <div style={{ fontSize: 14, fontWeight: 600 }}>{item.quantity}</div>
        </div>

        <div style={{ marginRight: 24 }}>
            <div>discount</div>
            <div style={{ fontSize: 14, fontWeight: 600 }}>{CurrencyCodeIcon[item.product.currency_code]} {item.discount_amount}</div>
        </div>

        <div>
            <div>amount</div>
            <div style={{ fontSize: 14, fontWeight: 600 }}>{CurrencyCodeIcon[item.product.currency_code]} {item.amount}</div>
        </div>
    </div>
)
import { observable } from 'mobx';
import moment from 'moment'
import { BaseMomentFormat } from 'config/common';
import { di } from 'jsmodules';
import { Batch_soul_userid } from 'utils/Batch_soul_userid';


export class OrderItemState{
    @di.Inject() batch_soul_userid: Batch_soul_userid

    creationTime = ""
    @observable customer_name = ""
    currencyCode = ""
    amount
    subtotal_amount
    productName = ""
    discountAmount = 0
    status = ""
    payment_status = ""

    merchantId = ""
    merchantNickname = ""

    
    constructor(public item){
        for (const key in item) {
            this[key] = item[key]
        }

        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
        this.currencyCode = item.currency_code
        this.amount = item.amount.toFixed(2)
        this.productName = item.items[0].product.name
        this.discountAmount = item.discount_amount.toFixed(2)
        this.status = item.status
        this.subtotal_amount = item.subtotal_amount.toFixed(2)
        this.payment_status = item.payment_status

        if(item.merchant.id == "1"){
            this.merchantId = "1",
            this.merchantNickname = "sounmate"
        }else{
            this.merchantId = item.merchant.id
            this.merchantNickname = item.merchant.extend_data.Nickname
        }

        this.loadCustomerInfo()
    }

    async loadCustomerInfo(){
        var result = await this.batch_soul_userid.getData(this.item.customer.user_id)
        this.customer_name = result.nickname
    }

}
import * as queryString from 'utils/queryString';
import * as React from 'react';
import AppLoader from 'components/AppLoader';
import LoginForm from './components/LoginForm';
import { di } from 'jsmodules';
import { Redirect, RouteComponentProps } from 'react-router';
import { SessionState } from 'stores/session';
import { Tabs, message } from 'antd'
import './style.less';
import { observer } from 'mobx-react';
import EditorLoginForm from './components/LoginForm/EditorLoginForm';


const TabPane = Tabs.TabPane


@observer
class Login extends React.Component<RouteComponentProps<any>, any> {

    @di.Inject() session: SessionState;

    state = {
        redirectToReferrer: false,
        loginRole: "Admin"
    }

    loginRoleChange = (key) =>{
        this.setState({
            loginRole: key
        })
    }

    handleSubmit = async (values) => {

        var loader = AppLoader.show();
        
        this.session.login_role = this.state.loginRole

        try {
            
            await this.session.login(values.userName, values.password);
            localStorage.setItem("sounmate_login_role", this.state.loginRole)
            //this.setState({ redirectToReferrer: true });
        } catch (ex) {
            message.error("wrong user name or password");
        } finally {
            loader.close();
        }

    }

    render(): JSX.Element {
        const queryParams = queryString.resolve(this.props.location.search);
        const next = queryParams.next || "/";

        const { redirectToReferrer } = this.state


        if (this.session.isAuthenticated) {
            return (
                <Redirect to={next} />
            )
        }


        return (
            <div className="login-container">
                <div className="login-page">
                    <div className="login-logo">
                        sounmate
                    </div>
                    <Tabs activeKey={this.state.loginRole} onChange={this.loginRoleChange}>
                        <TabPane key="Admin" tab="Admin">
                            <LoginForm onSubmit={this.handleSubmit} />
                        </TabPane>
                        <TabPane key="Editor" tab="Editor">
                            <EditorLoginForm onSubmit={this.handleSubmit}></EditorLoginForm>
                        </TabPane>
                    </Tabs>
                    
                </div>
            </div>

        )
    }
}

export default Login;

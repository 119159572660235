import React from 'react';
import { observer } from 'mobx-react';
import { Table, Switch, notification, message, InputNumber, Dropdown, Icon, Modal, Input } from 'antd';
import { CurrencyCodeIcon } from 'config/common';
import { AmountCell } from 'web/components/AmountCell/AmountCell';


const TextArea = Input.TextArea


@observer
export default class ProductList extends React.Component<any, any>{


    columns = [
        {
            title: "name",
            key: "name",
            render: record => <div style={{ display: "flex" }}>
                <div style={{ flex: 1 }}>
                    {
                        record.description ?
                            <NameCell record={record}></NameCell>
                            :
                            <div>{record.name}</div>
                    }
                </div>
                <div style={{ marginLeft: 8 }}>
                    <BaseInfoEditModal record={record}></BaseInfoEditModal>
                </div>
            </div>
        },
        {
            title: "price",
            key: "price",
            render: record => <AmountCell currencyCode={record.currency_code} amount={record.price}></AmountCell>
        },
        {
            title: "total quantity",
            key: "total_quantity",
            render: record => <InventoryCell record={record}></InventoryCell>
        },
        {
            title: "stock quantity",
            key: "stock_quantity",
            dataIndex: "stock_quantity"
        },
        {
            title: "used quantity",
            key: "used_quantity",
            dataIndex: "used_quantity"
        },
        {
            title: "sale begin time",
            key: "sale_begin_time",
            dataIndex: "sale_begin_time"
        },
        {
            title: "sale end time",
            key: "sale_end_time",
            dataIndex: "sale_end_time"
        },
        {
            title: "publish",
            render: record => <PublishCell record={record}></PublishCell>
        }
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {
        const columns = this.columns;
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}




@observer
class PublishCell extends React.Component<any, any>{

    handelChange = async (checked) => {
        const { record } = this.props
        try {
            if (checked) {
                await record.publish()
                notification.success({
                    message: "publish success!"
                })
            }

            if (!checked) {
                await record.unPublish()
                notification.success({
                    message: "unPublish success!"
                })
            }
        } catch (error) {
            if (checked) {
                notification.error({
                    message: "publish fail!",
                    description: error.message
                })
            } else {
                notification.error({
                    message: "unPublish fail!",
                    description: error.message
                })
            }
        }
    }


    render() {

        const { record } = this.props

        return (
            <div>
                <Switch
                    checked={record.hasPublished}
                    onChange={this.handelChange}
                ></Switch>
            </div>
        )
    }
}


@observer
class InventoryCell extends React.Component<any, any>{


    state = {
        quantiy: this.props.record.total_quantity
    }


    inventoryChange = (value) => {



        this.setState({
            quantiy: value
        })
    }

    set = () => {

        if (this.state.quantiy < this.props.record.estimated_quantity) {
            message.error(`Inventory quantity needs to be greater than predicted sales quantity ${this.props.record.estimated_quantity}`)
            return this.setState({
                quantiy: this.props.record.total_quantity
            })
        }

        var postData = {
            total_quantity: this.state.quantiy
        }

        try {
            this.props.record.updateQuantity(postData)
        } catch (error) {
            notification.error({
                message: "update quantity fail",
                description: error.message
            })
        }


    }

    render() {
        const { record } = this.props
        return (
            <div>
                <InputNumber
                    onChange={this.inventoryChange}
                    onBlur={this.set}
                    min={0}
                    value={this.state.quantiy}
                ></InputNumber>
            </div>
        )
    }
}

@observer
class NameCell extends React.Component<any, any>{
    render() {
        const { record } = this.props


        const Content = (
            <div style={{ background: "#f0f0f0", padding: "8px 16px", borderRadius: 2 }}>
                <span>{record.description}</span>
            </div>
        )

        return (
            <div>
                <Dropdown overlay={Content} trigger={["click"]} >
                    <a>{record.name}</a>
                </Dropdown>
            </div>
        )
    }
}

class BaseInfoEditModal extends React.Component<any, any>{

    state = {
        visible: false,
        name: this.props.record.name,
        description: this.props.record.description
    }

    show = () => {
        this.setState({
            visible: true
        })
    }

    hide = () => {
        this.setState({
            visible: false
        })
    }

    nameChange = (e) =>{
        this.setState({
            name: e.target.value
        })
    }

    descriptionChange = (e) =>{
        this.setState({
            description: e.target.value
        })
    }

    edit = async () => {
        const {name,description} = this.state

        if(!name){
            return message.error("name can not be null")
        }

        var postData = {
            name,
            description
        }

        try {
            await this.props.record.updateBaseInfo(postData)
            this.setState({
                visible: false
            })
        } catch (error) {
            notification.error({
                message: "update produce info fail",
                description: error.message
            })
        }

    }

    render() {

        const { visible, name, description } = this.state

        return (
            <div>
                <a onClick={this.show}><Icon type="edit"></Icon></a>
                <Modal
                    title="product info edit"
                    onCancel={this.hide}
                    onOk={this.edit}
                    visible={visible}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <div style={{ marginBottom: 36 }}>
                        <div style={{ marginBottom: 8, fontWeight: "bold" }}>name</div>
                        <Input
                            value={name}
                            onChange={this.nameChange}
                        ></Input>
                    </div>

                    <div>
                        <div style={{ marginBottom: 8, fontWeight: "bold" }}>description</div>
                        <TextArea
                            value={description}
                            onChange={this.descriptionChange}
                            autosize={{ minRows: 4, maxRows: 8 }}
                        ></TextArea>
                    </div>

                </Modal>
            </div >
        )
    }
}
import { di } from 'jsmodules';
import { COSUrlConverter } from 'services/urlConvert';

export class FormatUploadUrl {
    @di.Inject() urlConvert: COSUrlConverter

    formatImageUrl(url){
        var temp = this.urlConvert.url(url).imageCdn().target_url
        return temp
    }

    formatFileUrl(url){
        var temp = this.urlConvert.url(url).videoCdn().target_url
        return temp
    }
}
import React from 'react';
import { List, Rate } from 'antd';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import ReviewCell from 'web/routes/Review/ReviewCell';



@observer
export default class Comments extends React.Component<any, any>{


    pageChange = (page) => {
        const { mateCommentState } = this.props.mateRecord
        mateCommentState.page(page)
        mateCommentState.loadRecords()
    }

    pageSizeChange = (current, size) => {
        const { mateCommentState } = this.props.mateRecord
        mateCommentState.page(current, size)
        mateCommentState.loadRecords()
    }

    render() {

        const { mateRecord } = this.props

        const { records, pagination, status } = mateRecord.mateCommentState

        return (
            <div>
                <List
                    itemLayout="horizontal"
                    dataSource={records}
                    loading={status == "loading"}
                    pagination={pagination.total == 0 ? false : {
                        ...pagination,
                        onChange: this.pageChange,
                        onShowSizeChange: this.pageSizeChange
                    }}
                    renderItem={item => (
                        <ListItem item={item}></ListItem>
                    )}
                />
            </div>
        )
    }
}

@observer
class ListItem extends React.Component<any, any>{
    render() {
        const { item } = this.props
        return (
            <List.Item>
                <List.Item.Meta
                    title={
                        <div style={{ display: "flex", alignItems: "baseline" }}>
                            <Link to={`/souls/${item.author.id}`} className="linkColor" style={{ marginRight: 36, flex: 1 }}>{item.author.nickname}</Link>
                            <Rate allowHalf defaultValue={5} disabled />
                            <div style={{marginLeft: 24}}>
                                <ReviewCell record={item} type="Comment"></ReviewCell>
                            </div>
                        </div>
                    }
                    description={item.content}
                />
            </List.Item>
        )
    }
}
import { di, HttpFactory } from 'jsmodules';
import { CRUDService } from './_CRUDService';



export class AccountService extends CRUDService {
    @di.Inject() identity_api_v1: HttpFactory

    constructor() {
        super("/admin/accounts", "identity_api_v1")
    }

    block(id) {
        return this.identity_api_v1.url(`/admin/accounts/${id}/block`).addSecurityHeaders().put()
    }

    unblock(id) {
        return this.identity_api_v1.url(`/admin/accounts/${id}/unblock`).addSecurityHeaders().put()
    }

    checkPhoneNumberExsit(code,number){
        return this.identity_api_v1.url(`/accounts/phones/${encodeURIComponent(code)}-${encodeURIComponent(number)}`).head();
    }


}
import React from 'react';
import { Radio } from 'antd';
import AddCoinCell from './AddCoinCell';




export default class Actions extends React.Component<any, any>{

    typeChange = (e) => {
        this.props.onChange({ type: e.target.value, page: 1 })
    }


    render() {

        const { type } = this.props

        return (
            <div>
                <div style={{ marginBottom: 24 }}>
                    <AddCoinCell></AddCoinCell>
                </div>
                <div>
                    <span style={{ color: "#101934", minWidth: 80, marginRight: 36, lineHeight: "32px" }}>type</span>
                    <Radio.Group defaultValue={type} onChange={this.typeChange}>
                        <Radio value="">all</Radio>
                        <Radio value="virtual">virtual</Radio>
                        <Radio value="crypto">crypto</Radio>
                    </Radio.Group>
                </div>
            </div>

        )

    }
}
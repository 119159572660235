import React from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { Icon, Table, Divider } from 'antd';
import { CurrencyCodeIcon } from 'config/common';




@observer
export default class MateCollects extends React.Component<any, any>{

    columns: any = [
        {
            title: "nickname",
            dataIndex: "nickname",
            key: "nickname",
            render: (text, record) => <NicknameCell record={record}></NicknameCell>
        },
        {
            title: "price rule",
            render: record => (
                <div>
                    <span>{record.unitPrice}</span>
                    <span>{CurrencyCodeIcon[`${record.currencyCode}`]}</span>
                    <span style={{ padding: "0 2px" }}>/</span>
                    <span>{record.unitTimeSeconds / 60}mins</span>
                </div>
            )
        },
        {
            title: "birthday",
            render: record => <BirthdayCell record={record}></BirthdayCell>
        },
        {
            title: "gender",
            render: record => <GenderCell record={record}></GenderCell>
        },
        {
            title: "evaluation",
            render: record => (
                <div>
                    <Icon type="star" theme="filled" />
                    <span>{record.averageScore}</span>
                    <Divider type="vertical"></Divider>
                    <span>times: {record.evaluationCount}</span>
                </div>
            )
        }
    ]


    handleTableChange = (pagination, filters, sorter) => {

        const { soulCollectState } = this.props.soulRecord

        if (!sorter.order) {
            soulCollectState.filter({ sorting: "CreationTime desc" })
            soulCollectState.page(pagination.current,pagination.pageSize)
        } else {
            // this.props.onChange(
            //     {
            //         page: pagination.current,
            //         pagesize: pagination.pageSize,
            //         sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
            //     }
            // )
        }

        soulCollectState.loadRecords()
    }


    render() {

        const { soulRecord } = this.props

        const { records, pagination, status, totalCount } = soulRecord.soulCollectState

        return (
            <div>
                <div style={{ fontSize: 12, marginBottom: 12 }}>
                    <span style={{ fontWeight: 600 }}>{totalCount}</span> results
                </div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}


const NicknameCell = observer(({ record }) => {
    return <Link to={`/mates/${record.id}`}>
        <div className="linkColor">{record.nickname}</div>
    </Link>
})

const BirthdayCell = observer(({ record }) => {
    return <div>{record.birthday}</div>
})

const GenderCell = observer(({ record }) => {
    return <div>{record.gender}</div>
})


const ReviewResultCell = observer(({ record }) => {
    return (
        <React.Fragment>
            {
                record.reviewResult ?
                    <React.Fragment>
                        {
                            record.reviewResult == "Normal" && <div style={{ color: "#52c41a" }}>Normal</div>
                        }
                        {
                            record.reviewResult == "Illegal" && <div style={{ color: "#f5222d" }}>Illegal</div>
                        }
                    </React.Fragment>
                    :
                    <div>
                        unReview
                    </div>
            }
        </React.Fragment>
    )
})
import { CRUDState } from 'stores/_CRUDState';
import { SoulItemState } from './soulItemState';




export class SoulState extends CRUDState {


    

    constructor() {
        super("soulService", item => new SoulItemState(item))
    }
}
import { di } from 'jsmodules';
import { MemberService } from 'services/member';
import { observable } from 'mobx';
import { WalletTransationState } from 'stores/mates/walletTransation';



export class SoulWalletState {
    @di.Inject() memberService: MemberService

    walletTransationState: WalletTransationState

    @observable id

    constructor(public soul_id){
        
    }

    @observable balances = []
    async loadWallets(){
        var res = await this.memberService.loadWallet({owner_ids: this.soul_id})
        this.balances = res.data.result.items[0].currency_balances

        this.walletTransationState = new WalletTransationState()

        this.id = res.data.result.items[0].id
    }
}
import React from 'react';
import { observer } from 'mobx-react';
import { Table, Button, Popconfirm, notification } from 'antd';
import { AmountCell } from 'web/components/AmountCell/AmountCell';



@observer
export default class CallsList extends React.Component<any, any>{

    columns = [
        {
            title: "caller",
            key: "caller",
            render: record => <FromNicknameCell record={record}></FromNicknameCell>
        },

        {
            title: "callee",
            render: record => <ToNicknameCell record={record}></ToNicknameCell>
        },

        {
            title: "dial time",
            key: "creationTime",
            dataIndex: "creationTime"
        },

        {
            title: "start time",
            dataIndex: "startTime"
        },

        {
            title: "end time",
            render: record => <EndCallingCell record={record}></EndCallingCell>
        },
        {
            title: "dial duration",
            render: record => <div>{record.dial_duration} s</div>
        },

        {
            title: "duration",
            dataIndex: "callTime"
        },
        
        {
            title: "type",
            dataIndex: "type"
        },

        {
            title: "status",
            render: record => <StatusCell record={record}></StatusCell>
        },

        {
            title: "price rule",
            dataIndex: "rule"
        },

        {
            title: "amount",
            render: record => <AmountCell currencyCode={record.currencyCode} amount={record.amount} ></AmountCell>
        },
    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }


    render() {
        const columns = this.columns;
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}


const ToNicknameCell = observer(({ record }) => {
    return (
        <div>{record.to_nickname}</div>
    )
})

const FromNicknameCell = observer(({ record }) => {
    return (
        <div>{record.from_nickname}</div>
    )
})

const StatusCell = observer(({ record }) => {
    return (
        <div>{record.status}</div>
    )
})



@observer
class EndCallingCell extends React.Component<any, any>{


    confirm = async () => {
        const { record } = this.props
        try {
            await record.endCalling(record.id)
            notification.success({
                message: "end calling success!"
            })
        } catch (error) {
            notification.error({
                message: "end calling fail",
                description: error.message
            })
        }
    }

    render() {

        const { record } = this.props

        return (
            <div>
                {
                    record.status != "Calling" ?
                        <div>{record.endTime}</div>
                        :
                        <Popconfirm title="Are you sure you want to end the call now?"
                            onConfirm={this.confirm}
                            okText="yes"
                            cancelText="no">
                            <Button
                                type="primary"
                                size="small"
                                loading={record.loading_endCalling}
                            >End Now</Button>
                        </Popconfirm>
                }
            </div>
        )
    }
}
import React from 'react';
import { message, Icon, Upload, Modal } from 'antd';
import { observable, computed } from 'mobx';
import Compressor from 'compressorjs'
import { observer } from 'mobx-react';



type UploadTaskStatus = "none" | "compress" | "ready" | "success" | "max" | "not-support" | "fail";

class UploadTaskObject {


    @observable _status: UploadTaskStatus = "none";

    url: string;

    name: string;
    width: number;
    height: number;
    size: number
    type: ""

    originFileObj



    constructor(public src) {

        this.url = src.url;
        this.name = src.name;
        this.size = src.size
        this.type = src.type
        this.originFileObj = src.originFileObj
        this._init();
    }

    private async _init() {
        await this.getImageInfo(this.src.originFileObj);
        await this.compress();
    }

    private async getImageInfo(file) {
        return new Promise((resolve, reject) => {
            var url = URL.createObjectURL(file);
            var image = new Image();
            image.onload = (e) => {
                this.width = image.width;
                this.height = image.height;
                resolve();
            }
            image.onerror = () => {
                this._status = "fail";
                reject();
            }
            image.src = url;
        });
    }



    private async compress() {
        if (this.src.type == "image/gif") {
            this._status = "ready";
        } else {
            try {
                var originWidth = this.width
                var originHeight = this.height;
                var min = originWidth >= originHeight ? { type: "height", mlength: originHeight } : { type: "width", mlength: originWidth }
                var options: any = { quality: .6 };
                if (min.mlength > 1280) {
                    if (min.type == "width") {
                        options.width = 1280;
                    }
                    else {
                        options.height = 1280;
                    }
                }
                this._status = "compress"
                var self = this
                new Compressor(this.src.originFileObj, {
                    ...options,
                    async success(result) {
                        await self.getImageInfo(result)
                        self.originFileObj = result;
                        self.size = result.size
                        if (self.originFileObj.size > 1024 * 1024 * 5) {
                            self._status = "max";
                        } else {
                            self._status = "ready";
                        }
                    },
                    error(err) {
                        console.log(err.message);
                        self._status = "fail"
                    },
                });

            } catch (ex) {
                this._status = "fail"
            }
        }

    }

}


class filesState {
    @observable newFiles = []
    @observable showfiles = []

    constructor(files) {
        this.showfiles = files
        this.init(files)
    }

    init(files) {
        for (var file of files) {
            if (file._status) {
                this.newFiles.push(file)
                continue;
            }
            var fileObj = new UploadTaskObject(file);
            this.newFiles.push(fileObj);
        }
    }

}






function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}


@observer
export class ImageUpload extends React.Component<any, any> {
    state = {
        previewVisible: false,
        previewImage: '',
        filesState: {
            newFiles: [],
            showfiles: this.props.value || []
        }
    };

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
        });
    };

    handleChange = ({ file, fileList }) => {
        this.setState({
            filesState: new filesState(fileList)
        }, () => {
            this.props.onChange(this.state.filesState.newFiles)
        })
    };

    render() {
        const { previewVisible, previewImage, filesState } = this.state;
        let quantity = this.props.quantity || 1

        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">Upload</div>
            </div>
        );
        return (
            <div className="clearfix">
                <Upload
                    accept="image/jpeg,image/jpg,image/png,image/gif"
                    listType="picture-card"
                    fileList={filesState.showfiles}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                >
                    {filesState.showfiles.length >= quantity ? null : uploadButton}
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
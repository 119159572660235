import { CRUDState } from 'stores/_CRUDState';
import { AccountItemState } from './accountItemState';
import { di } from 'jsmodules';
import { AccountService } from 'services/account';
import { observable } from 'mobx';

export class AccountState extends CRUDState {
    @di.Inject() accountService: AccountService


    constructor() {
        super("accountService", item => new AccountItemState(item))
    }


    @observable loading_add = false
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { di, qs } from 'jsmodules';
import { TransactionState } from 'stores/transations/transations';
import TransationList from './TransationList';
import Actions from './Actions';
import { Button } from 'antd';

export interface ITransationsProps extends RouteComponentProps {
}



@observer
class Transations extends React.Component<ITransationsProps> {
    @di.Inject() transactionState: TransactionState

    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/transations?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    refresh = () => {
        this.transactionState.loadRecords()
    }

    public render() {

        const { records, status, pagination, totalCount } = this.transactionState
        const queryParams = qs.decode(this.props.location.search) || {}


        return (
            <div className="main-content">
                <div style={{ marginBottom: 36, display: "flex", alignItems: "baseline" }}>
                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        flex: 1
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Transations</span>
                    </div>
                    <div>
                        <Button onClick={this.refresh} type="primary" icon="sync">refresh</Button>
                    </div>
                </div>


                <div style={{ marginBottom: 36 }}>
                    <Actions
                        type={queryParams.type || ""}
                        onChange={this.queryChange}
                    ></Actions>
                </div>

                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <TransationList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></TransationList>
                </div>

            </div>
        );
    }
}

export default withRouter(Transations)
import { CRUDState } from 'stores/_CRUDState';
import { CRUDService } from 'services/_CRUDService';
import { di, HttpFactory } from 'jsmodules';
import { CategoryProductItem } from './categoryProductItem';




class CategoryProductService extends CRUDService{
    @di.Inject("content_api_v1") http: HttpFactory
    
    constructor(public category_id){
        super(`/admin/categories/${category_id}/items`,"content_api_v1")
    }

    delete(id,data){
        return this.http.url(`/admin/categories/${id}/items`).addSecurityHeaders().remove(data)
    }
}


export class CategoryProductState extends CRUDState{
    constructor(public category_id){
        super(new CategoryProductService(category_id), item => new CategoryProductItem(this,item))
    }
}
import React from 'react';
import { DatePicker, Radio } from 'antd';



const { RangePicker } = DatePicker;



export default class Actions extends React.Component<any, any>{



    statusChange = (e) => {
        this.props.onChange({ status: e.target.value, page: 1 })
    }

   

    render() {

        const { status } = this.props

        return (
            <div>
                <div style={{ marginBottom: 24 }}>
                    <span style={{ color: "#101934", minWidth: 120, marginRight: 36 }}>Order Status</span>
                    <Radio.Group defaultValue={status} onChange={this.statusChange}>
                        <Radio value="">all</Radio>
                        {

                            ["None", "Processing", "Authorized", "Completed", "Rejected"].map(item => {
                                return <Radio key={item} value={item}>{item}</Radio>
                            })
                        }
                    </Radio.Group>
                </div>
            </div>
        )
    }
}
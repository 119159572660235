import React from 'react';
import { observer } from 'mobx-react';
import { Table, Button, InputNumber } from 'antd';
import { AmountCell } from 'web/components/AmountCell/AmountCell';




@observer
export default class CategoryProductList extends React.Component<any, any>{

    columns: any = [
        {
            title: "name",
            key: "name",
            render: record => <NameCell record={record}></NameCell>
        },
        {
            title: "description",
            render: record => <DescriptionCell record={record}></DescriptionCell>
        },
        {
            title: "price",
            render: record => <PriceCell record={record}></PriceCell>
        },
        {
            title: "stock quantity",
            render: record => <StockQuantityCell record={record}></StockQuantityCell>
        },
        {
            title: "used quantity",
            render: record => <UsedQuantityCell record={record}></UsedQuantityCell>
        },

        {
            title: "status",
            render: record => <StatusCell record={record}></StatusCell>
        },

        {
            title: "index",
            render: reocrd => <IndexCell record={reocrd}></IndexCell>
        },

        {
            title: "operation",
            render: record => <Button size="small" type="primary" onClick={() => this.delete(record)}>remove</Button>
        }

    ]

    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    delete = (record) => {
        record.delete()
    }

    render() {

        const { records, status, pagination, onChange } = this.props

        return (

            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>

        )
    }
}


const NameCell = observer(({ record }) => {
    return (
        <div>{record.name}</div>
    )
})

const DescriptionCell = observer(({ record }) => {
    return (
        <div>{record.name}</div>
    )
})

const PriceCell = observer(({ record }) => {
    return (
        <AmountCell currencyCode={record.currency_code} amount={record.price} ></AmountCell>
    )
})

const StockQuantityCell = observer(({ record }) => {
    return (
        <div>{record.stock_quantity}</div>
    )
})

const UsedQuantityCell = observer(({ record }) => {
    return (
        <div>{record.used_quantity}</div>
    )
})

const StatusCell = observer(({ record }) => {
    return (
        <div>{record.isPublish ? "publish" : "unpublish"}</div>
    )
})


@observer
class IndexCell extends React.Component<any, any>{

    state = {
        index: this.props.record.index
    }

    indexChange = (index) => {
        this.setState({
            index: index
        })
    }

    setIndex = () => {
        const { record } = this.props
        record.updateIndex(this.state.index)
    }

    render() {



        return (
            <InputNumber
                min={0}
                value={this.state.index}
                onChange={this.indexChange}
                onBlur={this.setIndex}
            ></InputNumber>
        )
    }
}
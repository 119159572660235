import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';




export default class MateDetails extends React.Component<any, any>{

    state = {
        photoIndex: 0,
        isOpen: false,
    };


    render() {

        const { images } = this.props
        const { isOpen, photoIndex } = this.state

        return (
            <div>
                <div style={{
                    display: "flex"
                }}>

                    {
                        images.map((iamge, index) => {
                            return (
                                <div
                                    onClick={() => this.setState({ isOpen: true, photoIndex: index })}
                                    key={iamge.url}
                                    style={{
                                        marginRight: 24,
                                        width: 160,
                                        overflow: "hidden",
                                        backgroundImage: `url(${iamge.target_url})`,
                                        backgroundSize: "cover",
                                        backgroundPosition: "center",
                                        borderRadius: "2px",
                                        cursor:"pointer"
                                    }}>
                                    <div style={{ marginBottom: "120%" }}></div>
                                </div>
                            )

                        })
                    }

                </div>
                {
                    isOpen &&
                    <Lightbox
                        mainSrc={images[photoIndex].target_url}
                        nextSrc={images[(photoIndex + 1) % images.length].target_url}
                        prevSrc={images[(photoIndex + images.length - 1) % images.length].target_url}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length,
                            })
                        }
                        onMoveNextRequest={() =>
                            this.setState({
                                photoIndex: (photoIndex + 1) % images.length,
                            })
                        }
                    />
                }
            </div>

        )
    }
}
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { qs, di } from 'jsmodules';
import { FeedbackState } from 'stores/feedback/feedback';
import FeedbackList from './FeedbackList';

export interface IFeedbacksProps extends RouteComponentProps {
}



@observer
class Feedbacks extends React.Component<IFeedbacksProps> {
  @di.Inject() feedbackState: FeedbackState

  handleSearch(query, removeKeys?) {
    var last_query = qs.decode(this.props.location.search) || {};

    var new_query = { ...last_query, ...query };
    if (removeKeys) {
      for (var key of removeKeys) {
        delete new_query[key];
      }
    }
    this.props.history.push(`/feedbacks?${qs.encode(new_query)}`)
    var div1 = document.getElementById("scroll-container")
    div1.scrollTop = 0
  }


  queryChange = (value) => {
    var keys = []
    for (var key in value) {
      if (!value[key]) {
        keys.push(key)
      }
    }
    if (keys.length != 0) {
      this.handleSearch(value, keys)
    } else {
      this.handleSearch(value)
    }
  }

  refresh = () => {
    this.feedbackState.loadRecords()
  }


  public render() {

    const { records, status, pagination } = this.feedbackState
    const queryParams = qs.decode(this.props.location.search) || {}

    return (
      <div className="main-content">
        <div style={{
          color: "#D462FB",
          fontSize: 24,
          marginBottom: 36
        }}>
          <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Feedback</span>
        </div>
        <div>
          <FeedbackList
            records={records}
            suatus={status}
            pagination={pagination}
          ></FeedbackList>
        </div>
      </div>
    );
  }
}

export default withRouter(Feedbacks)
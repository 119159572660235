import { di } from 'jsmodules';
import { COSUrlConverter } from 'services/urlConvert';
import { observable } from 'mobx';
import moment from 'moment'
import { BaseMomentFormat } from 'config/common';


export class FeedbackItemState {
    @di.Inject() urlConvert: COSUrlConverter

    @observable avatar = ""
    creationTime

    constructor(item) {
        for (const key in item) {
            this[key] = item[key]
        }

        this.avatar = item.customer.avatar ? this.handleAvatar(item.customer.avatar) : ""
        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
    }

    handleAvatar = (url) => {
        var temp = ""
        if (url.indexOf("http") != -1) {
            temp = url
        } else {
            temp = this.urlConvert.url(url).imageCdn().thumbnail(132, 132)
        }
        return temp
    }
}
import Logger from '../../utils/logger';
import { di } from 'jsmodules';
import { observable } from 'mobx';
import { IAppPlugin, module, moduleFunc } from '.';
import { IKeyValueStorage } from '../../storage/IKeyValueStorage';
import { SessionState } from '../session';
import TokenService from '../../services/token';

export enum UserStatus {
    none,
    loading,
    local,
    success
}

/**
 * 用户模块
 */
export class SessionPlugin implements IAppPlugin {

    @di.Inject() private kvStorage: IKeyValueStorage;

    @di.Inject() private tokenService: TokenService;

    @di.Inject() private session: SessionState

    name = "session";

    __ready__: boolean;

    __installed__: boolean;


    @module
    async install() {
        Logger.info("查询本地是否记住登录状态")
        var access_token = await this.tokenService.getAccessToken();
        if (access_token) {
            this.session.isAuthenticated = true;
            Logger.info("取得本地登录状态")
        }
    }
}
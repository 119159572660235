import React from 'react';
import CallMissed from './components/CallMissed';
import { observer } from 'mobx-react';




@observer
export default class CallMissedCon extends React.Component<any,any>{

    componentWillMount() {
        const {soulRecord} = this.props
        soulRecord.callMissed.filter({ 
            statuses: ["DialHangUp","DialTimeout"].join(","),
            participant_ids: soulRecord.chatuser.id
         }, true)
        soulRecord.callMissed.loadRecords()
    }

    componentWillUpdate(nextProps) {
        const { selectTab } = this.props
        if(selectTab != nextProps.selectTab){
            if(nextProps.selectTab == "missed"){
                nextProps.soulRecord.callMissed.loadRecords()
            }
        }
    }

    render(){
        const {soulRecord} = this.props
        
        return(
            <CallMissed record={soulRecord}></CallMissed>
        )
    }
}
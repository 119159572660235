import React from 'react';
import { Tabs } from 'antd';
import TransfersCon from './TransfersCon';
import DepositCon from './DepositCon';
import WithdrawCon from './WithdrawCon';
import { observer } from 'mobx-react';



const TabPane = Tabs.TabPane


@observer
export default class PaymentsCon extends React.Component<any, any>{

    state = {
        key: "deposit"
    }

    componentWillMount() {
        const { mateRecord } = this.props
        mateRecord.walletState.walletTransationState.filter({
            account_identity: mateRecord.walletState.id,
            type: "DepositTransaction"
        }, true)
        mateRecord.walletState.walletTransationState.loadRecords()
    }

    keyChange = (key) => {
        const { mateRecord } = this.props

        this.setState({
            key: key
        }, () => {
            if (key == "deposit") {
                mateRecord.walletState.walletTransationState.filter({
                    account_identity: mateRecord.walletState.id,
                    type: "DepositTransaction"
                }, true)
                mateRecord.walletState.walletTransationState.loadRecords()
            }
            if (key == "withdraw") {
                mateRecord.walletState.walletTransationState.filter({
                    account_identity: mateRecord.walletState.id,
                    type: "WithdrawTransaction"
                }, true)
                mateRecord.walletState.walletTransationState.loadRecords()
            }
            if (key == "transfers") {
                mateRecord.walletState.walletTransationState.filter({
                    account_identity: mateRecord.walletState.id,
                    type: "TransferTransaction"
                }, true)
                mateRecord.walletState.walletTransationState.loadRecords()
            }
        })


    }

    render() {

        const { mateRecord } = this.props

        return (
            <div>
                <Tabs activeKey={this.state.key} onChange={this.keyChange}>
                    <TabPane tab="deposit" key="deposit">
                        <DepositCon record={mateRecord}></DepositCon>
                    </TabPane>
                    <TabPane tab="withdraw" key="withdraw">
                        <WithdrawCon record={mateRecord}></WithdrawCon>
                    </TabPane>
                    <TabPane tab="transfer" key="transfers">
                        <TransfersCon record={mateRecord}></TransfersCon>
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}

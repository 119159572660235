import React from 'react';
import { Upload, Icon, message, Modal, Button } from 'antd';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { dataUrlToFile } from 'utils/methods';


export default class AvatarUpload extends React.Component<any, any>{

    static getDerivedStateFromProps(nextProps) {
        // Should be a controlled component.
        if ('value' in nextProps) {
            return {
                ...(nextProps.value || {}),
            };
        }
        return null;
    }

    constructor(props) {
        super(props)

        this.state = {
            fileList: props.value || [],
            srcCropper: '',
            selectImgFile: '',
            editImageModalVisible: false
        }
    }

    triggerChange = (changedValue) => {
        // Should provide an event to pass value to Form.
        const onChange = this.props.onChange;
        console.log(changedValue)
        if (onChange) {
            onChange(changedValue);
        }
    }


    handleCancel = () => {
        this.setState({
            editImageModalVisible: false,
            fileList: []
        })
    }

    endEdit = () => {
        this.setState({
            editImageModalVisible: false
        })
    }



    saveImg = () => {
        const { selectImgFile, } = this.state
        const dataUrl = this.refs.cropper["getCroppedCanvas"]().toDataURL();
        var re_file = dataUrlToFile(dataUrl, selectImgFile)
        re_file["originFileObj"] = re_file
        this.triggerChange([re_file])
        this.setState({
            fileList: [re_file]
        }, () => {
            this.endEdit()
        })
    }

    handleChange = ({ file, fileList }) => {

        this.triggerChange(fileList)
        this.setState({ fileList })


    }

    beforeUpload = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error({ content: '文件大小不能超过10M' });
            return false;
        }
        var reader = new FileReader();
        reader.readAsDataURL(file); //开始读取文件
        // 因为读取文件需要时间,所以要在回调函数中使用读取的结果
        reader.onload = (e) => {
            const dataURL = e.target["result"];
            this.setState({
                srcCropper: dataURL,
                selectImgFile: file,
                editImageModalVisible: true,
            })
        }
        return false;
    }
    render() {
        const { fileList, editImageModalVisible } = this.state
        const uploadButton = (
            <div>
                <Icon type='plus' />
                <div className="ant-upload-text">上传</div>
            </div>
        );

        return (
            <React.Fragment>
                <Upload
                    accept="image/jpeg,image/jpg,image/png"
                    beforeUpload={this.beforeUpload}
                    listType="picture-card"
                    fileList={fileList}
                    onChange={this.handleChange}
                    showUploadList={{ showPreviewIcon: false, showRemoveIcon: true }}
                >
                    {
                        fileList.length == 0 && uploadButton
                    }
                </Upload>
                <Modal
                    visible={editImageModalVisible}
                    width={1000}
                    footer={[
                        <Button key="1" type="primary" onClick={this.saveImg}>保存</Button>,
                        <Button key="2" onClick={this.handleCancel}>取消</Button>
                    ]}
                >
                    <Cropper
                        style={{ 'height': 400 }}
                        src={this.state.srcCropper}
                        className="company-logo-cropper"
                        ref="cropper"
                        viewMode={1}
                        zoomable={false}
                        aspectRatio={128 / 128}
                        guides={true}
                        //viewMode={1}
                        background={false} //是否显示马赛克
                        rotatable={true} //是否旋转
                    />
                </Modal >
            </React.Fragment >
        )
    }
}
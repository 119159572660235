import en_US from './en-US';
import zh_CN from './zh-CN';



export interface LangDefinition{

}

const lang={
    "en-us":en_US,
    "zh-cn":zh_CN
}

export default lang
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import { Form, Input, Divider, Radio, Icon, DatePicker, InputNumber, Button, notification } from 'antd'
import { observer } from 'mobx-react';
import moment from 'moment'
import AudioUploadInForm from 'components/ImageUpload/AudioUploadInForm';
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { di } from 'jsmodules';
import { UploadState } from 'stores/upload';
import { BirthdayFormat } from 'config/common';
import { FormatUploadUrl } from 'stores/formatUploadUrl';


const TextArea = Input.TextArea;

export interface IRouteComponentProps extends RouteComponentProps {
    record: any
}


@observer
class EditForm extends React.Component<IRouteComponentProps & FormComponentProps> {
    @di.Inject() uploadState: UploadState
    @di.Inject() formatUploadUrl: FormatUploadUrl



    handleEdit = (e) => {
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.props.record.loading_edit = true
                var soulInfo_postData = {
                    nickname: values.nickname,
                    gender: values.gender,
                    birthday: moment(values.birthday).utc().format()
                }

                var mateInfo_postData = {
                    attachments: [],
                    audition_voices: [],
                    voice_product: {
                        unit_time_seconds: 60,
                        currency_code: "NTS",
                        unit_price: values.price
                    },
                    about: values.about
                }

                mateInfo_postData.audition_voices[0] = {
                    extend_data: values.audition_voices.extend_data,
                    type: "Audio",
                    url: values.audition_voices.target_url,
                }

                for (let i = 0; i < values.images.length; i++) {
                    if (values.images[i]._status == "done") {
                        mateInfo_postData.attachments[i] = {
                            extend_data: values.images[i].extend_data,
                            type: "Image",
                            url: values.images[i].baseUrl
                        }
                    } else {

                        var tempUrl = await this.uploadState.upload(values.images[i])
                        mateInfo_postData.attachments[i] = {
                            extend_data: {
                                width: values.images[i].width,
                                height: values.images[i].height,
                                type: values.images[i].type,
                                size: values.images[i].size
                            },
                            type: "Image",
                            url: this.formatUploadUrl.formatImageUrl(tempUrl.filePath) 
                        }
                    }
                }


                // console.log(soulInfo_postData)
                //  console.log(mateInfo_postData)
                //  this.props.record.loading_edit = false

                try {
                    await this.props.record.handleEdit(soulInfo_postData, mateInfo_postData)
                    notification.success({
                        message: "success!"
                    })
                    this.props.record.loading_edit = false
                    this.props.history.push(`/mates/${this.props.record.id}`)
                } catch (error) {
                    notification.error({
                        message: "update fail",
                        description: error.message
                    })
                    this.props.record.loading_edit = false
                }

            }
        })
    }


    public render() {

        const { getFieldDecorator } = this.props.form
        const { record } = this.props

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18, offset: 2 },
            },
        };

        let showImages = this.props.record.images.map((item, index) => {
            var temp = {
                uid: index,
                url: item.target_url,
                baseUrl: item.url,
                extend_data: item.extend_data,
                _status: "done"
            }
            return temp
        })

        return (
            <div style={{
                marginBottom: 140
            }}>
                <Form {...formItemLayout}>
                    <Form.Item label="name" >
                        {
                            getFieldDecorator("nickname", {
                                initialValue: record.nickname,
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <Input style={{ minWidth: 320, width: "60%" }}></Input>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="gender">
                        {
                            getFieldDecorator("gender", {
                                initialValue: record.gender,
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <Radio.Group>
                                    <Radio value="Male"><Icon type="man" /></Radio>
                                    <Radio value="Female"><Icon type="woman" /></Radio>
                                </Radio.Group>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="birthday">
                        {
                            getFieldDecorator("birthday", {
                                initialValue: record.birthday ? moment(record.birthday, BirthdayFormat) : null,
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <DatePicker></DatePicker>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="about you">
                        {
                            getFieldDecorator("about", {
                                initialValue: record.about,
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <TextArea rows={6} style={{ minWidth: 320, width: "60%" }}></TextArea>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="your voice">

                        {
                            getFieldDecorator("audition_voices", {
                                initialValue: record.voice[0],
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <AudioUploadInForm></AudioUploadInForm>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="photoes">
                        <div>

                        </div>
                        <div>
                            {getFieldDecorator('images', {
                                initialValue: showImages,
                                rules: [
                                    {
                                        required: true, message: "can not be null!",
                                    }
                                ]
                            })(
                                <ImageUpload quantity={9}></ImageUpload>
                            )}
                        </div>
                    </Form.Item>

                    <Divider orientation="center">sale info</Divider>

                    <Form.Item label="your price">
                        <div style={{ display: "flex" }}>
                            {
                                getFieldDecorator("price", {
                                    initialValue: record.unitPrice,
                                    rules: [
                                        {
                                            required: true, message: "can not be null!",
                                        }
                                    ]
                                })(
                                    <InputNumber min={0}></InputNumber>
                                )
                            }
                            <div style={{ marginLeft: 6 }}>
                                ♪/1min
                            </div>
                        </div>
                    </Form.Item>
                </Form>

                <div style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 68,
                    lineHeight: "68px",
                    marginLeft: 200,
                    zIndex: 100,
                    backgroundColor: "#fff",
                    textAlign: "right",
                    borderTop: "1px solid #d8d8d8"
                }}>
                    <Button
                        type="primary"
                        icon="save"
                        style={{ marginRight: "20%" }}
                        loading={this.props.record.loading_edit}
                        onClick={this.handleEdit}
                    >save</Button>
                </div>
            </div>
        );
    }
}


const _EditForm = Form.create<IRouteComponentProps & FormComponentProps>()(EditForm)


export default withRouter(_EditForm)




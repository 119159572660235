import React from 'react';
import { Table } from 'antd';
import { observer } from 'mobx-react';



@observer
export default class CallMissed extends React.Component<any, any>{

    columns = [
        {
            title: "dial time",
            key: "creationTime",
            dataIndex: "creationTime"
        },

        {
            title: "caller",
            key: "caller",
            render: record => <FromNicknameCell record={record}></FromNicknameCell>
        },

        {
            title: "callee",
            render: record => <ToNicknameCell record={record}></ToNicknameCell>
        },

        {
            title: "type",
            dataIndex: "type"
        },

        {
            title: "status",
            dataIndex: "status"
        },

    ]

    handleTableChange = (pagination, filters, sorter) => {
        const { callMissed } = this.props.record

        if (!sorter.order) {
            callMissed.filter({ sorting: "CreationTime desc" })
            callMissed.page(pagination.current, pagination.pageSize)
        } else {
            // this.props.onChange(
            //     {
            //         page: pagination.current,
            //         pagesize: pagination.pageSize,
            //         sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
            //     }
            // )
        }

        callMissed.loadRecords()
    }


    render() {

        const { record } = this.props
        const { records, status, pagination } = record.callMissed

        return (
            <Table dataSource={records}
                columns={this.columns}
                loading={status == "loading"}
                pagination={pagination}
                onChange={this.handleTableChange}
                rowKey={item => item["id"]}
            ></Table>
        )
    }
}

const FromNicknameCell = observer(({ record }) => {
    return (
        <div>{record.from_nickname}</div>
    )
})

const ToNicknameCell = observer(({ record }) => {
    return (
        <div>{record.to_nickname}</div>
    )
})
import React from 'react';
import { observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';



@observer
export default class Home extends React.Component<any, any> {


    render() {
        return (
            <Redirect to="/souls"></Redirect>
        );
    }
}



import moment from 'moment'
import { BaseMomentFormat } from 'config/common';
import { observable } from 'mobx';
import { di } from 'jsmodules';
import { ReviewService } from 'services/review';
import { Batch_wallet } from 'utils/batch_wallet';

export class PayoutItemState {
    @di.Inject() reviewService: ReviewService
    @di.Inject() batch_wallet: Batch_wallet

    creationTime
    @observable reviewResult

    sender_nickname = ""
    @observable sender_soul_id = ""

    recipient_identity = ""
    sender_withholding_confirmed = false

    constructor(public manager, public item) {
        for (const key in item) {
            this[key] = item[key]
        }
        this.reviewResult = item.review_result
        this.creationTime = moment(item.creation_time).format(BaseMomentFormat)
        this.sender_nickname = item.sender.extend_data.nickname || item.sender.extend_data.Nickname

        this.recipient_identity = item.recipient.identity
        this.sender_withholding_confirmed = item.sender.withholding_confirmed

        this.loadSenderInfo(item)
    }

    @observable loading_review = false
    async handleReview(data) {
        try {
            await this.reviewService.handleReview(data)
            this.manager.loadRecords()
            this.reviewResult = data.result
        } catch (error) {
            throw error
        }
    }


    async loadSenderInfo(item) {
        if(item.sender.identity == ""){
            return
        }


        // var res = await this.batch_wallet.getData(item.sender.identity)
        // if(res){
        //     this.manager.walletObj[item.sender.identity] = res
        //     this.sender_soul_id = res.owner.id
        // }else{
        //     this.sender_soul_id = this.manager.walletObj[item.sender.identity].owner.id
        // }
        var res = await this.batch_wallet.getData(item.sender.identity)
        this.sender_soul_id = res.owner.id
    }
}
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AddForm from './components/AddForm';

export interface IAddConProps extends RouteComponentProps {
}

export default class AddCon extends React.Component<IAddConProps> {
    public render() {
        return (
            <div style={{
                padding: "24px 48px",
                background: "#fff",
                minHeight: "100%"
            }}>

                <div style={{
                    minWidth: 920,
                    maxWidth: 1352,
                    margin: "0 auto",
                }}>
                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        marginBottom: 48
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Add Category</span>
                    </div>

                    <AddForm></AddForm>

                </div>

            </div>
        );
    }
}

import moment from 'moment'
import { BirthdayFormat } from 'config/common';
import { di } from 'jsmodules';
import { COSUrlConverter } from 'services/urlConvert';
import { SoulCommentState } from './soulComments';
import { SoulWalletState } from './soulWallet';
import { SoulCollectState } from './soulCollect';
import { observable } from 'mobx';
import { ChatService } from 'services/chat';
import { CallHistory } from './callHistory';
import { CallMissed } from './callMissed';
import { Batch_account } from 'utils/batch_account';
import { AccountService } from 'services/account';



export class SoulItemState {
    @di.Inject() urlConvert: COSUrlConverter
    @di.Inject() chatService: ChatService
    @di.Inject() batch_account: Batch_account
    @di.Inject() accountService: AccountService

    soulCommentState: SoulCommentState
    walletState: SoulWalletState
    soulCollectState: SoulCollectState
    callHistory: CallHistory
    callMissed: CallMissed


    id = ""
    nickname = ""
    constellation = ""
    avatar = ""
    birthday = ""
    gender
    mate_collect = 0
    commentCount = 0
    @observable phone = ""
    @observable has_block = false
    @observable roles = []


    constructor(public item) {
        this.soulCommentState = new SoulCommentState(item.id)
        this.walletState = new SoulWalletState(item.id)
        this.soulCollectState = new SoulCollectState(item.id)
        this.callHistory = new CallHistory()
        this.callMissed = new CallMissed()

        this.update(item)

        this.loadAccountInfo(item.user_id)
    }

    update(item) {
        this.id = item.id
        this.nickname = item.nickname
        this.constellation = item.constellation
        this.avatar = item.avatar ? this.handleAvatar(item.avatar) : ""
        this.birthday = moment(item.birthday).format(BirthdayFormat)
        this.gender = item.gender
        this.mate_collect = item.mate_collect_statistics ? item.mate_collect_statistics.count * 1 : 0
        this.commentCount = item.comment_statistics ? item.comment_statistics.count * 1 : 0
    }

    handleAvatar = (url) => {
        var temp = ""
        if (url.indexOf("http") != -1) {
            temp = url
        } else {
            temp = this.urlConvert.url(url).imageCdn().thumbnail(132, 132)
        }
        return temp
    }


    @observable loading_chatuser = true
    chatuser
    async loadChatuser() {
        this.loading_chatuser = true
        var res = await this.chatService.loadChatuser({ user_ids: this.item.user_id })
        this.chatuser = res.data.result.items[0]
        this.loading_chatuser = false
    }


    async loadAccountInfo(user_id){
        var result = await this.batch_account.getData(user_id)
        this.has_block = result.has_blocked
        this.phone = `+${result.primary_phone_number.country_code} ${result.primary_phone_number.area_number}`
        this.roles = result.roles
    }

    async block(){
        try {
            await this.accountService.block(this.item.user_id)
            this.has_block = true
        } catch (error) {
            throw error
        }
    }

    async unblock(){
        try {
            await this.accountService.unblock(this.item.user_id)
            this.has_block = false
        } catch (error) {
            throw error
        }
    }
}
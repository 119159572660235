import * as React from 'react';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router';
import { Form, Button, Input, Select, InputNumber, notification, DatePicker, Divider } from "antd"
import { ImageUpload } from 'components/ImageUpload/ImageUpload';
import { di } from 'jsmodules';
import _ from 'lodash';
import { UploadState } from 'stores/upload';
import { observer } from 'mobx-react';
import { ProductsState } from 'stores/products/products';
import moment from 'moment'
import AddPriceCell from './AddPriceCell';
import { FormatUploadUrl } from 'stores/formatUploadUrl';


const TextArea = Input.TextArea


@observer
class AddForm extends React.Component<RouteComponentProps & FormComponentProps> {
    @di.Inject() productsState: ProductsState
    @di.Inject() uploadState: UploadState
    @di.Inject() formatUploadUrl: FormatUploadUrl



    handleAdd = (e) => {
        e.preventDefault()

        this.props.form.validateFields(async (err, values) => {
            if (!err) {
                this.productsState.loading_add = true

                var postData = {
                    "description": values.description,
                    "has_published": false,
                    "inventory": {
                        "stock_quantity": values.quantity
                    },
                    "name": values.name,
                    "sale": {
                        "sale_begin_time": values.sale_begin_time.startOf('day').utc().format(),
                        "sale_end_time": values.sale_end_time.endOf('day').utc().format(),
                        "prices": [
                            {
                                "currency_code": values.price.currency,
                                "origin_price": values.price.number,
                                "price": values.price.number
                            }
                        ]
                    },
                    "attachments": [],
                    "extend_data": {},
                    "items": [
                        {
                            "id": "306763379467382784",
                            "quantity": values.item_quantity,
                            "type": "VirtualCoin",
                            "extend_data": {
                                "CurrencyCode": "NTS"
                            }
                        }
                    ]
                }


                if (values.files) {
                    for (var i = 0; i < values.files.length; i++) {
                        try {
                            var imageUrl = await this.uploadState.upload(values.files[i])
                            postData.attachments[i] = {
                                extend_data: {
                                    width: values.files[i].width,
                                    height: values.files[i].height,
                                    type: values.files[i].type,
                                    size: values.files[i].size
                                },
                                type: "Image",
                                url: this.formatUploadUrl.formatImageUrl(imageUrl.filePath)
                            }
                        } catch (error) {
                            this.productsState.loading_add = false
                            return notification.error({
                                message: "upload image fail",
                                description: error.message
                            })
                        }
                    }
                }



                try {
                    await this.productsState.create(postData)
                    this.props.form.resetFields();
                    notification.success({
                        message: "add success"
                    })
                    this.productsState.loading_add = false
                    this.props["history"].push("/products")
                } catch (error) {
                    this.productsState.loading_add = false
                    return notification.error({
                        message: "add fail",
                        description: error.message
                    })
                }

            }
        })
    }

    checkSaleEndTime = (rule, value, callback) => {
        var begin_time = this.props.form.getFieldValue("sale_begin_time")
        if (begin_time.isAfter(value)) {
            callback("End time cannot be earlier than start time")
        }
        callback()
    }

    disabledStartDate = startValue => {
        var endValue = this.props.form.getFieldValue("sale_end_time");
        if (!startValue || !endValue) {
            return false;
        }
        return startValue.valueOf() > endValue.valueOf();
    };

    disabledEndDate = endValue => {
        var startValue = this.props.form.getFieldValue("sale_begin_time");
        if (!endValue || !startValue) {
            return false;
        }
        return endValue.valueOf() <= startValue.valueOf();
    };


    public render() {

        const { getFieldDecorator } = this.props.form

        const formItemLayout = {
            labelCol: {
                sm: { span: 4 },
            },
            wrapperCol: {
                sm: { span: 18, offset: 2 },
            },
        };

        return (
            <div style={{
                maxWidth: 680,
                marginBottom: 140
            }}>
                <Form {...formItemLayout}>
                    <Form.Item label="name" >
                        {
                            getFieldDecorator("name", {
                                initialValue: "",
                                rules: [
                                    { required: true, message: "can not be null" },
                                ]
                            })(
                                <Input></Input>
                            )
                        }
                    </Form.Item>


                    <Form.Item label="description" >
                        {getFieldDecorator('description', {
                            initialValue: ""
                        })(
                            <TextArea autoSize={{ minRows: 6 }}></TextArea>
                        )}
                    </Form.Item>

                    <Form.Item label="images"  >
                        {getFieldDecorator('files', {
                        })(
                            <ImageUpload quantity={1}></ImageUpload>
                        )}
                    </Form.Item>


                    <Divider style={{ margin: "36px 0" }} orientation="center">Sale Info</Divider>


                    <Form.Item label="sale begin time">
                        {
                            getFieldDecorator("sale_begin_time", {
                                initialValue: moment()
                            })(
                                <DatePicker
                                    disabledDate={this.disabledStartDate}
                                ></DatePicker>
                            )
                        }
                    </Form.Item>



                    <Form.Item label="sale end time">
                        {
                            getFieldDecorator("sale_end_time", {
                                initialValue: moment().add(7, "days"),
                                rules: [
                                    {
                                        validator: this.checkSaleEndTime
                                    }
                                ]
                            })(
                                <DatePicker
                                    disabledDate={this.disabledEndDate}
                                ></DatePicker>
                            )
                        }
                    </Form.Item>



                    <Form.Item label="price">
                        {
                            getFieldDecorator("price", {
                                initialValue: { number: 0, currency: 'USD' }
                            })(
                                <AddPriceCell></AddPriceCell>
                            )
                        }
                    </Form.Item>

                    <Form.Item label="quantity">
                        {
                            getFieldDecorator("quantity", {
                                initialValue: 1
                            })(
                                <InputNumber min={0} style={{ width: "60%" }} ></InputNumber>
                            )
                        }
                    </Form.Item>



                    <Divider style={{ margin: "36px 0" }} orientation="center">Product Items</Divider>


                    <Form.Item label="item name">
                        <div>NTS</div>
                    </Form.Item>

                    <Form.Item label="item type">
                        <div>VirtualCoin</div>
                    </Form.Item>

                    <Form.Item label="item quantity">
                        {
                            getFieldDecorator("item_quantity", {
                                initialValue: 1
                            })(
                                <InputNumber min={0} style={{ width: "60%" }} ></InputNumber>
                            )
                        }
                    </Form.Item>


                </Form>

                <div style={{
                    position: "fixed",
                    left: 0,
                    right: 0,
                    bottom: 0,
                    height: 68,
                    lineHeight: "68px",
                    marginLeft: 200,
                    zIndex: 100,
                    backgroundColor: "#fff",
                    textAlign: "right",
                    borderTop: "1px solid #d8d8d8"
                }}>
                    <Button
                        type="primary"
                        icon="save"
                        style={{ marginRight: "20%" }}
                        loading={this.productsState.loading_add}
                        onClick={this.handleAdd}
                    >add</Button>
                </div>
            </div>
        );
    }
}





const _AddForm = Form.create<RouteComponentProps & FormComponentProps>()(AddForm)


export default withRouter(_AddForm)



import { CRUDState } from 'stores/_CRUDState';
import { MateItemState } from './mateItem';
import { di } from 'jsmodules';
import { MateService } from 'services/mates';
import { observable } from 'mobx';

export class MateState extends CRUDState {
    @di.Inject() mateService: MateService

    constructor() {
        super("mateService", item => new MateItemState(item))
    }

    review_result = null

    @observable loading_mates = false
    @observable mateRecords = []
    async loadMates() {
        this.loading_mates = true
        var param = {
            limit: 20,
            skip: 0,
            review_result: this.review_result
        }

        var res = await this.mateService.loadMates(param)
        this.totalCount = res.data.result.total_count * 1
        this.mateRecords = res.data.result.items.map(item => new MateItemState(item))
        this.loading_mates = false
    }


    @observable loading_more_mates = false
    async loadMoreMates() {

        if (!this.loading_more_mates) {
            this.loading_more_mates = true
            var param = {
                limit: 20,
                skip: this.mateRecords.length,
                review_result: this.review_result
            }

            var res = await this.mateService.loadMates(param)
            this.mateRecords = this.mateRecords.concat(res.data.result.items.map(item => new MateItemState(item)))
            this.loading_more_mates = false
        }

    }

}
import React from 'react';
import Comments from './components/Comments';
import { Tabs } from 'antd';


const TabPane = Tabs.TabPane

export default class CommentsCon extends React.Component<any, any>{

    componentWillMount() {
        const { mateRecord } = this.props
        
        mateRecord.mateCommentState.filter({ to_author_id: mateRecord.soul_id }, true)
        mateRecord.mateCommentState.loadRecords()
    }


    render() {
        const { mateRecord } = this.props

        return (
            // <Tabs defaultActiveKey="Comment">
            //     <TabPane tab="Comments" key="Comment">
            //         <Comments mateRecord={mateRecord}></Comments>
            //     </TabPane>
            // </Tabs>
            <Comments mateRecord={mateRecord}></Comments>
        )
    }
}
import * as React from 'react';
import hosts from '../config/hosts';
import TokenService from '../services/token';
import UserService from '../services/user';
import { AppState } from '../stores/app';
import { AutoHttpFactory } from '../utils/http';
import { di, HttpFactory } from 'jsmodules';
import { SessionState } from '../stores/session';
import { WebKeyValueStorage } from '../storage/web/KeyValueStorage';
import { BatchRequest } from 'utils/batchRequest';
import { ServerTimeState } from 'stores/serverTime';
import { Batch_soul_userid } from 'utils/Batch_soul_userid';
import { UploadState } from 'stores/upload';
import { Batch_productItem } from 'utils/batch_productItem';
import { Batch_wallet } from 'utils/batch_wallet';
import { Batch_account } from 'utils/batch_account';
import { Batch_soul_userid_no_cached } from 'utils/batch_soul_userid_no_cached';
import { UploadFileService } from 'services/uploadFile';
import { FormatUploadUrl } from 'stores/formatUploadUrl';




const { container } = di;

//#region http and api
container.bind('httpFactory').to(HttpFactory);
container.bind('identity_api_v1').toFactory(AutoHttpFactory).params(hosts.identity_api);
container.bind('content_api_v1').toFactory(AutoHttpFactory).params(hosts.content_api)
container.bind("member_api_v1").toFactory(AutoHttpFactory).params(hosts.member_api)
container.bind("payment_api_v1").toFactory(AutoHttpFactory).params(hosts.payment_api)
container.bind("marketing_api_v1").toFactory(AutoHttpFactory).params(hosts.marketing_api)
container.bind("ordering_api_v1").toFactory(AutoHttpFactory).params(hosts.ordering_api)
container.bind("chat_api_v1").toFactory(AutoHttpFactory).params(hosts.chat_api)

//#endregion

//#region global manager
container.bind('app').to(AppState).isSingletonScope();
container.bind('kvStorage').to(WebKeyValueStorage).params('Main').isSingletonScope();

//#endregion
//#region services

container.bind('tokenService').to(TokenService).isSingletonScope();
container.bind('userService').to(UserService).isSingletonScope();

//#region state

container.bind('session').to(SessionState).isSingletonScope();


container.bind("batchRequest").to(BatchRequest).isSingletonScope()

container.bind("serverTimeState").to(ServerTimeState).isSingletonScope()

container.bind("uploadState").to(UploadState).isSingletonScope()
container.bind("uploadFileService").to(UploadFileService).isSingletonScope()

container.bind("batch_soul_userid").to(Batch_soul_userid).isSingletonScope()
container.bind("batch_soul_userid_no_cached").to(Batch_soul_userid_no_cached).isSingletonScope()

container.bind("batch_productItem").to(Batch_productItem).isSingletonScope()
container.bind("batch_wallet").to(Batch_wallet).isSingletonScope()
container.bind("batch_account").to(Batch_account).isSingletonScope()

container.bind("formatUploadUrl").to(FormatUploadUrl).isSingletonScope()


//#endregion

export function AppContainer(props) {
    return <React.Fragment>{props.children}</React.Fragment>;
}

import React from 'react';
import { Button, Form, Modal, Input, Radio, notification, Spin } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { di } from 'jsmodules';
import { CoinState } from 'stores/coins/coins';
import { observer } from 'mobx-react';



@observer
export default class AddCoinCell extends React.Component<any, any>{
    @di.Inject() coinState: CoinState

    state = {
        visible: false
    }

    show = () => {
        this.setState({
            visible: true
        })
    }

    hide = () => {
        this.setState({
            visible: false
        })
    }

    handleAdd = () => {
        const { form } = this.formRef.props;

        if(this.coinState.loading_create){
            return
        }

        form.validateFields(async (err, values) => {
            if (!err) {

                this.coinState.loading_create = true

                let postData = {
                    "code": values.code,
                    "description": values.description,
                    "name": values.name,
                    "type": values.type,
                }


                try {
                    await this.coinState.create(postData)
                    this.coinState.loadRecords()
                    form.resetFields();
                    this.hide()
                    this.coinState.loading_create = false
                    notification.success({
                        message: "add coin success!",
                    })
                } catch (error) {
                    notification.error({
                        message: "add coin fail!",
                        description: error.message
                    })
                    this.coinState.loading_create = false
                }
            }
        })


    }


    formRef
    saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {

        console.log(this.coinState.loading_create)

        return (
            <div>
                <Button type="primary" onClick={this.show}>add new coin</Button>
                <AddCoinForm
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.state.visible}
                    onCancel={this.hide}
                    onSubmit={this.handleAdd}
                ></AddCoinForm>
            </div>
        )
    }
}



interface IaddCoinForm extends FormComponentProps {
    onSubmit: any
    onCancel: () => void
    visible: any
}


class _AddCoinForm extends React.Component<IaddCoinForm, any>{
    render() {

        const { getFieldDecorator } = this.props.form

        return (
            <div>
                <Modal
                    title="add new coin"
                    visible={this.props.visible}
                    onCancel={this.props.onCancel}
                    onOk={this.props.onSubmit}
                >
                    <Form layout="horizontal">
                        <Form.Item label="name">
                            {
                                getFieldDecorator('name', {
                                    rules: [
                                        { required: true, message: "can not be null!" }
                                    ]
                                })(
                                    <Input></Input>
                                )
                            }
                        </Form.Item>

                        <Form.Item label="code">
                            {
                                getFieldDecorator('code', {
                                    rules: [
                                        { required: true, message: "can not be null!" }
                                    ]
                                })(
                                    <Input></Input>
                                )
                            }
                        </Form.Item>

                        <Form.Item label="description">
                            {
                                getFieldDecorator('description')(
                                    <Input></Input>
                                )
                            }
                        </Form.Item>


                        <Form.Item>
                            {
                                getFieldDecorator('type', {
                                    rules: [
                                        { required: true, message: "can not be null!" }
                                    ],
                                    initialValue: "Virtual"
                                })(
                                    <Radio.Group>
                                        <Radio value="Virtual">Virtual</Radio>
                                        <Radio value="Crypto">Crypto</Radio>
                                    </Radio.Group>,
                                )
                            }
                        </Form.Item>


                    </Form>

                </Modal>
            </div>
        )
    }
}


const AddCoinForm = Form.create<IaddCoinForm>({ name: 'form_in_modal' })(_AddCoinForm)
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { observer } from 'mobx-react';
import { di, qs } from 'jsmodules';
import { PaymentState } from 'stores/payment/payment';
import PaymentList from './PaymentList';
import Actions from './Actions';
import { Icon, Button } from 'antd';


export interface IPaymentProps extends RouteComponentProps {
}



@observer
class Payment extends React.Component<IPaymentProps> {
    @di.Inject() paymentState: PaymentState


    handleSearch(query, removeKeys?) {
        var last_query = qs.decode(this.props.location.search) || {};

        var new_query = { ...last_query, ...query };
        if (removeKeys) {
            for (var key of removeKeys) {
                delete new_query[key];
            }
        }
        this.props.history.push(`/payments?${qs.encode(new_query)}`)
        var div1 = document.getElementById("scroll-container")
        div1.scrollTop = 0
    }


    queryChange = (value) => {
        var keys = []
        for (var key in value) {
            if (!value[key]) {
                keys.push(key)
            }
        }
        if (keys.length != 0) {
            this.handleSearch(value, keys)
        } else {
            this.handleSearch(value)
        }
    }

    refresh = () => {
        this.paymentState.loadRecords()
    }

    public render() {

        const { records, status, pagination, totalCount } = this.paymentState

        const queryParams = qs.decode(this.props.location.search) || {}

        return (
            <div className="main-content">
                <div style={{ marginBottom: 36, display: "flex", alignItems: "baseline" }}>
                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        flex: 1,
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Payment</span>
                    </div>
                    <div>
                        <Button onClick={this.refresh} type="primary" icon="sync">refresh</Button>
                    </div>
                </div>


                <div style={{ marginBottom: 36 }}>
                    <Actions
                        status={queryParams.status || ""}
                        onChange={this.queryChange}
                    ></Actions>
                </div>

                <div>
                    <div style={{ fontSize: 12, marginBottom: 12 }}>{totalCount} results</div>
                    <PaymentList
                        records={records}
                        status={status}
                        pagination={pagination}
                        onChange={this.queryChange}
                    ></PaymentList>
                </div>
            </div>
        );
    }
}

export default withRouter(Payment)
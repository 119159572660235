import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import AddForm from './components/AddForm';
import { di } from 'jsmodules';
import { EditorState } from 'stores/editor/editor';
import NicknameEdit from './components/NicknameEdit';
import { observer } from 'mobx-react';

export interface IAddConProps extends RouteComponentProps {
}



@observer
export default class AddCon extends React.Component<IAddConProps> {
    @di.Inject() editorState: EditorState


    public render() {
        return (
            <div className="main-container">
                <div className="main-content">
                    <div style={{
                        color: "#D462FB",
                        fontSize: 24,
                        marginBottom: 48
                    }}>
                        <span style={{ paddingBottom: 8, borderBottom: "2px solid #D462FB" }}>Add Editor</span>
                    </div>

                    {
                        this.editorState.step == "1" &&
                        <AddForm></AddForm>
                    }

                    {
                        this.editorState.step == "2" &&
                        <NicknameEdit></NicknameEdit>
                    }

                </div>
            </div>
        );
    }
}

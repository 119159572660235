import React from 'react';
import { observer } from 'mobx-react';
import Transfers from './components/Transfers';




@observer
export default class DepositCon extends React.Component<any, any>{

    render() {
        const { record } = this.props
        return (
            <Transfers record={record}></Transfers>
        )
    }
}
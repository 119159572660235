import React from 'react';
import { Table, Statistic } from 'antd';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import { AmountCell } from 'web/components/AmountCell/AmountCell';



@observer
export default class Transfers extends React.Component<any, any>{

    columns = [
        {
            title: "amount",
            key: "Amount",
            render: record => <AmountCell currencyCode={record.currency_code} amount={record.amount}></AmountCell>
        },
        {
            title: "net amount",
            render: record => {
                return <AmountCell currencyCode={record.netAmountCode} amount={record.netAmount} ></AmountCell>
            }
        },

        {
            title: "status",
            dataIndex: "status"
        },

        {
            title: "transfer in",
            render: record => <Transfer_in record={record}></Transfer_in>
        },

        {
            title: "transfer out",
            render: record => <Transfer_out record={record}></Transfer_out>
        },

        {
            title: "creation time",
            dataIndex: "creationTime"
        },
    ]

    handleTableChange = (pagination, filters, sorter) => {

        const { walletTransationState } = this.props.record.walletState

        if (!sorter.order) {
            walletTransationState.filter({ sorting: "CreationTime desc" })
            walletTransationState.page(pagination.current, pagination.pageSize)
        }

        walletTransationState.loadRecords()
    }

    render() {

        const { records, status, pagination } = this.props.record.walletState.walletTransationState

        return (
            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => item["id"]}
                ></Table>
            </div>
        )
    }
}





const Transfer_in = observer(({ record }) => {
    return <div><Link to={`/souls/${record.transfer_in_soul_id}`}>{record.transfer_in_name}</Link></div>
})


const Transfer_out = observer(({ record }) => {
    return <div><Link to={`/souls/${record.transfer_out_soul_id}`}>{record.transfer_out_name}</Link></div>
})
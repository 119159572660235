import { CRUDState } from 'stores/_CRUDState';
import { PayoutItemState } from './PayoutItem';

export class PayoutState extends CRUDState {
    constructor() {
        super("payoutService", item => new PayoutItemState(this,item))
    }


    walletObj = {}
}
import { di } from 'jsmodules';
import { Batch_soul_userid } from 'utils/Batch_soul_userid';
import { observable } from 'mobx';
import { BirthdayFormat } from 'config/common';
import moment from 'moment'
import { COSUrlConverter } from 'services/urlConvert';
import { ReviewService } from 'services/review';
import { MateWalletState } from './mateWallet';
import { MateCommentState } from './mateComments';
import { ChatService } from 'services/chat';
import { CallHistory } from './callHistory';
import { CallMissed } from './callMissed';
import { SoulService } from 'services/soul';
import { MateService } from 'services/mates';
import { Batch_soul_userid_no_cached } from 'utils/batch_soul_userid_no_cached';


export class MateItemState {
    @di.Inject() batch_soul_userid: Batch_soul_userid
    @di.Inject() batch_soul_userid_no_cached: Batch_soul_userid_no_cached
    @di.Inject() urlConvert: COSUrlConverter
    @di.Inject() reviewService: ReviewService
    @di.Inject() chatService: ChatService
    @di.Inject() soulService: SoulService
    @di.Inject() mateService: MateService


    walletState: MateWalletState
    mateCommentState: MateCommentState
    callHistory: CallHistory
    callMissed: CallMissed


    id
    @observable soul_id = ""
    @observable nickname = ""
    @observable birthday = ""
    @observable gender = ""
    @observable avatar = ""
    @observable constellation = ""

    about = ""
    images = []

    @observable coverImage = ""

    averageScore = 5
    evaluationCount = 0

    collectCount = 0
    hotCount = 0

    currencyCode = "NTS"
    unitPrice = 0
    unitTimeSeconds = 0

    serviceTimes = 0
    serviceTime

    voice

    @observable reviewResult

    constructor(public item) {
        this.walletState = new MateWalletState(item.user_id)
        this.mateCommentState = new MateCommentState(this.soul_id)
        this.callHistory = new CallHistory()
        this.callMissed = new CallMissed()

        this.update(item)
        
        this.loadSoulInfo(item.user_id)
        this.reviewResult = item.review_result
    }


    update(item) {
        this.id = item.id
        this.evaluationCount = item.evaluation_statistics ? item.evaluation_statistics.count * 1 : 0
        this.averageScore = item.evaluation_statistics ? item.evaluation_statistics.average_score * 1 : 5
        this.collectCount = item.collect_statistics ? item.collect_statistics.count * 1 : 0
        if (item.voice_product) {
            this.currencyCode = item.voice_product.currency_code
            this.unitPrice = item.voice_product.unit_price
            this.unitTimeSeconds = item.voice_product.unit_time_seconds * 1
        }
        this.serviceTimes = item.service_statistics ? item.service_statistics.times * 1 : 0
        this.serviceTime = this.formatServiceTime(item.service_statistics)
        this.about = item.about

        this.images = item.attachments.map(image => {
            var tempUrl = this.urlConvert.url(image.url).imageytoCdn().target_url
            image.target_url = tempUrl
            return image
        })
        this.voice = this.formatVoice(item.audition_voices)


        this.reviewResult = item.review_result
    }

    formatVoice(items) {
        var temp = items
        for (const item of temp) {
            item.target_url = this.urlConvert.url(item.url).videoCdn().target_url
        }
        return temp
    }

    async loadSoulInfo(user_id) {
        var result = await this.batch_soul_userid_no_cached.getData(user_id)
        this.soul_id = result.id
        this.nickname = result.nickname
        this.birthday = moment(result.birthday).format(BirthdayFormat)
        this.gender = result.gender
        this.avatar = result.avatar ? this.handleAvatar(result.avatar) : ""
        this.constellation = result.constellation
    }

    handleAvatar = (url) => {
        var temp = ""
        if (url.indexOf("http") != -1) {
            temp = url
        } else {
            temp = this.urlConvert.url(url).imageCdn().thumbnail(132, 132)
            this.coverImage = this.urlConvert.url(url).imageCdn().target_url
        }
        return temp
    }

    formatServiceTime(stat) {
        if (!stat) {
            return "0min"
        } else {
            var a = stat.total_time_seconds * 1

            var mins = Math.floor(a / 60)
            return mins + "mins"
        }
    }


    @observable loading_review = false
    async handleReview(data) {
        try {
            await this.reviewService.handleReview(data)
            this.reviewResult = data.result
        } catch (error) {
            throw error
        }
    }


    @observable loading_chatuser = true
    chatuser
    async loadChatuser() {
        this.loading_chatuser = true
        var res = await this.chatService.loadChatuser({ user_ids: this.item.user_id })
        this.chatuser = res.data.result.items[0]
        this.loading_chatuser = false
    }



    @observable loading_edit = false
    async handleEdit(soulData, mateData) {

        try {
            if (soulData.nickname != this.nickname) {
                await this.soulService.edit_nickname(this.soul_id, { nickname: soulData.nickname })
            }

            if (this.gender != soulData.gender || this.birthday != moment(soulData.birthday).format(BirthdayFormat)) {
                await this.soulService.edit_profile(this.soul_id, {
                    gender: soulData.gender,
                    birthday: soulData.birthday
                })
            }

            if(this.unitPrice != mateData.voice_product.unit_price){
                await this.mateService.edit_voice_product(this.id,mateData.voice_product)
            }

            delete mateData.voice_product


            await this.mateService.edit(this.id, mateData)

            this.nickname = soulData.nickname
            this.gender = soulData.gender
            this.birthday = moment(soulData.birthday).format(BirthdayFormat)


        } catch (error) {
            throw error
        }
    }

}
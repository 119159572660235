import React from 'react';
import { observer } from 'mobx-react';
import { Table, Dropdown, Form } from 'antd';
import { CurrencyCodeIcon } from 'config/common';
import { AmountCell } from 'web/components/AmountCell/AmountCell';
import PaymethodCell from 'web/components/PaymethodCell';
import { Link } from 'react-router-dom';
import ReviewOnceCell from 'web/routes/Review/ReviewOnceCell';



@observer
export default class PayoutList extends React.Component<any, any>{


    columns: any = [
        {
            title: "amount",
            key: "Amount",
            sorter: true,
            sortDirections: ["descend", "ascend"],
            render: record => <AmountCell currencyCode={record.currency_code} amount={record.amount} ></AmountCell>
        },
        {
            title: "net amount",
            render: record => <TradeCell trade={record.trade}></TradeCell>
        },
        {
            title: "sender",
            render: record => <SenderCell record={record}></SenderCell>
        },
        {
            title: "recipient",
            render: record => <RecipientCell record={record}></RecipientCell>
        },
        {
            title: "review result",
            render: record => <div>
                {
                    record.reviewResult == "Normal" && <div style={{ color: "#52c41a" }}>Normal</div>
                }
                {
                    record.reviewResult == "Illegal" && <div style={{ color: "#f5222d" }}>Illegal</div>
                }
                {
                    !record.reviewResult && <div></div>
                }
            </div>
        },
        {
            title: "status",
            dataIndex: "status"
        },
        {
            title: "pay method",
            render: record => <PaymethodCell trade={record.trade}></PaymethodCell>
        },
        {
            title: "creation time",
            dataIndex: "creationTime"
        },
        {
            title: "review",
            render: record => <ReviewOnceCell record={record} type="Payout" ></ReviewOnceCell>
        }
    ]


    handleTableChange = (pagination, filters, sorter) => {
        if (!sorter.order) {
            this.props.onChange({ page: pagination.current, pagesize: pagination.pageSize, sorting: "CreationTime desc" })
        } else {
            this.props.onChange(
                {
                    page: pagination.current,
                    pagesize: pagination.pageSize,
                    sorting: `${sorter.columnKey} ${sorter.order == "descend" ? "desc" : "asc"}`
                }
            )
        }
    }

    render() {
        const { status, records, pagination } = this.props

        return (
            <div>
                <Table dataSource={records}
                    columns={this.columns}
                    loading={status == "loading"}
                    pagination={pagination}
                    onChange={this.handleTableChange}
                    rowKey={item => { return `t-${item["id"]}` }}
                ></Table>
            </div>
        )
    }
}




const SenderCell = observer(({ record }) => {
    return (
        <div>
            {
                record.sender_soul_id ?
                    <div>
                        <Link to={`/souls/${record.sender_soul_id}`}>{record.sender_nickname}</Link>
                        <div>
                            {
                                record.sender_withholding_confirmed ?
                                    <div>withhold</div>
                                    :
                                    <div>holding failed</div>
                            }
                        </div>
                    </div>

                    :
                    <div>
                        <div>{record.sender_nickname}</div>
                        {
                            record.sender_withholding_confirmed ?
                                <div>withhold</div>
                                :
                                <div>holding failed</div>
                        }
                    </div>

            }
        </div>
    )
})




@observer
class TradeCell extends React.Component<any, any>{

    render() {

        const { trade } = this.props

        const menu = (
            <div style={{ padding: 16, background: "#000", color: "#fff", borderRadius: 2, fontSize: 12 }}>
                <div style={{ display: "flex", marginBottom: 6 }}>
                    <span style={{ width: 90 }}>gross amount:</span><span style={{ marginLeft: 16, fontWeight: 600 }}>
                        {CurrencyCodeIcon[trade.currency_code]} {trade.gross_amount}
                    </span>
                </div>
                <div style={{ display: "flex", marginBottom: 6 }}>
                    <span style={{ width: 90 }}>net amount:</span><span style={{ marginLeft: 16, fontWeight: 600 }}>{CurrencyCodeIcon[trade.currency_code]} {trade.net_amount}</span>
                </div>
                <div style={{ display: "flex", marginBottom: 6 }}>
                    <span style={{ width: 90 }}>sounmate fee:</span><span style={{ marginLeft: 16, fontWeight: 600 }}>{CurrencyCodeIcon[trade.currency_code]} {trade.sounmate_fee}</span>
                </div>
                <div style={{ display: "flex", marginBottom: 6 }}>
                    <span style={{ width: 90 }}>transfer fee:</span><span style={{ marginLeft: 16, fontWeight: 600 }}>{CurrencyCodeIcon[trade.currency_code]} {trade.transfer_fee}</span>
                </div>
                <div style={{ display: "flex", marginBottom: 6 }}>
                    <span style={{ width: 90 }}>total fee:</span><span style={{ marginLeft: 16, fontWeight: 600 }}>{CurrencyCodeIcon[trade.currency_code]} {trade.total_fee}</span>
                </div>
            </div>
        )

        return (
            <div>
                <Dropdown overlay={menu}>
                    <a>
                        <AmountCell currencyCode={trade.currency_code} amount={trade.net_amount}></AmountCell>
                    </a>
                    {/* <a>{`${trade.currency_code} ${trade.net_amount}`} </a> */}
                </Dropdown>

            </div>
        )
    }
}


const RecipientCell = observer(({ record }) => {
    return (
        <div style={{ fontSize: 12 }}>
            <div>{record.recipient_identity}</div>
        </div>
    )
})
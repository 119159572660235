
import * as React from 'react';
import { Form, Input, Icon, Button, Select } from "antd"

import "./style.less";
import { FormComponentProps } from 'antd/lib/form';
import AddPhoneCell from 'web/routes/Editor/components/AddPhoneCell';



interface LoginFormProps extends FormComponentProps {

    onSubmit: (values) => any;
}

const { Option } = Select;

class EditorLoginForm extends React.Component<LoginFormProps, {}> {


    areaCodeChange = (code) => {
        this.setState({
            areaCode: code
        })
    }

    handleSubmit = (e) => {

        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit({
                    userName: `+${values.phone.country_code}${values.phone.area_number}`,
                    password: values.password
                });
            }
        });
    }

    typeChange = (value) => {
        this.setState({
            currentType: value
        })
    }


    public render(): JSX.Element {
        const { getFieldDecorator } = this.props.form;
        return <Form onSubmit={this.handleSubmit}>
            <Form.Item>

                {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'input username!' }],
                    initialValue: {
                        area_number: "",
                        country_code: "1"
                    }
                })(
                    <AddPhoneCell></AddPhoneCell>
                )}

            </Form.Item>
            <Form.Item>
                {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'input password!' }],
                })(
                    <Input
                        prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        type="password"
                        placeholder="Password"
                    />
                )}
            </Form.Item>
            <Form.Item>
                <div style={{ textAlign: "center", margin: "0 auto" }}>
                    <Button type="primary" onClick={this.handleSubmit} >
                        login
                    </Button>
                </div>
            </Form.Item>
        </Form>
    }
}

export default Form.create<LoginFormProps>()(EditorLoginForm);

import React from 'react';
import { Card, Statistic } from 'antd';
import { CurrencyCodeIcon } from 'config/common';
import { observer } from 'mobx-react';


@observer
export default class Wallets extends React.Component<any, any>{
    render() {

        const { balances } = this.props.mateRecord.walletState



        return (
            <Card bordered style={{ maxWidth: 300 }}>
                <p>Wallet Overview</p>

                {
                    balances.length == 0 &&
                    <div style={{ display: "flex", padding: 16, background: "#a0a7e4", color: "#fff", borderRadius: 4 }}>
                        <div style={{ marginRight: 24 }}>
                            <div style={{ marginBottom: 4 }}>
                                <Statistic suffix={CurrencyCodeIcon["NTS"]} value={0} valueStyle={{ color: "#fff", fontSize: 24, fontWeight: 600 }} ></Statistic>
                            </div>
                            <div style={{ fontSize: 12, }}>NTS</div>
                        </div>
                    </div>
                }

                {
                    balances.map(item => {

                        var balance = item.balance * 1

                        return (
                            <div key={item.currency_code} style={{ display: "flex", padding: 16, background: "#a0a7e4", color: "#fff", borderRadius: 4 }}>
                                {
                                    item.currency_code == "NTS" &&
                                    <div style={{ marginRight: 24 }}>
                                        <div style={{ marginBottom: 4 }}>
                                            <Statistic suffix={CurrencyCodeIcon["NTS"]} value={balance} valueStyle={{ color: "#fff", fontSize: 24, fontWeight: 600 }} ></Statistic>
                                        </div>
                                        <div style={{ fontSize: 12, }}>{item.currency_code}</div>
                                    </div>
                                }
                                {
                                    item.currency_code == "USD" &&
                                    <div style={{ marginRight: 24 }}>
                                        <div style={{ marginBottom: 4 }}>
                                            <Statistic prefix="$" value={balance} valueStyle={{ color: "#fff", fontSize: 24, fontWeight: 600 }} precision={2} ></Statistic>
                                        </div>
                                        <div style={{ fontSize: 12, }}>{item.currency_code}</div>
                                    </div>
                                }
                                {
                                    item.currency_code == "CNY" &&
                                    <div style={{ marginRight: 24 }}>
                                        <div style={{ marginBottom: 4 }}>
                                            <Statistic prefix="￥" value={balance} valueStyle={{ color: "#fff", fontSize: 24, fontWeight: 600 }} precision={2} ></Statistic>
                                        </div>
                                        <div style={{ fontSize: 12, }}>{item.currency_code}</div>
                                    </div>
                                }
                            </div>
                        )
                    })
                }
            </Card>
        )
    }
}